import _, { isNull, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { FormControl, Grid, Link, MenuItem, Pagination, Select, Stack, Tab, Tabs, ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FaChevronDown } from "react-icons/fa";

import NoticeItem from '@components/NoticeItem';
import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

const Notice = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const styles = useStyles();
    const theme = useTheme();
    const componentRef = useRef(null);
    let id = location?.state?.id;

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const [state, setState] = useState({});
    const [selected, setSelected] = useState(null);
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get("type") ? searchParams.get("type") : "news");
    const [coverImg, setCoverImg] = useState({});
    const [noticeInfo, setNoticeInfo] = useState({});
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [currPage, setCurrPage] = useState(1);

    const GENERAL_TYPE = ['news', 'announcement'];
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const callApi  = () => {
        
        let apiData = {
            type: activeTab,
            show: "member",
            hidden: [ "status_log" ],
            all: true,
            method: 'paginate',
            per_page: 10,
            page: page,
        }

        getUrl(`/all-notices`, apiData).then(response => {
            setLoading(false);
            if (response.status) 
            {
                let { data, current_page, last_page } = response.data;
                if (_.size(data) > 0 )
                    setCurrPage(current_page);
                setTotal(last_page);
                setState(data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        setLoading(true);
        callApi();
        
        // eslint-disable-next-line
    }, [ page, activeTab]);

    useEffect(() => {
        let newStatus = searchParams.get("type") ? searchParams.get("type") : "news";
        if (newStatus !== activeTab) {
            setActiveTab(searchParams.get("type") ? searchParams.get("type") : "news");
        }
        // eslint-disable-next-line
    }, [searchParams]);

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.scrollTop = 0;
        }
    }, [noticeInfo]);

    useEffect(() => {
        if(id && _.size(state)>0){
            getNoticeContent(id);
        }
    }, [id, state]);
  
    useEffect(() => {
        getUrl(`/get-notice-images`).then(response => {
            if (response.status) 
            {
                setCoverImg(response?.data?.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    const handleTabChange = (event, newValue) => {
        if (_.includes(GENERAL_TYPE, newValue)){
            setActiveTab(newValue);
            setPage(1);
            navigate(`/account/notices?type=${newValue}`, { state: { from: storePaths(location) } });
        }else{
            addAlert('', t('error.contactSupport'), 'error', '');
        }
    };

    const getImageLink = (info) => {
        let imageDetail = null;
        if (_.size(info?.content_images) > 0) {
            let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
            if (!isUndefined(tempData)) {
                imageDetail = tempData?.file_name;
            } else {
                let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                if (defaultData) {
                    imageDetail = defaultData?.file_name;
                }
            }
        }
        return imageDetail;
    }

    const getNoticeContent = (id) => {
        setNoticeInfo(state.find(item => item.id === id ));
        setSelected(id);
    }

    const onChangePage = (page) => {
        setPage(page);
    }
    
    return (
        <div className='dashboard-container'>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <div style={{ padding: '0 5%', height:'calc(78vh)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div>
                            {
                                mobileView
                                    ?
                                    <div style={{ padding: 0, width: '100%' }}>
                                        <FormControl fullWidth className='dis-flex flex-m'>
                                            <Select
                                                variant='standard'
                                                value={activeTab}
                                                MenuProps={{ classes: { paper: styles.listRoot } }}
                                                onChange={(event) => handleTabChange([], event.target.value)}
                                                className={`txt-upper txt-center ${styles.selectBox}`}
                                                style={{ background: 'transparent' }}
                                                disableUnderline
                                                IconComponent={() => (
                                                    <FaChevronDown className={styles.dropdownIcon} />
                                                )}
                                            >
                                                {
                                                    _.size(GENERAL_TYPE) > 0 && _.map(GENERAL_TYPE, (type, typeIndex) => (
                                                        <MenuItem key={typeIndex} value={type} className='txt-upper' style={{ color: theme.palette.secondary.main, fontSize: 14 }}>{t(`notice.${type}`)}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    :
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        className={styles.optionNoticeLayout}
                                        centered
                                    >
                                        {
                                            _.size(GENERAL_TYPE) > 0 && _.map(GENERAL_TYPE, (type, typeIndex) => {
                                                return (
                                                    <Tab
                                                        key={typeIndex}
                                                        className={`${styles.tabButton} ${activeTab === type && styles.activeTab}`}
                                                        value={type}
                                                        label={
                                                            <Typography variant="body1" style={{ color: activeTab === type ? 'white' : 'inherit' }}>
                                                                {t(`notice.${type}`)}
                                                            </Typography>
                                                        }
                                                    />
                                                )
                                            })
                                        }
                                    </Tabs>
                            }

                            {
                                loading ?
                                    null
                                    :
                                    (
                                        _.size(state) > 0 ?
                                            <div style={{ paddingTop: 30 }}>
                                                {
                                                    _.map(state, (data, key) => {
                                                        let imageLink = null;
                                                        if (data?.content_type === "image" && _.size(data?.content_images) > 0) {
                                                            imageLink = getImageLink(data);
                                                        }

                                                        if (isNull(imageLink)) {
                                                            imageLink = '../images/dashboard/example.png';
                                                        }

                                                        if (isNull(data.cover_image)) {
                                                            imageLink = '/images/dashboard/example.png';
                                                        } else {
                                                            let filename = _.filter(coverImg, { id: data.cover_image });
                                                            imageLink = filename[0]?.file_name;
                                                        }

                                                        if (imageLink) {
                                                            if (mobileView) {
                                                                return (
                                                                    <div key={key}>
                                                                        <Link component={RouterLink} to={`/account/notice/${data?.id}`} state={{ from: storePaths(location) }} underline='none' >
                                                                            <div className='pos-relative flex-r p-b-30 pointer' onClick={() => getNoticeContent(data?.id)}>
                                                                                <div className='news-box flex-m' style={{ background: selected === data.id ? theme.palette.primary.main : theme.palette.secondary.main }}>
                                                                                    <div className='p-l-70' style={{ fontWeight: 500 }}>
                                                                                        <p className='txt-truncate2 fs-15' style={{ color: '#f0f0f0' }}>{data?.title[i18n.language] ? data.title[i18n.language] : data?.title?.cn}</p>
                                                                                        <p className='fs-12' style={{ color: '#f0f0f0' }}>{data?.created_at ? moment(data?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='news-img-pos'>
                                                                                    <img src={imageLink} alt='new-img' className='w-full max-w-full' style={{ borderRadius: '25px 7px', boxShadow: '3px 4px 4px 0 #0002' }} />
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={key}>
                                                                        <div className='pos-relative flex-r p-b-30 pointer' onClick={() => getNoticeContent(data?.id)}>
                                                                            <div className='news-box flex-m' style={{ background: selected === data.id? theme.palette.primary.main : theme.palette.secondary.main }}>
                                                                                <div className='p-l-70' style={{ fontWeight: 500 }}>
                                                                                    <p className='txt-truncate2 fs-15' style={{ color: '#f0f0f0' }}>{data?.title[i18n.language] ? data.title[i18n.language] : data?.title?.cn}</p>
                                                                                    <p className='fs-12' style={{ color: '#f0f0f0' }}>{data?.created_at ? moment(data?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='news-img-pos'>
                                                                                <img src={imageLink} alt='new-img' className='w-full max-w-full' style={{ borderRadius: '25px 7px', boxShadow: '3px 4px 4px 0 #0002' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                }
                                            </div>
                                            :
                                            <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                {/* <img src={'/images/genealogy/downline_none.png'} alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }} loading='lazy' /> */}
                                                <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                                                <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                                                    {t('table.noRecord')}
                                                </Typography>
                                            </Grid>

                                    )
                            }
                        </div>
                        
                        <ThemeProvider theme={paginationStyle}>
                            <Stack spacing={2} style={{justifyContent:'space-around'}}>
                                {
                                    _.size(state) > 0
                                    ? ( <Pagination classes={{ul: styles.ul}} count={total} page={page} onChange={(event, page) => onChangePage(page)} /> )
                                    : null
                                }
                            </Stack>
                        </ThemeProvider>
                    </div>
                </Grid>

                {
                    !mobileView && 
                    (
                        <Grid item xs={12} lg={6}>
                            <div ref={componentRef} className='box-out p-lr-35 p-tb-25' style={{background: theme.palette.gray.secondary, height:'calc(78vh)', overflow:'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {_.size(noticeInfo) === 0 && (
                                <img src={'/images/general/filter-search-empty-data.png'} width={400} alt="No data" loading='lazy' />
                            )}
                            {/* // News  */}
                            {
                                _.size(noticeInfo) > 0
                                ?
                                    <>
                                        <p className='txt-truncate2 fs-21 theme-txt txt-title' style={{ color: '#f0f0f0' }}><b>{noticeInfo?.title[i18n.language] ? noticeInfo.title[i18n.language] : noticeInfo?.title?.cn}</b></p>
                                        <p className='fs-12' style={{ color: '#9D9D9D' }}>{noticeInfo?.created_at ? moment(noticeInfo?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                        <NoticeItem info={noticeInfo} />
                                    </>
                                :
                                    <Typography variant='body1'>{t('general.noDisplay')}</Typography>
                            }
                            </div>
                        </Grid>
                    )
                }
                
            </Grid>
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    optionNoticeLayout: {
        borderRadius: '48px',
        background: '#1E1E1E',
        // boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
    },
    tabButton: {
        borderRadius: '48px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        color: '#fff',
    },
    activeTab: {
        color: 'white',
        background: 'linear-gradient(91deg, #86552E 2.67%, #DDA253 99.35%)',
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 100,
        marginBottom: 30,
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        width: '80%',
        color: '#F0F0F0',
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 0,
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#ffffffde"
        }
    }
}));

const paginationStyle = createTheme({
    components: {
      // Name of the component
      MuiPagination: {
        styleOverrides: {
          // Name of the slot
          ul: {
            // Some CSS
            justifyContent:'space-around',
          },
          
        },
      },

  }});


export default Notice;