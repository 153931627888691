// import moment from 'moment-timezone';
import _ from 'lodash';
import React, { useEffect, useState , useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { storePaths, currencyFormat, convertToK  } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';


import { Dialog, DialogTitle, DialogContent, Hidden, IconButton, Button, Typography, Grid, Container} from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { makeStyles, useTheme, styled } from '@mui/styles';

import MarketPlaceCard from '@layouts/Dashboard/MarketPlaceCard';
import DialogTradeDetails from '@components/DialogTradeDetails';
import CloseIcon from '@mui/icons-material/Close';
import { BsInfo } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";

import ChartData from "@components/ChartData";
import BTTransactionTable from '@components/TransactionItems/BTTransactionTable';
import BonusTokenHistoryListing from "@components/TransactionItems/BonusTokenHistoryItem";
import RedeemHistoryListing from "@components/TransactionItems/RedeemTransactionItem";
import BuyTradeHistoryItem from '@components/TransactionItems/BuyTradeHistoryItem';

export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    let location = useLocation();
    const navigate = useNavigate();
    const { addAlert , setLoading , loading } = useNotificationLoading();
    const { id } = useSelector((state) => state.user);

    const [marketPrice, setMarketPrice] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [marketDislogOpen, setMarketDialogOpen] = useState(false);

    const [tradeProfile, setTradeProfile] = useState({
        passive_unit: 0,
        active_unit: 0,
        lock_value: 0
    });
    // console.log(tradeProfile)
    const [tradeRedeemable, setTradeRedeemable] = useState(0.00);
    const [currentRate, setCurrentRate] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [reservedHistroy, setReservedHistroy] = useState([]);
    const [open, setOpen] = useState(false);

    // transaction history
    const [btTransactionTable, setBtTransactionData] = useState([]);
    const [bonusTokenTransaction, setBonusTokenTransaction] = useState([]);
    const [redeemTransactionList, setRedeemTransactionList] = useState([]);
    const [currentPrice, setCurrentPrice] = useState(0.000);
    const [buyTradeTransactionList, setBuyTradeTransactionList] = useState([]);
    const [data, setData] = useState([]);

    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [viewDetails, setViewDetails] = useState(false);

    // icon
    const [selectedIcon, setSelectedIcon] = useState(2);

    const icons = [
        // { id: 1, img: 'buy', alt: 'Buy Icon', label: t('trade.buy'), title: t('title.buyTradeHistory'), link: "/trade/buy-trade-histories" },
        { id: 2, img: 'bc', alt: 'Bonus Token Icon', label: t('title.bonusTokenHistory'),  title: t('title.bonusTokenHistory'), link: "/trade/bonus-token-histories" },
        { id: 3, img: 'transfer', alt: 'Transfer Icon', label: t('title.redeemHistory'),  title: t('title.redeemHistory'), link: "/trade/redeem-transaction" },
        { id: 4, img: 'trade', alt: 'Trade Icon', label: t('title.tradeHistory'), title: t('title.tradeHistory'), link: "/trade/sell-transaction-history" },
    ];

    const dialogIcons = [
        { id: 2, img: 'bc', alt: 'Bonus Token Icon', label: t('title.bonusTokenHistoryIcon'),  title: t('title.bonusTokenHistoryIcon'), link: "/trade/bonus-token-histories" },
        { id: 1, img: 'buy', alt: 'Buy Icon', label: t('title.buyHistory'), title: t('title.buyHistory'), link: "/trade/buy-trade-histories" },
        { id: 3, img: 'transfer', alt: 'Transfer Icon', label: t('title.redeemHistoryIcon'),  title: t('title.redeemHistoryIcon'), link: "/trade/redeem-transaction" },
        { id: 4, img: 'trade', alt: 'Trade Icon', label: t('title.tradeHistoryIcon'), title: t('title.tradeHistoryIcon'), link: "/trade/sell-transaction-history" },
    ];

    const handleViewOpen = () => {
        setOpen(true);
    }
    const handleViewClose = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
      

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    // ------------ setup call api function ------------

    const callUserBalance = () => {
        getUrl(`/member-get-balance`).then(response => {
            setLoading(false);
            if (response.status) {
                setTradeProfile(response.trade_profile);
                setTradeRedeemable(response.trade_redeemable);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const callAllMarket = () => {

        let apiData = {
            per_page: 5
        }

        getUrl(`/get-all-market`,  apiData).then(response => {
            setLoading(false);
            if (response.status) {
                setCurrentRate(response.data.current_price);
                setMarketPrice(response.data.market);

                const currentDateTime = new Date();
                const formattedDateTime = currentDateTime.toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false
                });

                setLastUpdate(formattedDateTime);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const callReservedValueHistory = () => {
        getUrl(`/member-get-reserved-history`).then(response => {
            setLoading(false);
            if (response.status) {
                setReservedHistroy(response.reserved_value_histroy);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const callBTTransactionTable = () => {
        getUrl(`/member-get-sell-histories`, {per_page: _.size(marketPrice) > 0 ? 18 : 15}).then(response => {
            setLoading(false);
            if (response.status) {
                let { data, current_page, last_page } = response.listing;
                setBtTransactionData(data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const callBonusTokenTransactionTable = () => {
        getUrl(`/get-member-bonus-token-history`, {per_page: _.size(marketPrice) > 0 ? 18 : 15}).then((response) => {
            if(response.status)
            {
                setBonusTokenTransaction(response.transaction.data);
            }
            else
            {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const callRedeemTransactionTable = () => {
        getUrl(`/redeemable-transaction-list/${id}`, {per_page: _.size(marketPrice) > 0 ? 18 : 15}).then(response => {
            setLoading(false);
            if (response.status) {
                setRedeemTransactionList(response.data.listing.data);
                setCurrentPrice(currencyFormat(response.data.current_price, 3));
            }
            else
            {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const callBuyTradeHistory = () => {
        getUrl(`/member-get-buy-histories`, {per_page: _.size(marketPrice) > 0 ? 18 : 15}).then(response => {
            setLoading(false);
            if (response.status) {
                setBuyTradeTransactionList(response.listing.data);
            }
            else
            {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        callAllMarket();
        callUserBalance();
        callReservedValueHistory();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        switch(selectedIcon) {
            case 1: callBuyTradeHistory(); break;
            case 2: callBonusTokenTransactionTable(); break;
            case 3: callRedeemTransactionTable(); break;
            case 4: callBTTransactionTable(); break;
            default: break;
        }
        // eslint-disable-next-line
    }, [selectedIcon]);

    useEffect(() => {
        setMarketDialogOpen(false);

        // eslint-disable-next-line
    }, [isLgDown]);

    // ------------ calculation ------------
        
    // ------------ end up calculation ------------

    // ------------ setup call api function ------------

    const goToAddBalance = () => {
        navigate('/plans', { state: { from: storePaths(location) } });
    };

    // ------------ layout ------------
    
    // bonus token
    const bonusTokenChart = () => {
        const passiveUnit = currencyFormat(tradeProfile?.passive_unit||0, 0) || 0;
        const activeUnit = currencyFormat(tradeProfile?.active_unit||0, 0) || 0;
        const totalUnits = (tradeProfile?.passive_unit||0) + (tradeProfile?.active_unit || 0);
        const totalValue = totalUnits * currentRate || 0.00;
        const formattedTotalUnits = Math.round(totalUnits, 0) || 0;
        const formattedTotalValues = Math.round(totalValue, 2) || 0.00;
        // currentRate
        const activeUnitValue = tradeProfile?.active_unit * currentRate || 0;
        const reservedUnitValue = tradeProfile?.lock_value || 0 ;
        const tradableUnitValue = tradeProfile?.tradable_value || 0 ;
        const passiveUnitValue = tradeProfile?.passive_unit * currentRate || 0 ;


        return (
            <>
                {/* <Hidden smDown>
                    <Grid item>
                        <Typography className={`${classes.colorGrey} ${classes.fontTitle}`}>
                            {t('trade.bonusToken')}
                        </Typography>
                    </Grid>
                </Hidden> */}
                <Grid 
                    container
                    className='p-lr-20 p-b-30 flex-c-m m-t-10 m-b-20'
                    style={{borderRadius: 8, background: '#1E1E1E', boxShadow: '4px 4px 4px 0px #FFFFFF0F, 4px 4px 4px 0px #00000040 inset'}}>
                    <div className='w-full txt-center p-all-5'
                        style={{
                            width: 300,
                            maxWidth:'60%',
                            margin:'0 auto',
                            background: theme.palette.primary.main,
                            boxShadow: '-2px -2px 4px 0px #0000001A inset, 4px 0px 4px 0px #00000026 inset',
                            clipPath: 'polygon(0 0, 100% 0, 90% 100%, 10% 100%)'
                        }}
                    >
                        <Typography className={`${classes.colorWhite} ${classes.fontTitleDivider} txt-upper`}>
                            {t('trade.bonusToken')}
                        </Typography>
                    </div>

                    <div className='flex-m p-t-20 w-full' style={{flexDirection:isSmDown ? 'column' : 'row',}}>
                        
                        {/* <div className="txt-center" style={{width:170, maxWidth:'100%', minWidth:170}}>
                            <ChartData 
                                data={[
                                    {title: t('spend.activeUnit'), value: tradeProfile?.active_unit||0 },
                                    {title: t('spend.passiveUnit'), value: tradeProfile?.passive_unit||0 },
                                ]}
                                total={totalUnits}
                                totalTitle={t('general.totalUnit')} 
                            />
                        </div> */}
                        
                        <div className='w-full' style={{paddingLeft:isSmDown ? '0' : '15px',}}>
                            
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    {t('title.activeUnitValue')}
                                </Typography>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    $ {currencyFormat( activeUnitValue ||0, 2)}
                                </Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    {t('title.reserveValue')}
                                </Typography>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    $ {currencyFormat( reservedUnitValue ||0, 2)}
                                </Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    {t('title.tradableValue')}
                                </Typography>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    $ {currencyFormat( tradableUnitValue ||0, 2)}
                                </Typography>
                            </div>

                            <hr
                                style={{
                                    backgroundColor: '#1E1E1E',
                                    boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
                                    height: 4,
                                    display: 'inline-block',
                                    border: 0,
                                    width: '100%',
                                    marginBottom: 0,
                                }}
                            />

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    {t('title.passiveUnitValue')}
                                </Typography>
                                <Typography style={{ color: theme.palette.white.main, textTransform: 'capitalize', marginTop: 3 }}>
                                    $ {currencyFormat( passiveUnitValue||0, 2)}
                                </Typography>
                            </div>

                            <hr
                                style={{
                                    backgroundColor: '#1E1E1E',
                                    boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
                                    height: 4,
                                    display: 'inline-block',
                                    border: 0,
                                    width: '100%'
                                }}
                            />

                        </div>

                        {/* <div className='w-full' style={{paddingLeft:isSmDown ? '0' : '15px',}}>
                            <div className='flex-sb-m p-tb-20'>
                                <div style={{ color: theme.palette.white.main }}>
                                    <p className='fs-15 txt-upper'>
                                        <b>{t('trade.totalBalance')}</b>
                                    </p>
                                    <p className='fs-12'>
                                        {t('trade.currentRate')}: ${currentRate || 0}
                                    </p>
                                </div>
                                <Button variant="contained" className={classes.btnIcon} onClick={goToAddBalance}>
                                    <AddIcon className='fs-25' />
                                </Button>
                            </div>
                            <Grid
                                container
                                style={{
                                    // justifyContent: isSmDown ? 'space-around' : 'space-between',
                                    // alignItems: 'center',
                                    marginBottom: 5,
                                    // display: 'flex',
                                    // flexDirection: 'row',
                                    // flexWrap: 'nowrap'
                                }}
                            >
                                <Grid item xs={5}>
                                    <p className='fs-11 txt-white'>
                                        {t('general.current')} ({t('general.unit')})
                                    </p>
                                    <Typography style={{ fontSize: 14, fontWeight: 700, textTransform: 'uppercase' }}>
                                        {convertToK(formattedTotalUnits)} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: 19, fontWeight: 700, fontStyle: 'italic', textAlign:'center' }}>
                                        ≈
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} className='flex-r'>
                                    <div>
                                    <p className='fs-11 txt-white'>
                                        {t('trade.approximately')}
                                    </p>
                                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                                        $ {convertToK(formattedTotalValues)}
                                    </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <hr
                                style={{
                                    backgroundColor: '#1E1E1E',
                                    boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
                                    height: 4,
                                    display: 'inline-block',
                                    border: 0,
                                    width: '100%'
                                }}
                            />
                        </div> */}

                    </div>

                    <div className='flex-sb-m w-full p-b-10 p-t-20'>
                        <p className='txt-white'>{t('trade.showDetail')}</p>
                        {viewDetails ?
                            <div onClick={() => setViewDetails(false)} className='pointer' style={{ height: 25, width: 43, borderRadius: 48, padding: 2, background: '#ca2120', boxShadow: 'inset 5px 4px 3px #0000004d', transition:'all 0.5s'   }}>
                                <div style={{ width: 21, height: 21, borderRadius: 48, background: '#f1f1f1', transform:'translateX(17px)', transition:'all 0.5s'  }}></div>
                            </div>
                            :
                            <div onClick={() => setViewDetails(true)} className='pointer' style={{ height: 25, width: 43, borderRadius: 48, padding: 2, background: '#f1f1f1', boxShadow: 'inset 5px 4px 3px #0000004d', transition:'all 0.5s'  }}>
                                <div style={{ width: 21, height: 21, borderRadius: 48, background: '#222', transform:'translateX(0)', transition:'all 0.5s'  }}></div>
                            </div>
                        }
                    </div>

                   
                    {viewDetails &&
                        <Grid container spacing={2} style={{ marginBottom: 5, textAlign: 'center' }}>
                            <Grid item xs={6} sm={6} className='flex-m'>
                                <Grid item xs={6} style={{ padding: 10, background: theme.palette.primary.main, borderRadius: '5px 0px 0px 5px', boxShadow: '3px 3px 4px 0px rgba(203, 203, 203, 0.06), 3px 3px 4px 0px rgba(0, 0, 0, 0.48) inset', }}>
                                    <Typography style={{ color: theme.palette.white.main, fontWeight: 400, fontSize: 11, textTransform: 'uppercase', marginTop: 3 }}>
                                        {t('button.active')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 10, background: '#1E1E1E', borderRadius: '0px 5px 5px 0px', boxShadow: '0px 2px 3px 0px #FFFFFF12, 0px 1px 2px 0px #000000DB inset' }}>
                                    <Typography style={{ color: theme.palette.white.main, fontWeight: 700, fontSize: 14 }}>
                                        {activeUnit}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} className='flex-m'>
                                <Grid item xs={6} style={{ padding: 10, background: theme.palette.white.main, borderRadius: '5px 0px 0px 5px', boxShadow: '3px 3px 4px 0px rgba(203, 203, 203, 0.06), 3px 3px 4px 0px rgba(0, 0, 0, 0.48) inset', }}>
                                    <Typography style={{ color: theme.palette.gray.main, fontWeight: 400, fontSize: 11, textTransform: 'uppercase', marginTop: 3 }}>
                                        {t('trade.passive')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 10, background: '#1E1E1E', borderRadius: '0px 5px 5px 0px', boxShadow: '0px 2px 3px 0px #FFFFFF12, 0px 1px 2px 0px #000000DB inset' }}>
                                    <Typography style={{ color: theme.palette.white.main, fontWeight: 700, fontSize: 14 }}>
                                        {passiveUnit}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }
    
    // trade and transfer
    const tradeAndTransfer = () => {
        const activeUnit = Number(tradeProfile?.active_unit|| 0) || 0;
        const lockAmount = Number(tradeProfile?.lock_value|| 0) || 0;
        const tradableValue = (activeUnit * currentRate) - lockAmount || 0.00;
        const totalValue = Number(tradeRedeemable) || 0.00;
        const totalUnit = totalValue / currentRate || 0;
        const totalSum = reservedHistroy.reduce((sum, item) => sum + (Number(item.price) || 0), 0);
        
        return (
            <>
                <Grid container rowSpacing={1} style={{ marginBottom: 30 }}>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 15, fontWeight: 700, color: theme.palette.white.main}}>{t('trade.currentPrice')}: {currentRate || 0}</Typography>
                        <Typography style={{ fontSize: 12, fontWeight: 400, color: '#ADADAD'}}>{t('title.lastUpdate')}: {lastUpdate ? lastUpdate : '00-00-0000, 00:00:00'}</Typography>
                    </Grid>
                    <Grid 
                        item xs={6} 
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
                    >
                        <Typography style={{ fontSize: 14, fontStyle: 'italic', fontWeight: 400, color: theme.palette.white.main}}>{t('general.moreInfo')}</Typography>
                        <Button variant="contained" className={classes.btnIcon} onClick={handleClickOpen}>
                            <BsInfo size="25px" style={{ rotate: '10deg' }} />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2} >
                    {/* Tradable */}
                    <Grid item xs={12} sm={6} >
                        <div className={classes.whiteDivider}>
                            <div className={classes.titleBorder}>
                                <Typography className={classes.titleText} style={{textTransform: 'uppercase'}}>{t('general.tradable')}</Typography>
                            </div>
                            <Grid  
                                container spacing={2}
                                style={{ alignItems: 'center'}}
                            >
                                <Grid item xs={6} sm={12}>
                                <div style={{ marginTop: 20 }}>
                                    <Typography className={classes.tradableValue} style={{ textAlign: isSmDown ? 'left' : 'center' }}>$ {currencyFormat(tradableValue, 2)}</Typography>
                                    <Typography className={classes.tradableValueLabel} style={{textTransform: 'uppercase', textAlign: isSmDown ? 'left' : 'center'}}>{t('trade.tradableValue')}</Typography>
                                    <Typography className={classes.activeBonusTokenLabel} style={{textTransform: 'uppercase', textAlign: isSmDown ? 'left' : 'center'}}>{t('trade.activeBonusToken')}</Typography>
                                </div>
                                </Grid>
                                <Grid item xs={6} sm={12}>
                                    <div>
                                        <Button 
                                            variant="contained"
                                            size="large" 
                                            className={classes.tradeButton} 
                                            onClick={() => goToLink("/trade/sell")}
                                        >
                                            <Typography className={classes.tradeButtonText}>
                                                {t('button.tradeNow')}
                                            </Typography>
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* Redeemable */}
                    <Grid item xs={12} sm={6}>
                        <div className={classes.whiteDivider}>
                            <div className={classes.titleBorder}>
                                <Typography className={classes.titleText} style={{ textTransform: 'uppercase' }}>{t('trade.redemmable')}</Typography>
                            </div>
                            <Grid  
                                container spacing={2}
                                style={{ alignItems: 'center'}}
                            >
                                <Grid item xs={6} sm={12}>
                                    <div style={{ marginTop: 20 }}>
                                        <Typography className={classes.activeBonusTokenLabel} style={{ marginLeft: isSmDown ? '0%' : '30%', textTransform: 'uppercase', textAlign: isSmDown ? 'left' : 'center'}}>≈ {currencyFormat(totalUnit, 0)} {t('general.unit')}</Typography>
                                        <Typography className={classes.tradableValue} style={{ textAlign: isSmDown ? 'left' : 'center' }}>$ {currencyFormat(totalValue, 2)}</Typography>
                                        <Typography className={classes.tradableValueLabel} style={{ textTransform: 'uppercase', textAlign: isSmDown ? 'left' : 'center' }}>{t('trade.totalValue')}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={12}>
                                    <div>
                                        <Button 
                                            variant="contained"
                                            size="large" 
                                            className={classes.tradeButton} 
                                            onClick={() => goToLink("/trade/redeem-list")}
                                        >
                                            <Typography className={classes.tradeButtonText}>
                                                {t('transfer.confirmTransfer')}
                                            </Typography>
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                {/* Dialog for more trade details */}
                <Dialog open={dialogOpen} maxWidth="sm" fullWidth>
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.closeButton}>
                            <IconButton className={classes.closeIcon} onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className='w-full m-b-40'>
                            <div className={classes.titleContainer}>
                                <DialogTitle className={classes.dialogTitle}>
                                    <b>{t('title.tradeDetail')}</b>
                                </DialogTitle>

                                {/* <Typography className={classes.dialogTypography}>
                                    {t('dialog.tradeDetail')}
                                </Typography> */}

                                <Typography className={classes.dialogTypography}>
                                    {t('title.activeUnitValueRules')}
                                </Typography>

                                <Typography className={classes.dialogTypography}>
                                    {t('title.reservedValueRules')}
                                </Typography>

                                <Typography className={classes.dialogTypography}>
                                    {t('title.tradableValueRules')}
                                </Typography>
                            </div>
                        </div>

                        <div className='w-full'>
                            {/* <div
                                className='p-lr-20 p-tb-30 flex-c-m m-t-10'
                                style={{
                                    borderRadius: 8,
                                    position: 'relative',
                                    background: '#1E1E1E',
                                    boxShadow: '4px 4px 4px 0px #FFFFFF0F, 4px 4px 4px 0px #00000040 inset',
                                    marginBottom: 20
                                }}
                            >
                                <Grid
                                    className={`${isSmDown ? classes.currentPriceContainerSm : classes.currentPriceContainer}`}
                                >
                                    <Typography className={`${classes.colorWhite} ${classes.fontTitleDivider}`}>
                                        {t('trade.currentPrice')}: ${currentRate || 0}
                                    </Typography>
                                </Grid>

                                <Grid container spacing={2} className='w-full' style={{ textAlign: 'center', marginTop: 10, }}>
                                    <Grid
                                        item
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            flexDirection: isSmDown ? 'row-reverse' : 'column',
                                            alignItems: isSmDown ? 'center' : 'stretch',
                                        }}
                                    >
                                        <Typography style={{ color: theme.palette.white.main, fontWeight: 700, fontSize: 22 }}>${currencyFormat(tradableValue, 2) || 0}</Typography>
                                        <Typography className={classes.colorGrey} style={{ fontWeight: 400, fontSize: 17 }}>{t('general.tradable')}</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            flexDirection: isSmDown ? 'row-reverse' : 'column',
                                            alignItems: isSmDown ? 'center' : 'stretch',
                                        }}
                                    >
                                        <Typography style={{ color: theme.palette.white.main, fontWeight: 700, fontSize: 22, fontStyle: isSmDown ? 'italic' : '' }}>${currencyFormat(totalSum, 2) || 0}</Typography>
                                        <Typography className={classes.colorGrey} style={{ fontWeight: 400, fontSize: 17 }}>{t('general.reserved')}</Typography>
                                    </Grid>
                                </Grid>
                            </div> */}

                            {/* <hr
                                style={{
                                    borderRadius: '276px',
                                    background: 'rgba(0, 0, 0, 0.13)',
                                    filter: 'blur(4px)',
                                    width: '276px',
                                    height: '4.529px',
                                    flexShrink: '0'
                                }}
                            /> */}

                            {/* Content for the reservedValueHistory */}
                            <div>
                                <DialogTradeDetails reservedHistroy={reservedHistroy} />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </>
        )
    }

    // market place
    const marketPlaceDisplay = () => {
        return (
            <div className='p-t-30'>
                <div style={{ marginTop: 20 }}><MarketPlaceCard marketPrice={marketPrice} /></div>
            </div>
        );
    }

    // button of trade and transfer on mobile View
    const transactionListDisplay = () => {
        let selectedListToDisplay = [];
        let count = 0;

        switch(selectedIcon) {
            case 1: selectedListToDisplay = buyTradeTransactionList; break;
            case 2: selectedListToDisplay = bonusTokenTransaction; break;
            case 3: selectedListToDisplay = redeemTransactionList; break;
            case 4: selectedListToDisplay = btTransactionTable; break;
            default: break;
        }
        
        return (
            <div className={classes.divider}>
                {
                    _.size(selectedListToDisplay) > 0 ?
                        _.map(selectedListToDisplay, (data, key) => {
                            let displayItem = '';
                            count += 1;
                            switch(selectedIcon) {
                                case 1:
                                    let units = 0;
                                    _.map(data, detail => (units += parseInt(detail?.active_unit + detail?.passive_unit)));
                                    displayItem = <BuyTradeHistoryItem data={data} units={units} />;
                                    break;
                                case 2:
                                    displayItem = <BonusTokenHistoryListing data={data} />;
                                    break;
                                case 3:
                                    let factor = data?.factor !== 1 ? '-' : '+';
                                    displayItem = <RedeemHistoryListing data={data} factor={factor} currentPrice={currentPrice} />;
                                    break;
                                case 4:
                                    displayItem = <BTTransactionTable item={data} />;
                                    break;
                                default: break;
                            }

                            return (
                                <div key={key}>
                                    {displayItem}
                                    { count < _.size(selectedListToDisplay) && <div className={classes.dividerDesign} />}
                                </div>
                            )
                        })
                        : <Grid container className='flex-l-m p-tb-10'>
                            <Grid item xs={12}>
                                <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                            </Grid>
                        </Grid>
                }
            </div>
        )
    }

    const buttonTradingAndMarket = () => {
        return (
            <Grid container spacing={2} className='p-t-20'>
                <Grid item xs={12} sm={6}>
                    <div onClick={handleViewOpen} className={classes.container}>
                        <div className={`${classes.item} ${classes.flexContainer}`}>
                            <div className={classes.iconContainer}>
                                <div className={classes.innerIconContainer}>
                                    <img src='/images/trade/document_icon.png' alt="icon" className={classes.image} />
                                </div>
                            </div>
                            <p className={classes.text}>
                                {t('title.transaction')}
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div onClick={() => setMarketDialogOpen(true)} className={classes.container}>
                        <div className={`${classes.item} ${classes.flexContainer}`}>
                            <div className={classes.iconContainer}>
                                <div className={classes.innerIconContainer}>
                                    <img src='/images/trade/computer_icon.png' alt="icon" className={classes.image} />
                                </div>
                            </div>
                            <p className={classes.text}>
                                {t('trade.market')}
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    // transaction History
    const transactionHistory = () => {
        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={6}>
                        <Typography className={`${classes.colorGrey} ${classes.fontTitle}`}>
                            {t('transaction.tokenTitle')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} >
                        <div onClick={handleViewOpen} style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
                            <Typography variant='body2' className={`${classes.colorWhite}`} style={{fontWeight: 700, cursor: 'pointer'}}>
                                {t('button.viewAll')}
                            </Typography>
                            <MdKeyboardArrowRight style={{color: theme.palette.white.main, fontSize: '25px'}} />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    {_.map(icons, (icon) => (
                        <Grid item lg={4} key={icon.id} className='flex-m' style={{ flexDirection: 'column' }}>
                            <div className={`flex-c-m ${classes.iconTrade} ${selectedIcon === icon.id ? classes.selectedIconTrade : ''}`} onClick={() => setSelectedIcon(icon.id)}>
                                <img
                                    // src={selectedIcon === icon.id ? `/images/trade_icon/${icon.hoverSrc}` : `/images/trade_icon/${icon.src}`}
                                    src={`/images/trade_icon/${icon.img}${selectedIcon === icon.id ? "-sel" : ""}.png`}
                                    alt={icon.alt}
                                    className={classes.iconImg}
                                />
                            </div>
                            <div>
                                <Typography
                                    className={classes.fontIcon}
                                    style={{ color: selectedIcon === icon.id ? theme.palette.white.main : '#ADADAD', fontWeight: selectedIcon === icon.id ? 'bold' : 'normal' , textAlign: 'center'}}
                                >
                                    {icon.label}
                                </Typography>
                            </div>
                            {
                                selectedIcon === icon.id &&
                                <div style={{ position: 'relative', top: 8 }}>
                                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.25 -0.00878906L24.0496 20.4287H0.450404L12.25 -0.00878906Z" fill="#1E1E1E" />
                                    </svg>
                                </div>
                            }
                        </Grid>
                    ))}
                </Grid>

                {transactionListDisplay()}
                {mobileTransactionDialog()}
            </>
        )
    }
    
    // market mobile view
    const mobileMarketDialog = () => {
        return (
            <Dialog open={marketDislogOpen} onClose={() => setMarketDialogOpen(false)} maxWidth="sm" fullWidth 
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent !important',
                        boxShadow: 'none !important',
                        overflow: 'visible'
                    },
                }}
            >
                <>
                    <MarketPlaceCard marketPrice={marketPrice?.slice(0, 5)} mobileDialog={true} />
                </>
            </Dialog>
        )
    }

    const mobileTransactionDialog = () => {
        return (
            <Dialog open={open} maxWidth="sm" fullWidth>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.closeButton}>
                        <IconButton className={classes.closeIcon} onClick={handleViewClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className='w-full'>
                        <div className={classes.titleContainer}>
                            <DialogTitle className={classes.dialogTitle}>
                                <b>{t('title.transactionType')}</b>
                            </DialogTitle>

                            {/* <Typography className={classes.dialogTypography}>
                                {t('dialog.tradeDetail')}
                            </Typography> */}
                        </div>
                    </div>

                    <div className='w-full m-t-30'>
                        {
                            _.map(dialogIcons, (icon, key) => (
                                <Grid container key={key} className={`flex-m ${classes.gridContainer} ${classes.boxContainer}`} onClick={() => goToLink(icon?.link)}>
                                    <Grid item xs={1} style={{ display: 'flex' }}>
                                        <img src={`/images/trade_icon/${icon?.img}.png`} alt={icon?.alt} className={classes.icon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography variant='body1' className={classes.titleTypeTransaction}>
                                            {icon?.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    // ------------ layout ------------

    return (
        <>
            <CustomDiv className='dashboard-container'>
               <Hidden lgUp>
                    <div className={`m-t-${isMdDown ? 60 : 0}`}>{bonusTokenChart()}</div>
                    { <div className='m-t-30'>{tradeAndTransfer()}</div>}
                    {buttonTradingAndMarket()}
                    {mobileMarketDialog()}
                    {mobileTransactionDialog()}
                    {/* { <div className='m-t-30'>{tradeAndTransfer()}</div>} */}
                </Hidden>
                
                <Hidden lgDown>
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6} className='w-full'>
                            {bonusTokenChart()}
                            {tradeAndTransfer()}   
                            {marketPlaceDisplay()}
                        </Grid>
                        <Grid item xs={12} lg={6} className='w-full' style={{ display: 'flex', flexDirection: 'column' }}>
                            {transactionHistory()}
                        </Grid>
                    </Grid>
                </Hidden>
            </CustomDiv>
        </>
    );
}

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 100,
    },
});

const useStyles = makeStyles(theme => ({
    // general
    colorGrey: {
        color: "#ADADAD"
    },
    colorWhite: {
        color: "#F0F0F0"
    },
    fontTitle: {
        fontSize: 18,
        fontWeight: 700
    },
    fontTitleDivider: {
        fontSize: 14,
    },
    flexCenter: {
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center'
    },
    diagonalBackground: {
        background: `linear-gradient(45deg, transparent 50%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 50%)`,
        backgroundSize: '8px 8px',
    },
    closeIcon: {
        color: theme.palette.gray.main,
        width: 27,
        height: 27,
        boxShadow: '-3px -3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 5px 0px rgba(0, 0, 0, 0.1)',
        background: 'rgba(238, 238, 238, 1)'
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
    btnIcon: {
        marginLeft: 10, 
        borderRadius: '5px', 
        minWidth: '25px', 
        height: '25px', 
        padding: "0px 0px"
    },
    // bonus token
    currentPriceContainer: {
        position: 'absolute',
        top: '0px',
        textAlign: 'center',
        boxShadow: '-2px -2px 4px 0px #0000001A inset, 4px 0px 4px 0px #00000026 inset',
        padding: '10px',
        background: theme.palette.primary.main,
        clipPath: 'polygon(0 0, 100% 0, 90% 100%, 10% 100%)',
        width: '50%',
    },
    currentPriceContainerSm: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '80%',
        padding: '10px 0',
        borderRadius: '15px',
        background: theme.palette.primary.main,
        boxShadow: 'none',
        textAlign: 'center'
    },
    // trade and transfer
    whiteDivider: {
        background: theme.palette.white.main,
        borderRadius: '15px',
        padding: '0px 15px 15px',
        flexDirection: 'column',
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    titleBorder: {
        background: '#F0F0F0',
        boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: '0px 0px 15px 16px',
        padding: '5px 20px 5px 20px',
        margin: '0 10%'
    },
    // transaction History
   fontIcon: {
        color: '#ADADAD',
        fontSize: 18,
    },
    iconImg: {
        // width: 40,
        aspectRatio: 1/1,
        transition: 'all 0.3s ease',
    },
    iconTrade: {
        background: theme.palette.white.main,
        padding: 20,
        width: 70,
        marginBottom: '5px',
        borderRadius: '100%',
        height: 70,
        aspectRatio: 1/1,
        color: '#ADADAD',
        fontWeight: 400,
        fontSize: 19,
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.21) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)'
    },
    selectedIconTrade: {
        background: theme.palette.primary.main,
        color: theme.palette.white.main,
        boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 4px 0px #0000007A inset'
    },
    divider: {
        width: '100%',
        height: '100%',
        background: 'rgba(30, 30, 30, 1)',
        borderRadius: 15,
        boxShadow: '4px 4px 4px 0px rgba(255, 255, 255, 0.06), 4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
        padding: '20px'
    },
    // dialog for transaction history
    dialogContent: {
        width: '100%',
        padding: '20px 30px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 15px 20px',
        },
    },
    closeButton: {
        textAlign: 'end',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogTitle: {
        color: theme.palette.gray.main,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    dialogTypography: {
        color: theme.palette.gray.main,
        fontWeight: 400,
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    boxContainer: {
        height: 55,
        borderRadius: 15,
        padding: 10,
        background: theme.palette.white.main,
        boxShadow: '-3px -3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 5px 0px rgba(0, 0, 0, 0.1)',
        '&:hover':{
            boxShadow: '-3px -3px 4px 0px rgba(255, 255, 255, 1) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.1) inset',
        },
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            marginBottom: 10,
        },
        cursor: 'pointer',
    },
    gridContainer: {
        padding: '0 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
        },
    },
    icon: {
        width: 30,
        aspectRatio: 1/1,
        [theme.breakpoints.down('sm')]: {
            width: 25,
            aspectRatio: 1/1,
        },
    },
    titleTypeTransaction: {
        color: theme.palette.gray.main,
        fontWeight: 700,
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
        },
    },
    tradableValue: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.darkBlack.main
    },
    tradableValueLabel: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.darkGray.main
    },
    activeBonusTokenLabel: {
        fontWeight: 400,
        fontSize: 10,
        color: theme.palette.lightGray.main
    },
    tradeButton: {
        width: '100%',
        background: theme.palette.primary.main,
        marginTop: 15,
        boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.21) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)'

    },
    tradeButtonText: {
        color: '#F0F0F0',
        fontWeight: 400,
        fontSize: 13
    },
    titleText: {
        fontWeight: 700,
        fontSize: 13,
        color: theme.palette.primary.secondary
    },
    customChart: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 3px 3px 4px 0px inset, rgb(255, 255, 255) 4px 4px 6px 0px',
        height: '170',
        width: '170',
        borderRadius: '50%',
        padding: '15px',
    },
    squareContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
    square: {
        position: 'relative',
        width: '50vw',
        height: '50vw',
        backgroundColor: '#3498db',
    },
    buttonStyle: {
        width: 300,
        height: 50,
        marginTop: 20,
    },
    // circleContainer: {
    //     boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 4px 4px 6px 0 #fff',
    //     height: 185,
    //     width: 185,
    //     borderRadius: '100%',
    //     padding: 15,
    // },
    circleContent: {
        background: '#82512D',
        boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
        borderRadius: '100%',
        position: 'relative',
    },
    circleText: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    circleLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        fontSize: '12px',
        marginTop: '15px',
    },
    container: {
        cursor: 'pointer',
    },
    item: {
        width: '100%',
        background: theme.palette.primary.main,
        boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15),-2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset,2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset',
        borderRadius: 10,
        padding: '15px',
        [theme.breakpoints.down('sm')]:{
            padding: '10px',
        },
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    iconContainer: {
        background: '#EEEEEE',
        boxShadow: '2px 2px 4px 0px #00000040 inset',
        padding: 5,
        borderRadius: '100%',
        width: 45,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]:{
            width: '7vw',
            height: '7vw',
        },
        [theme.breakpoints.down('sm')]:{
            width: '12vw',
            height: '12vw',
        },
    },
    innerIconContainer: {
        padding: 5,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
    },
    text: {
        color: '#F0F0F0',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 14,
        paddingLeft: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
        },
    },
    selectBox: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 0,
        textAlign: 'end',
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
    activeTrade: {
        marginBottom: 10,
        padding: 10,
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#EEE'
    },
    paddingTrade: {
        marginBottom: 10,
        padding: 10,
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#EEE'
    },
    rectangle: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        height: '4px',
        margin: '5px 0px 15px 0px'
    },
    viewAll: {
        textAlign: 'center',
        cursor: 'pointer',
        // padding: '10px 20px',
        background: theme.palette.primary.main,
        borderRadius: 10,
        boxShadow: 'none',
        color: '#F0F0F0'
    },
    dialogMarketPlaceCard: {
        scrollbarWidth: 'none',
        padding: 25,
        width: '40vw',
        [theme.breakpoints.down('md')]: {
            width: '50vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '80vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        }
    },
    // mobileMarketDialog
    marketPriceTitleContainer: {
        position: 'fixed',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '50%', 
        padding: '10px 0',
        borderRadius: '15px',
        background: theme.palette.primary.main,
        boxShadow: '0px 4px 5px 0px rgba(209, 60, 60, 0.17)',
        left: '50%'
    },
    marketPriceTitle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '8px 0',
    },
    headerText: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 700,
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '10px 0',
    },
    itemText: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 500,
    },
    newDividerClass: {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px rgba(255, 255, 255, 1)',
        height: 4,
        display: 'inline-block',
        border: 0,
        width: '100%'
    }
}));