import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

export default function MerchantRequest(props) {
    const { checked, handleChange } = props;
    const { t } = useTranslation();

    const theme = useTheme();
    const styles = useStyles();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div className={styles.boxDesign}>
                <div style={{ overflowY: 'scroll', height: '100%' }}>
                    <Typography variant='body2' style={{ color: theme.palette.gray.main }}><b>{t('merchant.docDetail')}</b></Typography>
                    <ul style={{ margin: 0 }}>
                        <li><Typography variant='body2' style={{ color: theme.palette.gray.main }}>{t('merchant.copySsm58')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.gray.main }}>{t('merchant.copySsm78')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.gray.main }}>{t('merchant.copyLicence')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.gray.main }}>{t('merchant.copyHalal')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.gray.main }}>{t('merchant.copyOthers')}</Typography></li>
                    </ul>
                </div>
            </div>

            <FormControlLabel
                style={{ paddingTop: 10 }}
                control={<Checkbox name='check' checked={checked} onChange={handleChange} />}
                label={<Typography variant='body2' className='txt-left' style={{ color: theme.palette.gray.main }}>
                    <b>{t('merchant.terms')}</b>
                </Typography>}
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    boxDesign: {
        width:'100%',
        height: 250,
        borderRadius: 10,
        textAlign: 'left',
        padding: '20px 30px', 
        boxShadow: '-3px -3px 4px 0px #FFFFFF inset, 2px 2px 3px 0px #0000001A inset',
    }
}));