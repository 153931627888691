import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import _ from 'lodash';
// import BonusCard from '../components/BonusCard';
import { PropTypes } from 'prop-types';
import MerchantRecieveData from '@components/MerchantRecieveData';
import { Pagination, Dialog, Box } from "@mui/material";

import MerchantRecieveReceipt from '@layouts/MerchantRecieveReceipt';

const MerchantWithdrawalsTable = (props) => {
    const { history, total, pageApi, setPageApi, status } = props;
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null); // Track the selected transaction
    const classes = useStyles();

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleTransactionClick = (transaction) => {
        setSelectedTransaction(transaction); // Set the selected transaction
        setDialogOpen(true);
    };

    return (
        <React.Fragment>
            <Grid container style={{borderRadius:8, padding:"10px 15px 15px"}} className='box-in'>
                {_.map(history, (data, key) => (
                    <Grid item xs={12} key={key} onClick={() => handleTransactionClick(data)}> {/* Handle click event */}
                        <MerchantRecieveData
                            data={data}
                            date={data?.created_at}
                            title={data?.transaction_code_display[i18n.language] ? data?.transaction_code_display[i18n.language] : data?.transaction_code_display?.en}
                            remark={data?.remark?.member_remark ? data?.remark?.member_remark : ''}
                            amount={data && data.value ? parseFloat(data.value) : 0}
                            factor={data.factor}
                        />
                        {key !== history.length - 1 && ( /* Render rectangle bottom for all but the last entry */
                            <Grid item xs={12}>
                                <div className='s-full m-t-10' style={{ height: 4, background: '#1E1E1E', boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12' }}></div>
                            </Grid>
                        )}
                    </Grid>
                ))}
                <Grid item xs={12} className='flex-c' style={{marginTop:10}}> 
                    <Pagination classes={{ul: classes.ul}} count={total} onChange={(e, value) => setPageApi(value)} showFirstButton showLastButton size="large"/>
                </Grid>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                disableScrollLock={true}
                PaperProps={{
                    style: {
                        // backgroundColor: 'transparent',
                        // boxShadow: 'none',
                    },
                }}
            >
                <Box style={{maxWidth: 400}}>
                    <MerchantRecieveReceipt data={selectedTransaction} handleClose={handleDialogClose} />
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#ffffffde"
        }
    }
}));


// DEFINE DEFAULT PROPS TYPE
MerchantWithdrawalsTable.propTypes = {
    history:PropTypes.array,
    total:PropTypes.number
};

// DEFINE DEFAULT PROPS VALUE
MerchantWithdrawalsTable.defaultProps = {
    history: [],
    total:0,
};
export default MerchantWithdrawalsTable;