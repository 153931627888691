import _ from 'lodash';
import React, { useState , useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl , postUrl } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, TextField, IconButton, InputAdornment, Dialog, DialogContent } from '@mui/material';

import { Link as RouterLink, useNavigate} from 'react-router-dom';

import { FiEye, FiEyeOff } from "react-icons/fi";
import { currencyFormat } from '@utils/Tools';

import useNotificationLoading from '@utils/useNotificationLoading';
import TwoFA from '@components/TwoFA';

const INITIAL_STATE = {
    amount: "",
    remark: "",
    authenticationCode: "",
    securityPassword: "",
}

const ConvertAPtoValue = () => {
    const styles = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    // const [searchParams] = useSearchParams();

    const [inputErrors, setInputErrors] = useState();
    const [state, setState] = useState(INITIAL_STATE);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [balance, setBalance] = useState(0);

    const [twoFASetting, setTwoFASetting] = useState(false);
    const [open, setOpen] = useState(false);
    const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
    const [showPassword, setShowPassword] = useState({
        security_password: false,
    });
    const [preview, setPreview] = useState(false);

    useEffect(() => {
        callApi();

        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        setLoading(true);
        getUrl('/member-get-convert-ap-to-value' ).then(response => {
            if(response.status)
            {
                setTwoFASetting(response.twoFA);
                setBalance(response.balance);
                setCurrentPrice(response.rate);
            }
            else
            {
                // navigate(`/`);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally (() => {
            setLoading(false)
        })
        ;
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            if ( !/^-?\d*$/.test(value) ){
                value = '';
            }
            setState({ ...state, [name]: value });
        }
        else{
            setState({ ...state, [name]: value });
        }
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            security_password: !showPassword.security_password
        });
    };
    
    const handleOpenDrawer = () => {
        if ( state?.amount !== '' && state?.securityPassword ) {
            if (twoFASetting)
            {
                setOpen(true);
            }
            else
            {
                setDialogBoxOpen(true);
            }
        } else {
            addAlert('', t('error.requiredFields', {'value': t(`user.securityPassword`) + " ," + t(`transfer.amount`) } ), 'error', '');
        }
    }
    
    const nextFunction = () => {
        return handleOpenDrawer ;
        // return twoFASetting ? handleOpenDrawer : submitData ;
    }

    const submitData = () => {
        
        setLoading(true);
        setInputErrors();

        let apiData = {
            amount: state.amount,
            remark: state.remark,
            current_price: currentPrice,

            authentication_code: state.authenticationCode,
            security_password: state.securityPassword,
        };

        postUrl('/member-convert-ap-to-value', apiData).then(response => {
            if (response.status) 
            {
                addAlert('', t('success.convertSuccess'), 'success', '');
                setState(INITIAL_STATE);
                setPreview(false);
                callApi();
                // navigate('/');
            }
            else 
            {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.purchaseError'), 'error', '');
                let datas = ['remark', 'security_password', 'amount'];
                // version 1
                datas.some(element => {
                    if((Object.keys(response?.errors)).includes(element)){
                        setOpen(false);
                    }
                })

            }
            setOpen(false);
            setDialogBoxOpen(false);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const step1 = () => {
        return (
            <>
                <Typography variant='h6' className='txt-center p-b-30' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main }} >
                {t('title.convertAPtoValue')}
                </Typography>

                <div
                    style={{
                        backgroundColor: 'var(--Dark-Base, #2E3133)',
                        padding: '40px 30px',
                        textAlign: 'center',
                        borderRadius: 15,
                        margin: '10px 20px',
                        boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 4px 0px rgba(255, 255, 255, 0.06)',
                    }}
                >
                    <Typography style={{ fontSize:10, }} >{t('wallet.balance')}</Typography>
                    <Typography style={{ fontSize:21, fontWeight: 700, }} >{currencyFormat(balance, 2)}</Typography>
                    <Typography style={{ fontSize:15, }} >{t('general.convertRate', {"ap": 1, "value": currencyFormat(currentPrice, 0)})}</Typography>
                </div>
                
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='amount'
                            // label={t('withdraw.withdrawalAmount')}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            value={state.amount || ''}
                            placeholder={t('receipt.amount')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='remark'
                            // label={t('withdraw.remark')}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            value={state.remark}
                            placeholder={t('receipt.remark')}
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    fullWidth
                    className='big-button'
                    style={{ margin: '30px 0 0' }}
                    onClick={() => setPreview(true)}
                    disabled={ state?.amount !== '' && state?.remark !== '' ? false : true }
                >
                    {t('button.next')}
                </Button>
            </>
        )
    }

    const step2 = () => {
        return (
            <>
                <Typography  variant='h6' className='txt-center p-b-30' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main , padding: '0 0 10px'}} >
                    {t('title.convertAPtoValue')}
                </Typography>
               
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                    
                    <Grid item xs={12}>
                        
                        <div 
                            style={{
                                margin: "10px 0",
                                borderRadius: 20,
                                padding: 20,
                                boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 2px 0px rgba(0, 0, 0, 0.1) inset',
                            }}
                        >
                            <div style={{ textAlign: 'center', alignItems: "center", color: 'rgba(173, 173, 173, 1)'}}>
                                <p>{t('general.summary')}</p>
                            </div>
                            <div className={styles.newDividerClass} />

                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p>{t('receipt.amount')}</p>
                                <p>{currencyFormat(state?.amount, 0)}</p>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p>{t('general.rate')}</p>
                                <p>{currencyFormat(currentPrice, 0)}</p>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p style={{ fontWeight:  700 }}>{t('general.totalAmount')}</p>
                                <p style={{ fontWeight:  700 }}>{ currencyFormat(state?.amount * currentPrice , 0) }</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body2' style={{color: 'rgba(120, 120, 120, 1)' }}>
                            <span style={{ fontWeight: 700 }}>{t('withdraw.remark')}</span> : { state.remark }
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                   
                        <TextField
                            variant="standard"
                            fullWidth
                            name="securityPassword"
                            placeholder={t('profile.currentSecurityPassword')}
                            type={showPassword.security_password ? 'text' : 'password'}
                            value={state.securityPassword || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                        >
                                            {showPassword.security_password ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={styles.textFieldBox}
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    fullWidth
                    className='big-button'
                    style={{ margin: '30px 0 0' }}
                    onClick={nextFunction()}
                    disabled={state?.securityPassword ? false : true}
                >
                    {t('button.next')}
                </Button>

                <Button
                    variant="contained"
                    fullWidth
                    className='big-button'
                    onClick={() => setPreview(false)}
                    sx={{
                        margin: '10px 0',
                        backgroundColor: 'rgba(238, 238, 238, 1)',
                        border: '2px solid rgba(203, 32, 32, 1)',
                        boxShadow: 'unset',
                        color: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: 'rgba(203, 32, 32, 1)',
                            boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.1), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
                            color: 'rgba(238, 238, 238, 1)',
                            border: 'unset',
                        },
                    }}
                >
                    {t('button.back')}
                </Button>

            </>
        )
    }

    const twofa = () => {
        return (
            <TwoFA
                inputErrors={inputErrors?.authentication_code}
                open={open}
                onClose={() => setOpen(false)}
                value={state?.authenticationCode || ''}
                handleChange={handleChange}
                onClick={submitData}
                name={"authenticationCode"}
            />
        )
    }

    const dialogForm = () => {
        return (
            <Dialog open={dialogBoxOpen} onClose={() => setDialogBoxOpen(false)}>
                <DialogContent>

                    <div 
                        style={{
                            margin: '10px 15px 25px',
                        }}
                    >
                        <Typography variant='h5' style={{color: theme.palette.darkBase.main }} >{t('merchant.notice')}</Typography>
                        <Typography variant='body2' style={{color: theme.palette.darkBase.main }} >{t('merchant.noticeMessage')}</Typography>
                    </div>

                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button 
                            onClick={() => setDialogBoxOpen(false)}
                            variant="contained"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: theme.palette.darkBase.main,
                                    color: theme.palette.white.main,
                                },
                                "&.Mui-disabled": {
                                    backgroundColor: "#D9D9D9",
                                    color: "#ADADAD",
                                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                                },
                            }}
                        >
                            {t('button.back')}
                        </Button>

                        <Button 
                            onClick={() => submitData()}
                            variant="contained"
                            sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: "#D9D9D9",
                                    color: "#ADADAD",
                                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                                },
                            }}
                        >
                            {t('button.submit')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div className='dashboard-container'>
            <div className='dashboard-w' style={{paddingBottom:40}}>
                <div className='pos-relative' style={{zIndex:2}}>
                    { preview ? step2() : step1() }
                    { dialogForm() }
                    { twofa() }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    newDividerClass: {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px rgba(255, 255, 255, 1)',
        height: 4,
        display: 'inline-block',
        border: 0,
        width: '100%'
    }
}));

export default ConvertAPtoValue;