import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
// import styles bundle
import 'swiper/css/bundle';
// import Swiper and modules styles
import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Grid } from '@mui/material';
import _ from 'lodash';
import BonusCard from '../components/BonusCard';
import { storePaths } from '@utils/Tools';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';

const BonusSliderShow = (props) => {
    const { bonusTitle, setSelectedId, selectedId } = props;
    const [swiper, setSwiper] = useState(null);
    
    const theme = useTheme();
    const { i18n } = useTranslation();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (selectedId && swiper) {
            const matchingIndex = _.findIndex(bonusTitle,(data) => {
                
                return (
                    data.id === parseInt(selectedId)
                );
            });       
    
            if (matchingIndex !== -1) {
                swiper.slideTo(matchingIndex);
            }
        }
    }, [selectedId, bonusTitle, swiper]);
  
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('b');
    
        if (id) {
            const decodedId = atob(id);
            setSelectedId(decodedId)
        }
    }, []);

    const handleClickEvent = (index) =>{
        let selectedBonus = bonusTitle[index];
        if(selectedBonus){
            setSelectedId(selectedBonus?.id);
            navigate(`/bonus?b=${btoa(selectedBonus?.id)}`, { state: { from: storePaths(location) } });
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Swiper 
                spaceBetween={20}
                grabCursor={true}
                loop={false}
                pagination={{ 
                    clickable: true,
                    el: '.wallet-slider',
                }}
                slidesPerView="auto"
                freeMode={true}
                onSwiper={setSwiper}
                modules={[Pagination]} 
                className="wallet-swiper"
                onClick={(e) => handleClickEvent(e.clickedIndex)}
            >
                {_.map(bonusTitle,(data, dataIndex) => {
                    return (
                        <SwiperSlide key={dataIndex} style={{width:250}}>
                            <div >
                                <BonusCard
                                    bonusCode= {data.bonus_type}
                                    bonusAmount= {parseFloat(data.total_amount)}
                                    walletDecimer= {2}
                                    backgroundColorCode={data.id == selectedId? theme.palette.primary.main :'#565656'}
                                    imgFilterColor={data.id == selectedId? theme.palette.white : undefined}
                                    textColor={data.id === selectedId ? theme.palette.white : undefined}
                                    displayName= {data.name[i18n.language]}
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="wallet-slider" />
        </Grid>
    );
};

export default BonusSliderShow;