import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import moment from 'moment-timezone';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import {  Typography, Hidden } from '@mui/material';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { storePaths , currencyFormat } from '@utils/Tools';

import { useTheme } from '@mui/styles';

import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

export default function BonusProfitBalance() {
    const { t } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    const { addAlert, setLoading } = useNotificationLoading();

    const [profitLimit, setProfitLimit] = useState({});

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setProfitLimit(response.profit.total_profit_limit);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className=' server-box m-b-50 p-tb-20 p-lr-30' style={{ display: 'flex', justifyContent: 'space-between'}}>

            {/* <div style={{ height: 65, padding: 10 , backgroundColor: theme.palette.primary.main, borderRadius: '50%', aspectRatio: '1/1', alignContent: 'center', marginRight: 20, }}>
                <img src='/images/logos/logo-white.png' alt='logo' className='w-full bor-15' style={{ objectFit: 'contain'}} loading='lazy' />
            </div> */}
                
            <div style={{ width: '-webkit-fill-available'}} >

                <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center'}} >
                    <Typography variant='body2' style={{ color: '#F0F0F0', fontWeight: 500 }}>{t('wallet.accumulateBonus')}</Typography>
                    <div className=' txt-center' style={{ display: 'flex', justifyContent: 'space-between', color: 'white', cursor: 'pointer', alignItems: 'center'}} onClick={() => goToLink('/funds/bonus')}>
                        <Hidden lgDown>
                            <Typography variant='body2' >{ t('general.viewMore')}</Typography>
                        </Hidden>
                        <KeyboardArrowRightOutlinedIcon />
                    </div>
                </div>

                <div style={{ color: '#F0F0F0' }}>
                    <Typography style={{ color: '#F0F0F0', fontWeight: '700', fontSize: 25 }}>
                        $ { currencyFormat(profitLimit?.profit_limit_balance || 0, 2)}
                    </Typography>
                </div>

            </div>

        </div>
    );
}