import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { changeLanguage } from '@actions';
import { removeLoginAccess } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';

import UserBasicInfoCard from '@layouts/Dashboard/UserBasicInfoCard';
import { Dashboard, Wallet, Edit, AddBusinessOutlined, QrCodeScanner, CurrencyExchange, AccountTree, ShoppingBag, History, DonutLarge, Password, Newspaper, Language, MeetingRoom, BusinessOutlined, ReceiptLong} from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { FaLink } from "react-icons/fa";
import EditNoteIcon from '@mui/icons-material/EditNote';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

const Me = () => {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { merchant } = useSelector(state => state.user);
    const styles = useStyles();
    const dispatch = useDispatch();
    const [langOpen, setLangOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState('en');
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if(!mobileView){
            navigate('/', { state: { from: storePaths(location) } });
        }
        // eslint-disable-next-line
    }, [mobileView])
    
    const logout = () => {
        removeLoginAccess();
        navigate('/login');
    };

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    const itemDisplay = (icon, label, action) => {
        let lang = label === t('profile.language') ? true : false;
        return (
            <>
                <ListItem>
                    <ListItemButton onClick={action}>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            primaryTypographyProps={{ fontWeight: "bold", fontSize: 16 }}
                        />
                    </ListItemButton>
                </ListItem>
            </>
        )
    }

    return (
        <div className={styles.card} style={{ marginTop: 80 }}>
            <div style={{ margin: '0px 20px' }}>
                <UserBasicInfoCard />
            </div>

            <div className='p-l-35 p-r-35 p-b-100'>
                {/* <div className={styles.titleText}>
                    {t('profile.linkAccToMstore')}
                </div>
                <List>
                    {itemDisplay(
                        <FaLink style={{ color: 'white'}} />,
                        t('profile.toMstore'),
                        () => goToLink("/linkAcc/toMstore")
                    )}
                </List>
                <div className="divider-list-black" /> */}
                
                {/* ACCOUNT */}
                <div className={styles.titleText}>
                    {t('profile.account')}
                </div>
                <List>
                    {/* {itemDisplay(
                        <img
                            className="icon-img-style"
                            src="images/profile_icon/profile.png"
                            alt="Profile Icon"
                            loading='lazy'
                        />,
                        t('profile.editProfile'),
                        () => goToLink("/account/profile")
                    )} */}

                    {/* {itemDisplay(
                        <EditNoteIcon style={{ color: 'white'}}/>
                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/address-sel.png"
                        //     alt="Profile Icon"
                        //     loading='lazy'
                        // />
                        ,
                        t('profile.editAddress'),
                        () => goToLink("/account/address")
                    )} */}

                    {itemDisplay(
                        <Edit style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/register-sel.png"
                        //     alt="Register Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('profile.registerAcc'), 
                        () => goToLink("/account/recruit")
                    )}

                    {itemDisplay(
                        <QrCodeScanner style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/qr-sel.png"
                        //     alt="Qr Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('profile.referralQr'), 
                        () => goToLink("/account/referral-qr")
                    )}

                    {itemDisplay(
                        <AccountTree style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/community-sel.png"
                        //     alt="Community Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('title.community'), 
                        () => goToLink("/community/sponsor")
                    )}

                    {itemDisplay(
                        <History style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/package-sel.png"
                        //     alt="Plan History Icon"
                        //     loading='lazy'
                        // />
                        ,
                        t('title.planHistory'),
                        () => goToLink("/plan-histories")
                    )}
                </List>
                <div className="divider-list-black" />

                {/* MERCHANT */}
                <div className={styles.titleText}>
                    {t('profile.merchant')}
                </div>
                <List>
                    {merchant?.status !== 20 && itemDisplay(
                        <AddBusinessOutlined style={{ color: 'white' }} />,
                        t('merchant.becomeMerchant'),
                        () => goToLink("/become-merchant")
                    )}
                    {itemDisplay(
                        <BusinessOutlined style={{ color: 'white' }} />,
                        t('merchant.merchantListing'),
                        () => goToLink("/merchant-listing")
                    )}
                    {merchant?.status == 20 && itemDisplay(
                        <ReceiptLong style={{ color: '#f0f0f0' }} />,
                        t('title.merchantWithdrawalsHistory'),
                        () => goToLink("/merchant-withdraw-history")
                    )}
                    {merchant?.status == 20 && itemDisplay(
                        <PaymentsOutlinedIcon style={{ color: '#f0f0f0' }} />,
                        t('title.merchatnWithdrawal'),
                        () => goToLink("/merchant-withdraw")
                    )}
                    {merchant?.status == 20 && itemDisplay(
                        <PointOfSaleIcon style={{ color: '#f0f0f0' }} />,
                        t('title.merchantReceiveTrade'),
                        () => goToLink("/merchant-spending-history")
                    )}
                </List>

                 {/*<List>
                    

                   

                    {itemDisplay(
                        <CurrencyExchange style={{ color: '#f0f0f0' }} />,
                        t('profile.convert'),
                        () => goToLink("merchant-convert")
                    )}


                </List> */}
                <div className="divider-list-black" />

                {/* SECURITY */}
                <div className={styles.titleText}>
                    {t('profile.security')}
                </div>
                <List>
                    {itemDisplay(
                        <Password style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/password-sel.png"
                        //     alt="Password Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('profile.password'), 
                        () => goToLink("/account/passwordHome")
                    )}
                    {/* {itemDisplay(
                        <img
                            className="icon-img-style"
                            src="images/profile_icon/security.png"
                            alt="Security Password Icon"
                            loading='lazy'
                        />, 
                        t('profile.securityPassword'), 
                        () => goToLink("/account/security-password")
                    )} */}
                    {itemDisplay(
                        <LockIcon style={{ color: '#f0f0f0' }}/>
                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/2fa.png"
                        //     alt="Two Factor Auth Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('profile.twoFactorAuth'), 
                        () => goToLink("/account/2fa")
                    )}
                    {/* {itemDisplay(<FaWallet className="img-style" />, t('profile.bankAcc'), null)} */}
                    {itemDisplay(
                        <img
                            className="icon-img-style"
                            src="images/profile_icon/crypto.png"
                            alt="Crypto Address Icon"
                            loading='lazy'
                        />
                        , 
                        t('title.cryptoAddress'), 
                        () => goToLink("/account/crypto-address")
                    )}
                </List>
                <div className="divider-list-black" />
                {/* SETTING */}
                <div className={styles.titleText}>
                    {t('profile.setting')}
                </div>
                <List>
                    {itemDisplay(
                        <Newspaper style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/news-sel.png"
                        //     alt="News & Announcements Icon"
                        //     loading='lazy'
                        // />
                        ,
                        t('title.newsAnnouncements'),
                        () => goToLink("/account/notices")
                    )}
                    {/* {itemDisplay(
                        <History style={{ color: 'white'}}/>
                        
                        // <img
                        //     className="icon-img-style"
                        //     src={`/images/profile_icon/faq-sel.png`}
                        //     alt="FAQ Icon"
                        //     loading='lazy'
                        // />
                        ,
                        t('title.faq'),
                        () => window.open(`/images/faq/teazu_faq_${i18n.language}.pdf`, '_blank')
                    )} */}
                    {/* {itemDisplay(
                        <History style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src={`/images/profile_icon/help-sel.png`}
                        //     alt="Help Center Icon"
                        //     loading='lazy'
                        // />
                        ,
                        t('title.helpCenter'),
                        () => goToLink("/help-center")
                    )} */}
                    {itemDisplay(
                        <Language style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/language-sel.png"
                        //     alt="Language Icon"
                        //     loading='lazy'
                        // />
                        , 
                        langOpen ? t('profile.language') : selectedLang === 'en' ? 'Language | 中文' : '语言 | English',
                        () => {
                            const newLang = selectedLang === 'en' ? 'cn' : 'en'; 
                            changeSystemLanguage(newLang);
                            setSelectedLang(newLang);
                        }
                    )}
                    {itemDisplay(
                        <MeetingRoom style={{ color: 'white'}}/>

                        // <img
                        //     className="icon-img-style"
                        //     src="images/profile_icon/logout-sel.png"
                        //     alt="Logout Icon"
                        //     loading='lazy'
                        // />
                        , 
                        t('profile.logout'), 
                        () => logout()
                    )}
                </List>
            </div>

            {/* <Drawer
                anchor={'bottom'}
                open={langOpen}
                onClose={() => setLangOpen(false)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <div className='p-tb-30 p-lr-50'>
                    <div className='dis-flex flex-m flex-sb-m p-b-20'>
                        <Typography className='w-full' style={{ color: 'rgba(82, 82, 82, 0.80)' }}><b>{t('general.selectOption', {option: t('profile.language')})}</b></Typography>
                    </div>

                    <div style={{ display: langOpen ? "block" : "none" }}>
                        <ListItem onClick={() => {changeSystemLanguage('en'); setLangOpen(false);}}>
                            <ListItemButton style={{ color: theme.palette.primary.main }}>
                                {t('general.en')}
                            </ListItemButton>
                        </ListItem>
                        <div className="divider-white" />
                        <ListItem onClick={() => {changeSystemLanguage('cn'); setLangOpen(false);}}>
                            <ListItemButton style={{ color: theme.palette.primary.main }}>
                                {t('general.cn')}
                            </ListItemButton>
                        </ListItem>
                        <div className="divider-white" />
                    </div>
                </div>
            </Drawer> */}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        width:'100%',
        height:'100%',
        flexShrink: 0,
        background:theme.palette.primary.main
    },
    avatarOutlineStyle: {
        borderRadius: 48,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        padding: 3,
        width: 75,
        height: 75
    },
    infoText: {
        wordBreak: 'break-all',
        color: '#F0F0F0',
        lineHeight: '14.52px',
        margin: '5px 0'
    },
    infoCard: {
        backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 40px',
        width: '100%'
    },
    titleText: {
        color: '#F0F0F0',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginTop: 20,
    },
    divider: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        height: '4px',
        margin: '5px 0'
    },
    card: {
        backgroundImage: `url('../images/profile/account_bg.png')`,
        backgroundSize: '120px 120px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '300px',
        backgroundPositionY: '85%',
        minHeight: '90vh',
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    }
}));

export default Me;