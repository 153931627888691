import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { storeProfile } from '@actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, IconButton, InputAdornment, TextField, Typography, MenuItem, FormHelperTex, FormControl, Select, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiEye, FiEyeOff, FiKey, FiLock } from "react-icons/fi";
import OutlinedInput from '@mui/material/OutlinedInput';

import { putUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import theme from '../../Theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
}; 

const Profile = () => {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const { username, name, email, mobile, mobile_country_code } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { hideSidebar } = location.state || {}; 

    const [userProfile, setUserProfile] = useState({
        name: name || '',
        username: username || '',
        email: email || '',
    });

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: mobile_country_code || "",
        mobile: mobile || "",  
        error: {},
    })

    useEffect(() => {
        setLoading(true)
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ ...mobileCodeList, country: response?.data?.country_list });
            }

        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setUserMobile({ ...userMobile, [name]: value });
            return;
        }
        if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '');
        }

        setUserProfile(prevState => ({ ...prevState, [name]: value }));
    };

    const updateProfile = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: userProfile.name,
            username: userProfile.username,
            email: userProfile.email,
            country: userMobile.country,
            mobile_code: userMobile.code,
            mobile: userMobile.mobile,
        };

        putUrl(`/user`, params).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
                if (hideSidebar) {
                    navigate('/dashboard');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };

    return (
        <div>
            <div className='txt-center w-full p-b-30 p-t-20'>
                <Typography variant='h6' component="span" style={{ fontWeight: 'bold', lineHeight: 1.1 , color: theme.palette.darkBase.main}}>{t('profile.editProfile')}</Typography>
            </div>
            <form>
            <div>
                <TextField
                    variant="standard"
                    fullWidth
                    name='username'
                    placeholder={t('user.username') + '*'}
                    value={userProfile?.username}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                    error={inputErrors && inputErrors.username ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    className={styles.textFieldBox}
                />
                <TextField
                    variant="standard"
                    fullWidth
                    name='name'
                    placeholder={t('user.name') + '*'}
                    value={userProfile?.name}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                    error={inputErrors && inputErrors.name ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    className={styles.textFieldBox}
                />
                <TextField
                    variant="standard"
                    fullWidth
                    name='email'
                    placeholder={t('user.email') + '*'}
                    value={userProfile?.email}
                    onChange={handleChange}
                    required
                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                    error={inputErrors && inputErrors.email ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    className={styles.textFieldBox}
                />
                <FormControl sx={{ width: '100%' }} variant="standard" className={styles.textFieldBox} error={inputErrors && inputErrors['country'] ? true : false}>
                    <Select
                        id="country-select"
                        value={userMobile.country || ""}
                        onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                        displayEmpty
                        input={<OutlinedInput sx={{ border: 'none' }} />}
                        renderValue={(selected) => {
                            if (!selected) {
                                return <em style={{ color: '#2E3133' }}>{t('user.country')}</em>;
                            }
                            const selectedOption = mobileCodeList?.country.find(option => option.code === selected);
                            return selectedOption ? (i18n.language === 'cn' ? selectedOption.name_cn : selectedOption.name) : selected;
                        }}
                        MenuProps={MenuProps}
                        sx={{
                            '& .MuiSelect-select': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                                color: '#2E3133',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                            },
                            '& .MuiSelect-icon': {
                                color: '#2E3133',
                            },
                        }}
                    >
                        {_.size(mobileCodeList?.country) > 0 ? (
                            _.map(mobileCodeList?.country, (option, key) => (
                                <MenuItem key={key} value={option.code} style={{ color: '#222' }}>
                                    {i18n.language === 'cn' ? option.name_cn : option.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="" style={{ color: '#222' }}>
                                {t('general.pleaseSelect')}
                            </MenuItem>
                        )}
                    </Select>
                    {
                        inputErrors && inputErrors['country'] &&
                        <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                    }
                    {
                        userMobile?.code > 0 ? (
                            <></>
                        ) : (
                            <FormHelperText className='p-l-5 fs-11' style={{lineHeight:1, color:'#8f8e8e'}}>{t('general.pleaseSelectCountry')}</FormHelperText>
                        )
                    }
                </FormControl>
                <div className='flex-sb-m w-full' style={{ flexWrap: 'wrap' }}>
                    <FormControl sx={{ width: 'calc(30% - 20px)' }} variant="standard" className={styles.textFieldBox}>
                        <Select
                            id="code-select"
                            value={userMobile.code || ""}
                            onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                            displayEmpty
                            input={<OutlinedInput sx={{ border: 'none', boxShadow: 'none' }} />}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <em style={{ color: '#2E3133' }}>{t('user.code')}</em>;
                                }
                                return selected;
                            }}
                            MenuProps={MenuProps}
                            sx={{
                                '& .MuiSelect-select': {
                                    border: 'none',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#2E3133',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                },
                                '& .MuiSelect-icon': {
                                    color: '#2E3133',
                                },
                            }}
                        >
                            {_.size(mobileCodeList?.code) > 0 ? (
                                _.map(mobileCodeList?.code, (option, key) => (
                                    <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                        {option}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" style={{ color: '#222' }}>
                                    {t('general.pleaseSelect')}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <div className='txt-center' style={{ width: 40, color: "black", marginBottom: 20 }}>
                        <p className='fs-21'>-</p>
                    </div>
                    <div style={{ width: 'calc(70% - 20px)' }}>
                        <TextField
                            placeholder={t('user.mobile')}
                            value={userMobile.mobile}
                            onChange={handleChange}
                            name="mobile"
                            variant="standard"
                            fullWidth
                            // helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            // error={inputErrors && inputErrors.mobile ? true : false}
                            // InputLabelProps={{ shrink: true }}
                            // InputProps={{ disableUnderline: true }}
                            className={styles.textFieldBox}
                        />
                    </div>
                    {inputErrors?.mobile_code && !inputErrors?.mobile && (
                        <FormHelperText style={{ color: 'red' }} className="p-l-10">
                            {inputErrors.mobile_code}
                        </FormHelperText>
                    )}

                    {inputErrors?.mobile && !inputErrors?.mobile_code && (
                        <FormHelperText style={{ color: 'red' }} className="p-l-10">
                            {inputErrors.mobile}
                        </FormHelperText>
                    )}

                    {inputErrors?.mobile_code && inputErrors?.mobile && (
                        <FormHelperText style={{ color: 'red' }} className="p-l-10">
                            {t('error.mobileCodeAndMobileError')}
                        </FormHelperText>
                    )}
                </div>
                
                <div className='flex-c-m m-t-20'>
                    <Button variant="contained" onClick={updateProfile} className='big-button' fullWidth>{t('button.update')}</Button>
                </div>
            </div>
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldBox: {
        marginBottom: 20
    }
}));

export default Profile