import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Typography, Grid, Box , useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import MerchantWithdrawalsTable from '../../layouts/MerchantWithdrawalsTable';
import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';

// import { currencyFormat } from '@utils/Tools';

const WithdrawalsHistory = () => {
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [filterData, setFilterData] = useState('all');
    const [status, setStatus] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    const [filters, setFilters] = useState([
        { title: t('transaction.filterOptions.all'), value: 'all' },
    ]);

    useEffect(() => {
        let newFilters = [
            { title: t('transaction.filterOptions.all'), value: 'all' },
        ]
        newFilters = [
            ...newFilters,
            ..._.map(status, (data, key) => ({
                value: key,
                title: data[i18n.language] ? data[i18n.language] : data?.en,
            }))
        ];
        
        setFilters(newFilters);
      
    }, [status, t, i18n.language]); 
    
    const callApi = () => {
        setLoading(true);
        let apiData = {
            per_page: 10,
            page: page,
            filter_data: filterData,
            start_date : (startDate) ? startDate.format('YYYY-MM-DD') : null,
            end_date : (endDate) ? endDate.format('YYYY-MM-DD') : null
        }

        getUrl(`/member-get-merchant-withdrawals-record`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                let { data, current_page, last_page } = response.data;
                if (_.size(data) > 0)
                {
                    setTotal(last_page);
                    setCurrPage(current_page);
                }
                setWalletTransactions(data); 
                setStatus(response.display_status)
            } else {
                // navigate(`/`);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
            // navigate(`/`);
        });
    }   

    useEffect(() => {
        callApi();
        
        // eslint-disable-next-line
    }, [page, filterData, searchDate]);

    return (
        <div className='dashboard-container'>

            <Grid container>
                <Grid item xs={12} sm={12} md={12} style={{display:'flex', justifyContent:'space-between'}} container >
                    <Grid item xs={12} md={6}>
                        <Typography variant='h6' className={styles.colorLightGray} style={{ fontWeight: 'bold' }}>
                            {t('title.merchantWithdrawalsHistory')}
                        </Typography>
                        {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                    </Grid>
                    <Grid  item xs={12} md={6} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Grid item  sx={{ justifyContent:{ xs: 'flex-end'} , display:'flex'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                                <div style={{ paddingRight: 10 }}>
                                    <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                                </div>
                            </div>
                        </Grid>
                        <AllButton filters={filters} handleChange={setFilterData} />
                    </Grid>
                </Grid>

                {walletTransactions.length > 0 
                ? (
                    <Grid item xs={12} style={{ padding: '10px 0', cursor: 'pointer' }} >
                        <Box>
                            <MerchantWithdrawalsTable history={walletTransactions} total={total} setPageApi={setPage} pageApi={page} status={status}/>
                        </Box>
                    </Grid>
                ) 
                : (
                    <Grid item xs={12} className={styles.endFontStyle} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <img src={'/images/general/filter-search-empty-data.png'} alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }}  loading='lazy'/>
                        <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20}}>
                            {t('table.noTransactionYet')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    colorLightGray: {
        color: '#A7A7A7'
    }
}));

export default WithdrawalsHistory;