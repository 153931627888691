import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Typography, Grid, Box, FormControl, Select, MenuItem, Button, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';


import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import TransactionItem from '@components/TransactionItem';
import WalletItem from '@components/WalletItem';
import BonusTable from '../../layouts/TransactionTable';
import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';

// Date
import moment from 'moment';

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { currencyFormat } from '@utils/Tools';

const Transaction = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);
    let currDate = moment();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    
    const [searchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState('all');
    const [wallet, setWallet] = useState({});
    const [walletBalance, setWalletBalance] = useState('');
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [selectedWalletId, setSelectedWalletId] = useState(null);
    const [sumCredit, setSumCredit] = useState(0);
    const [sumDebit, setSumDebit] = useState(0);
    const [state, setState] = useState({ id: '', });
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState('all');
    const [showCustomRange, setShowCustomRange] = useState(false);

    // Date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    const filters = [
        {title: t('transaction.filterOptions.all'), value: 'all'},
        {title: t('transaction.filterOptions.credit'), value: 'credit'},
        {title: t('transaction.filterOptions.debit'), value: 'debit'},
    ];

    const handleFilterChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
    
        setShowCustomRange(selectedOption === 'customRange');
    };
    
    // const handleFilterOptionsChange = (event) => {
    //     const selectedFilterCreditDebit = event.target.value;
    //     setSelectedFilterOptions(selectedFilterCreditDebit);
    // };

    useEffect(() => {
        if (selectedOption === 'customRange') {
            callApi();
        }
        // eslint-disable-next-line
    }, [searchDate]);
    
    const callApi = () => {
        setLoading(true);
        isMountedRef.current = true;
        if (typeof(searchParams.get("w")) === 'string') {
            try {
                let id = parseInt(atob(searchParams.get("w")));
                
                if (!isNaN(id)) {
                    setState({ ...state, id: id, });
                    let apiData = {
                        id: id,
                        filter_option: selectedOption,
                        per_page: 10,
                        page: page,
                        filter_credit_debit: selectedFilterOptions,
                        start_date : (selectedOption === 'customRange' && startDate) ? startDate.format('YYYY-MM-DD') : null,
                        end_date : (selectedOption === 'customRange' && endDate) ? endDate.format('YYYY-MM-DD') : null
                    }

                    getUrl(`/member-transaction-list`, apiData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            let { data, current_page, last_page } = response.data;
                            if (_.size(data) > 0)
                            setTotal(last_page);
                            setCurrPage(current_page);
                            setWalletBalance(response.wallet.wallet_balance);
                            setWallet(response.wallet.wallet)
                            setSumCredit(response.sum_credit);
                            setSumDebit(response.sum_debit);
                            // const filterOptionParam = searchParams.get("filter_option");
                            // setSelectedOption(filterOptionParam || 'all');

                            // if (_.size(data) > 0)
                            //     setCurrPage(current_page);
                            // if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                            // if (page > 1) { setWalletTransactions(_.concat(walletTransactions, data)); } 
                            // else { 
                                setTotal(last_page);
                                setWalletTransactions(data); 
                            // }
                        } else {
                            navigate(`/`);
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                        navigate(`/`);
                    });
                } else {
                    setLoading(false);
                    navigate(`/`);
                }
            } catch (error) {
                setLoading(false);
                navigate(`/`);
            }
        } else {
            setLoading(false);
            navigate(`/`);
        }
    }   

    useEffect(() => {
        callApi();
        
        // return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
        // eslint-disable-next-line
    }, [page, selectedOption, searchParams, selectedFilterOptions]);

    // const handleScroll = () => {
    //     const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    //     if (bottom) { 
    //         setPage(page + 1); 
    //     }
    // }

    return (
        <div className='dashboard-container'>
            {/* Title Transaction */}
            <div className='flex-sb-m w-full p-b-10'>
                <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: '1.5', verticalAlign: 'middle', color: '#A7A7A7' }}>
                    {t('transaction.title')}                  
                </Typography>
                <div className='flex-m'>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <Select
                            value={selectedOption}
                            onChange={handleFilterChange}
                            displayEmpty
                            style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: 0, borderRadius: 0, color: '#F0F0F0', textAlignLast:'center' }}
                            MenuProps={{
                                style: { backgroundColor: 'transparent' }
                            }}
                            IconComponent={() => <ArrowDropDownIcon style={{ color: '#F0F0F0' }} />}
                            sx={{'& .MuiOutlinedInput-input': { padding: '0 !important' }}}
                        >
                            <MenuItem value="all">{t('button.all')}</MenuItem>
                            <MenuItem value="thisWeek">{t('transaction.thisWeek')}</MenuItem>
                            <MenuItem value="thisMonth">{t('transaction.thisMonth')}</MenuItem>
                            <MenuItem value="thisYear">{t('transaction.thisYear')}</MenuItem>
                            <MenuItem value="customRange">{t('transaction.customRange')}</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <FiChevronDown /> */}
                </div>
            </div>

            <Grid container spacing={3} className='p-b-30'>
                <Grid item xs={12} sm={12} md={5}>
                    <WalletItem
                        walletValue={walletBalance}
                        walletDecimal={wallet?.decimal}
                        walletCode={wallet?.code}
                        walletName={wallet?.name && wallet?.name[i18n.language] ? wallet.name[i18n.language] : wallet?.name?.cn}
                        clickable={false}
                    />
                </Grid>
                <Grid container spacing={3} item xs={12} sm={12} md={7}>
                    <Grid item xs={12} sm={6} md={6} >
                        <div className='box-out flex-c-m p-tb-20' style={{minHeight:160}}>
                            <div className='txt-center txt-white'>
                                <p className='fs-25'><b>{currencyFormat(sumCredit, 2)}</b></p>
                                {/* <p className='fs-25'><b>{Number(sumCredit).toFixed(2)}</b></p> */}
                                <p>{t('transaction.totalCredit')}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >
                        <div className='box-out flex-c-m p-tb-20' style={{minHeight:160}}>
                            <div className='txt-center txt-white'>
                                <p className='fs-25'><b>{currencyFormat(sumDebit, 2)}</b></p>
                                {/* <p className='fs-25'><b>{Number(sumDebit).toFixed(2)}</b></p> */}
                                <p>{t('transaction.totalDebit')}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            {/* Transaction History */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12} style={{display:'flex', justifyContent:'space-between'}} container >
                    <Grid item xs={12} md={6}>
                        <Typography variant='h6' className={styles.colorLightGray} style={{ fontWeight: 'bold' }}>
                            {t('transaction.title')}
                        </Typography>
                        {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                    </Grid>
                    <Grid  item xs={12} md={6} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {/* DatePicker for start date and end date */}
                        {
                            showCustomRange && (
                                <Grid item  sx={{ justifyContent:{ xs: 'flex-end'} , display:'flex'}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                        {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                                        <div style={{ paddingRight: 10 }}>
                                            <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                                        </div>
                                    </div>
                                </Grid>
                            )
                        }
                        <AllButton filters={filters} handleChange={setSelectedFilterOptions} />
                    </Grid>
                </Grid>

                {/* Wallet Listing */}
                {walletTransactions.length > 0 ? (
                    <Grid item xs={12} style={{ padding: '10px 0', cursor: 'pointer' }} >
                        {/* <Box>
                            <TransactionItem
                                transaction={transaction}
                                selectedWalletId={selectedWalletId}
                                style={{ cursor: 'pointer' }}
                            />
                        </Box> */}
                        <Box>
                            <BonusTable history={walletTransactions} total={total} setPageApi={setPage} pageApi={page}/>
                        </Box>
                    </Grid>
                ) : (
                    // Display "NO DATA" when walletTransactions is empty
                    <Grid item xs={12} className={styles.endFontStyle} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <img src={'/images/general/filter-search-empty-data.png'} alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }}  loading='lazy'/>
                        <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20}}>
                            {t('table.noTransactionYet')}
                        </Typography>
                    </Grid>
                )}

                {/* {walletTransactions.length > 0 && (
                    <Grid item xs={12} className={styles.endFontStyle}>
                        <Typography style={{color: '#B5B5B5'}}>
                            { currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                        </Typography>
                    </Grid>
                )} */}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    colorLightGray: {
        color: '#A7A7A7'
    }
}));

export default Transaction;