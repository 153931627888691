import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLock, MdEmail } from "react-icons/md";
import { MdGroup } from "react-icons/md";

import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function ResetPassword() {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const [state, setState] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        username: '',
        token: searchParams.get("token")
    });

    const [option, setOption] = useState({
        password_show: false,
        password_confirmation_show: false,
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        let postData = {
            email: state.email,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            token: state.token,
            username: state.username,
        }
        postUrl('/reset-password', postData).then(response => {
            setLoading(false);
            setInputErrors();
            if (response.status) {
                addAlert('', response.message, 'success', '');
                navigate('/login');
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                // _.map(response.errors, (value, index) => {
                //     msg += ` ${value}`;
                // })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            {/* //////////////////////////////////////////////////////////////// */}
            {/* <div style={{ paddingTop: '10vh', backgroundColor: '#222', minHeight: '100vh', backgroundImage: `url('../images/auth/login-bg2.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
            <div style={{ paddingTop: '10vh', backgroundColor: '#222', minHeight: '100vh' }}>
                <div className='login-container flex-c-m' style={{width: 950}}>
                    <div className='forgot-img'>
                        {/* <img src='/images/auth/forgot.png' alt="forgot" className='w-full' /> */}
                    </div>

                    <div className='login-box pos-relative'>
                        {/* <div style={{ backgroundImage: `url('../images/auth/mountain.png')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom', backgroundSize: 'contain', paddingBottom: 30, width: 400, maxWidth: '100%', margin: '0 auto' }}> */}
                        <div style={{  paddingBottom: 30, width: 400, maxWidth: '100%', margin: '0 auto' }}>
                            <p className='fs-30' ><b>{t('resetPassword.title')}</b></p>
                            <p>{t('resetPassword.desc')}</p>
                        </div>
                        <Box className='pos-relative max-w-full' style={{ width: 400, margin: '0 auto' }}>

                            <TextField
                                variant="standard"
                                placeholder={t('user.email')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEmail className="img-style" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type="email"
                                autoComplete="email"
                                color='primary'
                                value={state.email}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                                // helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                // error={inputErrors && inputErrors.email ? true : false}
                                className={classes.textFieldStyle}
                            />
                            <TextField
                                variant="standard"
                                placeholder={t('user.username')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdGroup className="img-style" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type="username"
                                autoComplete="username"
                                color='primary'
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                // helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                // error={inputErrors && inputErrors.username ? true : false}
                                className={classes.textFieldStyle}
                            />
                            {
                                _.size(inputErrors) > 0
                                &&
                                ((Object.keys(Object.assign({}, inputErrors))).includes('username')
                                    ||
                                    (Object.keys(Object.assign({}, inputErrors))).includes('email')
                                    ||
                                    (Object.keys(Object.assign({}, inputErrors))).includes('record')
                                )
                                &&
                                (
                                    <Typography
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        {t('error.recordNotFound')}
                                    </Typography>
                                )
                            }
                            <TextField
                                variant="standard"
                                placeholder={t('user.password')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdLock className="img-style" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handlePasswordShow('password_show')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {option?.password_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                autoComplete="password"
                                color='primary'
                                value={state.password}
                                type={option?.password_show ? 'text' : 'password'}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                className={classes.textFieldStyle}
                            />
                            <TextField
                                variant="standard"
                                placeholder={t('user.confirmPassword')}
                                type={option?.password_confirmation_show ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdLock className="img-style" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handlePasswordShow('password_confirmation_show')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {option?.password_confirmation_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                autoComplete="password"
                                color='primary'
                                value={state.passwordConfirmation}
                                onChange={({ target }) => setState({ ...state, passwordConfirmation: target.value })}
                                helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                error={inputErrors && inputErrors.password_confirmation ? true : false}
                                className={classes.textFieldStyle}
                            />
                            <Button fullWidth variant="contained" color="primary" onClick={resetPassword} className='big-button' style={{ marginTop: 20 , background: theme.palette.primary.main }} >
                                {t('button.reset')}
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '70%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));