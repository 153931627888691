import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';

import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

const WalletItem = props => {
    const { walletName, walletCode, walletValue, walletDecimal, walletType, background, labelColor, urlLink , clickable } = props;
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    // Determine wallet icon path based on the wallet code
    const getWalletIconPath = () => {
        switch (walletCode) {
            case 'tw':
                return '/images/wallet_icon/icon_wallet_tw.png';
            case 'rw':
                return '/images/wallet_icon/icon_wallet_rw.png';
            case 'ew':
                return '/images/wallet_icon/icon_wallet_ew.png';
            case 'bw':
                return '/images/wallet_icon/icon_wallet_bw.png';
            default:
                return null;
        }
    };

    return (
        <Box className={styles.wallet} style={{ background: `${background} center fixed`, backgroundSize: 'cover', boxShadow: '3px 3px 6px 0px rgba(0, 0, 0, 0.25) inset', height: '160px' }}>
            {/* wallet icon */}
            {/* {getWalletIconPath() && (
                <div style={{ alignSelf: 'flex-end', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        src={getWalletIconPath()}
                        alt={`Wallet Icon - ${walletName}`}
                        style={{ maxWidth: '50px', maxHeight: '50px' }}
                    />
                    <Typography style={{ color: labelColor, fontSize: '12px', marginTop: '2px' }}>{walletName}</Typography>
                </div>
            )} */}
            <div style={{ textAlign:'right' , width:'100%'}}>
                <Typography style={{ color: labelColor, fontSize: '29px', lineHeight:'unset', letterSpacing:'4px'}}><b>{walletCode}</b></Typography>
                <Typography style={{ color: labelColor, fontSize: '12px', marginTop: '-8px', fontWeight:500 }}><b>{walletName}</b></Typography>
            </div>     

            {/* Amount and Font of Wallet Balance */}
            <div style={{ alignItems: 'flex-start', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant="h6"
                    style={{
                        color: labelColor,
                        wordBreak: 'break-all',
                        fontSize:'23px'
                    }}
                >
                    {
                        _.includes(['BTA','BTP','BT2'],walletCode)?
                        <b>{walletValue ? currencyFormat(walletValue, walletDecimal) : currencyFormat(0, walletDecimal)} {walletType} {t('general.unit')} </b>
                        // <b>{walletValue ? (parseFloat(walletValue)).toFixed(walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType} {t('general.unit')} </b>
                        :
                        <b>$ {walletValue ? currencyFormat(walletValue, walletDecimal) : currencyFormat(0, walletDecimal) } {walletType}</b>
                        // <b>$ {walletValue ? (parseFloat(walletValue)).toFixed(walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType}</b>
                    }

                </Typography>

                <Typography style={{ color: labelColor, textTransform: 'capitalize', fontSize:'14px' , fontWeight:500 }}>
                    {t('wallet.balance')}
                </Typography>
            </div>
        </Box>
    )
}

const useStyles = makeStyles((theme, clickable) => ({
    wallet: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between', 
        height: '100%', 
        padding: 20, 
        borderRadius: '16px', 
        overflow: 'hidden', 
        // marginTop: '20px', 
        // border: '1px solid #E7E7E7', 
        cursor: clickable? 'pointer': 'default',
        boxShadow: '3px 3px 12px 0px rgba(255, 255, 255, 0.25) inset',
    }
}));

// DEFINE DEFAULT PROPS TYPE
WalletItem.propTypes = {
    walletName: PropTypes.string,
    walletLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
    clickable: PropTypes.bool,
};

// DEFINE DEFAULT PROPS VALUE
WalletItem.defaultProps = {
    walletName: '',
    walletLogo: "",
    walletValue: '0',
    walletDecimal: 0,
    background: 'linear-gradient(109.07deg, #A51616 3.41%, #C33838 98.09%)',
    labelColor: '#ffffff',
    clickable: true,
};

export default WalletItem;