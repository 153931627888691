import _ from 'lodash';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BonusItem from '@components/BonusItem';

const BonusListing = props => {
    const { listing } = props;
    const { t, i18n } = useTranslation();

    const bgColors = [
        'linear-gradient(109.07deg, #A51616 3.41%, #C33838 98.09%)',
        'linear-gradient(109.07deg, #A51616 3.41%, #C33838 98.09%)',
        'linear-gradient(109deg, #E15959 3.41%, #FF7B7B 98.09%)',
        'linear-gradient(109deg, #E15959 3.41%, #FF7B7B 98.09%)',
    ];

    return (
        <Grid container spacing={2} style={{ padding: '20px 0', margin:0, width:'100%' }}>
            {_.size(listing) > 0 ? (
                _.map(listing, (data, key) => {
                let setBgIndex = key % _.size(listing);
                let bonusBgColor = bgColors[setBgIndex];
                return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={key} style={{ margin:'13px 0', width: '100%', padding:0 }}>
                    <BonusItem
                        bonusName={data?.bonus_type}
                        bonusCode={data?.bonus_type}
                        bonusValue={parseFloat(data?.total_amount)}
                        bonusDecimal={2}
                        bonusType={data?.name?.[i18n.language] ? data?.name?.[i18n.language] : data?.name?.cn}
                        background={bonusBgColor}
                        labelColor={setBgIndex === 0 ? '#000000' : '#ffffff'}
                        urlLink={`/bonus?b=${btoa(data?.id)}`}
                    />
                    </Grid>
                );
                })
            ) : (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                    <Typography style={{color: 'black'}}>{t('general.noBonusData')}</Typography>
                </Grid>
            )}
        </Grid>

    );
}

export default BonusListing;