import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _, { filter } from 'lodash';

import { Grid, Typography, Button, Hidden, Menu, MenuItem, ThemeProvider, Stack, Pagination, createTheme, useMediaQuery, Tooltip, List, ListItem, ListItemIcon, Drawer } from '@mui/material';
import { makeStyles, useTheme, styled } from '@mui/styles';
import { FiMoreVertical } from "react-icons/fi";
import { Girl, Tune } from '@mui/icons-material';

import { storePaths, currencyFormat,convertToK } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import { getUrl } from '@utils/ApiAction';

const RedeemList = () => {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    let navigate = useNavigate();
    const location = useLocation();

    const { id } = useSelector((state) => state.user);
    const { permissions } = useSelector((state) => state.general);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();
    
    const [tradeProfile, setTradeProfile] = useState({});
    const [redeemList, setRedeemList] = useState({});
    const [currentPrice, setCurrentPrice] = useState(0.000);
    const [totalMerchantValue, setTotalMerchantValue] = useState(0);
    const [system, setSystem] = useState({});
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [sortBy, setSortBy] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState(null);

    const open = Boolean(anchorEl);
    const filters = [
        {title: `-- ${t('general.pleaseSelect')} --`, value: ''},
        {title: `${t('trade.totalValue')} - ${t('general.asc')}`, value: {id: 'redeemable_value', desc: 'ASC'}},
        {title: `${t('trade.totalValue')} - ${t('general.desc')}`, value: {id: 'redeemable_value', desc: 'DESC'}},
        {title: `${t('trade.merchants')} - ${t('general.asc')}`, value: {id: 'merchants.name', desc: 'ASC'}},
        {title: `${t('trade.merchants')} - ${t('general.desc')}`, value: {id: 'merchants.name', desc: 'DESC'}},
    ];

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        let apiData = {
            per_page: 5,
            page: page,
            sorting: filters?.[sortBy]?.value,
            language: i18n.language,
        }

        getUrl(`/member-trade-redeem/${id}`, apiData).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data } = response;
                let { current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);

                if (status) {
                    setTradeProfile(data.profile);
                    setCurrentPrice(currencyFormat(data.current_price, 3));
                    setRedeemList(response.data.listing.data);
                    setSystem(data.system);
                    setTotalMerchantValue(parseFloat(data?.total_merchant_value || 0));
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, page, sortBy]);

    let totalTransfer = tradeProfile?.active_unit + tradeProfile?.passive_unit || 0;
    let totalTransferApprox = currencyFormat((totalTransfer * currentPrice), 2) || 0;
    let globalValue = parseFloat(system?.redeemable_value) || 0;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHandleFilter = (event, index) => {
        setSortBy(index);
        setAnchorEl(null);
    }

    const onChangePage = (page) => {
        setPage(page);
    }

    {/* For System and Merchant Transfer */}
    const onClickTransfer = (merchant_id) => {
        navigate(`/trade/transfer?m=${btoa(merchant_id)}`, { state: { from: storePaths(location) } });
    }

    const onClickConvert = (merchant_id) => {
        navigate(`/to-thirdparty/form?m=${btoa(merchant_id)}`, { state: { from: storePaths(location) } });
    }

    {/* For Special Transfer */}
    const onClickSpecialTransfer = () => {
        navigate(`/trade/special-transfer`, { state: { from: storePaths(location) } });
    }

    const handleCloseDrawer = () => {
        // Close the drawer
        setDrawerOpen(false);
    };

    const handleMerchantClick = (data) => {
        // Open the drawer and set the selected wallet data
        setDrawerOpen(true);
        setSelectedMerchant(data);
      };

    // Function to render drawer content
    const renderDrawerContent = (data) => {
        return (
            <div className={styles.drawerContent}>
                <Typography variant='h6' className={styles.drawerTitle}>{t('general.pleaseSelect')}</Typography>
                <List>
                    {
                        data?.merchant?.allow_third_party_convert ?
                            <React.Fragment>
                                <ListItem
                                    onClick={() => onClickConvert(data?.merchant_id)}
                                    className={styles.listItem}
                                >
                                    <ListItemIcon>
                                        <img src="/images/wallets/third_party_convert_black.png" alt="convert_logo" className={styles.icon} loading='lazy' />
                                    </ListItemIcon>
                                    <Typography style={{ color: 'black' }}>{t('title.convert')}</Typography>
                                </ListItem>
                                <div className={styles.rectangle}></div>
                            </React.Fragment>
                            : null
                    }
                    <React.Fragment>
                        <ListItem
                            onClick={() => onClickTransfer(data?.merchant_id)}
                            className={styles.listItem}
                        >
                            <ListItemIcon>
                                <img src="/images/wallets/icon_transfer_black.png" alt="transfer_logo" className={styles.icon} loading='lazy' />
                            </ListItemIcon>
                            <Typography style={{ color: 'black' }}>{t('title.transfer')}</Typography>
                        </ListItem>
                        {/* No rectangle for the last item */}
                    </React.Fragment>
                </List>
            </div>
        )
    };  

    return (
        <div className='dashboard-container' style={{ paddingBottom: 20 }}>
            <div className='flex-col-c-m w-full p-b-10'>
                <div className={styles.redeemBonusToken} style={{ width: mobileView ? '100%' : '80%' }}>
                    <div className={styles.redeemCurrentPrice} style={{ width: mobileView ? '85%' : '70%' }}>
                        <Typography variant='body1' className='txt-center'><b>{t('trade.redeemBonusToken')}</b></Typography>
                        <Typography variant='body2' className='txt-center'><i>{t('trade.currentPrice')}: ${parseFloat(currentPrice).toFixed(3)}</i></Typography>
                    </div>
                    {/* Total Transferable */}
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} sm={4}>
                            <Typography variant='body1'>{t('trade.totalTransfer')}</Typography>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='body2' className='txt-right'><b>{totalTransfer} {t('trade.units')}</b></Typography>
                                <Typography variant='body2' className='txt-right'><b>≈ ${totalTransferApprox}</b></Typography>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='h6' className='txt-center'><b>{totalTransfer} {t('trade.units')}</b></Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='h6' className='txt-center'><b>≈ ${totalTransferApprox}</b></Typography>
                            </Grid>
                        </Hidden>
                    </Grid>

                    {/* Total Merchants Value */}
                    <Grid container className='w-full flex-m p-t-15'>
                        <Grid item xs={6} sm={4}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('trade.totalMerchantValue')}</Typography>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='body2' className='txt-right' style={{ color: '#adadad' }}>≈ {Math.round(totalMerchantValue / currentPrice) || 0} {t('trade.units')}</Typography>
                                <Typography variant='body2' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(totalMerchantValue, 2)}</Typography>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='body1' className='txt-center' style={{ color: '#adadad' }}>≈ {Math.round(totalMerchantValue / currentPrice) || 0} {t('trade.units')}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant='body1' className='txt-center' style={{ color: '#adadad' }}>${currencyFormat(totalMerchantValue, 2)}</Typography>
                            </Grid>
                        </Hidden>
                    </Grid>

                    {/* Global Value */}
                    <Grid container className='w-full flex-m p-t-15'>
                        <Grid item xs={6} sm={4}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('trade.globalValue')}</Typography>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={6} sm={4} className='flex-col-r-m' style={{ flexDirection: 'column'}}>
                                <div style={{ padding: '0px 10px', background: theme.palette.primary.main, borderRadius: '9px 0px 9px 0px' }}>
                                    <Typography variant='body2' className='txt-center' style={{ color: '#f0f0f0' }}>+ {Math.round(globalValue / currentPrice) || 0}  {t('trade.units')}</Typography>
                                </div>
                                <div style={{ padding: '0px 10px', background: theme.palette.primary.main, borderRadius: '9px 0px 9px 0px', marginTop: 10 }}>
                                    <Typography variant='body2' className='txt-center' style={{ color: '#f0f0f0' }}>+ ${currencyFormat(globalValue, 2)}</Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item xs={6} sm={4} className='flex-c'>
                                <div style={{ width: '70%', background: theme.palette.primary.main, borderRadius: '9px 0px 9px 0px' }}>
                                    <Typography variant='body1' className='txt-center' style={{ color: '#f0f0f0' }}>+ {Math.round(globalValue / currentPrice) || 0}  {t('trade.units')}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4} className='flex-c'>
                                <div style={{ width: '70%', background: theme.palette.primary.main, borderRadius: '9px 0px 9px 0px' }}>
                                    <Typography variant='body1' className='txt-center' style={{ color: '#f0f0f0' }}>+ ${currencyFormat(globalValue, 2)}</Typography>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>

                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' }} />

                <Grid container style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center', paddingTop: 20 }}>
                    <Grid item xs={4} md={6}>
                        <Typography variant={mobileView ? 'body1' : 'h6'} style={{ color: '#adadad' }}><b>{t('trade.transferList')}</b></Typography>
                    </Grid>
                    <Grid item xs={8} md={6} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div style={{ paddingRight: 10 }}>
                            <Typography variant='body2' className='txt-upper' style={{ lineHeight: 1.2, color: '#adadad' }}>{t('general.sortBy')}</Typography>
                            <Typography style={{ fontSize: mobileView ? 15 : 18, lineHeight: 1.2, color: '#adadad' }}><b>{filters?.[sortBy]?.title}</b></Typography>
                        </div>
                        
                        <Button variant="contained" className={styles.squareButton} onClick={handleClick}>
                            <Tune style={{ fontSize: 25, color: '#f0f0f0', rotate: '90deg' }} />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {
                                _.map(filters, (filter, idx) => (
                                    <MenuItem key={idx} onClick={(event) => onHandleFilter(event, idx)}>{filter?.title}</MenuItem>
                                ))
                            }
                        </Menu>
                    </Grid>
                </Grid>

                {/* System Data */}
                <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: mobileView ? '20px' : '20px 40px' }}>
                    <Grid container className='flex-l-m p-tb-10'>
                        {
                            !mobileView
                                ? <>
                                    <Grid item xs={4} className='flex-l-m'>
                                        <img src={'/images/logos/logo.png'} className='w-full m-r-15' alt="merchant_logo" loading='lazy' style={{ width: 74, height: 74, aspectRatio: 1 / 1 }} />
                                        <Typography variant='body1'><b>{t('trade.global')}</b></Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='body1' className='txt-center p-r-10' style={{ fontWeight: 'bold' }}>${currencyFormat(parseFloat(system?.redeemable_value || 0), 2)}</Typography>
                                        <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('trade.totalValue')}</b></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='body1' className='txt-center p-r-10' style={{ fontWeight: 'bold' }}><i>≈ {Math.round(globalValue / currentPrice) || 0}</i></Typography>
                                        <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('trade.approxUnit')}</b></Typography>
                                    </Grid>
                                </>
                                : <Grid item xs={9} className='flex-l'>
                                    <div style={{ lineHeight: 0, width: 65, minWidth: 60, aspectRatio: 1 / 1, marginRight: 10 }}>
                                        <img src={'/images/logos/logo.png'} alt="merchant_logo" loading='lazy'
                                            style={{ objectFit: 'cover', borderRadius: 5, width: '100%' }}
                                        />
                                    </div>
                                    <div className='flex-col-l-m w-full'>
                                        <Typography variant='caption' noWrap style={{ lineHeight: 1.1 }}><b>{t('trade.global')}</b></Typography>

                                        <div className='flex-l-m w-full p-t-5'>
                                            <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>${currencyFormat(parseFloat(system?.redeemable_value || 0), 2)} <b>{t('trade.totalValue')}</b></Typography>
                                        </div>

                                        <div className='flex-l-m w-full p-t-5'>
                                            <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}><i>≈ {Math.round(globalValue / currentPrice) || 0}</i> <b>{t('trade.approxUnit')}</b></Typography>
                                        </div>
                                    </div>
                                </Grid>
                        }

                        <Grid item xs={3} className='flex-r-m'>
                            {
                                !mobileView
                                    ? <Button variant="contained" onClick={() => onClickTransfer(0)} className={styles.buttonRedDesign} style={{ width: '70%' }}>
                                        <Typography variant='body2' className='txt-center txt-upper' style={{ color: '#f0f0f0' }}>{t('title.transfer')}</Typography>
                                    </Button>
                                    : <Tooltip title={t('title.transfer')}>
                                        <Button variant="contained" onClick={() => onClickTransfer(0)} className={styles.buttonSmallRedDesign} style={{ minWidth: 0, aspectRatio: 1 / 1, padding: 5, width:40}}>
                                            <img src={'/images/wallets/icon_transfer_white.png'} className='s-full' alt="transfer_logo" loading='lazy' style={{ color: '#f0f0f0' }} />
                                        </Button>
                                    </Tooltip>
                            }

                        </Grid>
                    </Grid>
                </div>

                {/* Passive Unit Transfer */}
                {
                    _.includes(permissions, "member-special-transfer") &&
                    <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: mobileView ? '20px' : '20px 40px' }}>
                        <Grid container className='flex-l-m p-tb-10'>
                            {
                                !mobileView
                                    ? <>
                                        <Grid item xs={4} className='flex-l-m'>
                                            <img src={'/images/trade/passive_unit_transfer.png'} className='w-full m-r-15' alt="merchant_logo" loading='lazy' style={{ width: 74, height: 74, aspectRatio: 1 / 1 }} />
                                            <Typography variant='body1'><b>{t('trade.passiveUnitTransfer')}</b></Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant='body1' className='txt-center p-r-10' style={{ fontWeight: 'bold' }}><i>≈ ${currencyFormat(parseFloat(tradeProfile?.passive_unit * currentPrice || 0), 2)}</i></Typography>
                                            <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('trade.approxValue')}</b></Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='body1' className='txt-center p-r-10' style={{ fontWeight: 'bold' }}>{tradeProfile?.passive_unit || 0}</Typography>
                                            <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('spend.passiveUnit')}</b></Typography>
                                        </Grid>
                                    </>
                                        : <Grid item xs={9} className='flex-l'>
                                            <div style={{ lineHeight: 0, width: 65, minWidth: 60, aspectRatio: 1 / 1, marginRight: 10 }}>
                                                <img src={'/images/trade/passive_unit_transfer.png'} alt="merchant_logo" loading='lazy'
                                                    style={{ objectFit: 'cover', borderRadius: 5, width: '100%' }}
                                                />
                                            </div>
                                        <div className='flex-col-l-m w-full'>
                                            <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}><b>{t('trade.passiveUnitTransfer')}</b></Typography>

                                            <div className='flex-l-m w-full p-t-5'>
                                                <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}><i>≈ ${currencyFormat(parseFloat(tradeProfile?.passive_unit * currentPrice || 0), 2)}</i> <b>{t('trade.approxValue')}</b></Typography>
                                            </div>

                                            <div className='flex-l-m w-full p-t-5'>
                                                <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>{tradeProfile?.passive_unit || 0} <b>{t('spend.passiveUnit')}</b></Typography>
                                            </div>
                                        </div>
                                    </Grid>
                            }

                            <Grid item xs={3} className='flex-r-m'>
                                {
                                    !mobileView
                                        ? <Button variant="contained" onClick={() => onClickSpecialTransfer(id)} className={styles.buttonRedDesign} style={{ width: '70%' }}>
                                            <Typography variant='body2' className='txt-center txt-upper' style={{ color: '#f0f0f0' }}>{t('title.transfer')}</Typography>
                                        </Button>
                                        : <Tooltip title={t('title.transfer')}>
                                            <Button variant="contained" onClick={() => onClickSpecialTransfer(id)} className={styles.buttonSmallRedDesign} style={{ minWidth: 0, aspectRatio: 1 / 1, padding: 5, width:40}}>
                                                <img src={'/images/wallets/icon_transfer_white.png'} className='s-full' alt="transfer_logo" loading='lazy' style={{ color: '#f0f0f0'}} />
                                            </Button>
                                        </Tooltip>
                                }

                            </Grid>
                        </Grid>
                    </div>
                }

                {/* Merchant Data */}
                <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: mobileView ? '20px' : '20px 40px' }}>
                    {
                        !mobileView ?
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant='body1' style={{ color: '#adadad' }}><b>{t('plan.merchantName')}</b></Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('trade.totalValue')}</b></Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant='body1' className='txt-center' style={{ color: '#adadad', lineHeight: 1.5 }}><b>{t('trade.approxUnit')}</b></Typography>
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{ color: '#adadad' }}><b>{t('plan.merchantName')}</b></Typography>
                                </Grid>
                            </Grid>
                    }

                    {
                        _.size(redeemList) > 0 ?
                            _.map(redeemList, (data, idx) => {
                                if (data?.merchant_id !== 0) {
                                    let approxUnitMerchant = Math.round(parseFloat(data?.redeemable_value) / currentPrice) || 0;
                                    let approxUnitGlobal = Math.round(globalValue / currentPrice) || 0;
                                    return (
                                        <Grid container key={idx} className='flex-l-m p-tb-10'>
                                            {
                                                !mobileView
                                                    ? <>
                                                        <Grid item xs={4} className='flex-l-m' style={{ position: 'relative' }}>
                                                            <div style={{ position: 'absolute', width: 74, height: 74, aspectRatio: 1 / 1, borderRadius: 5, boxShadow: '2px 2px 4px 0px #FFFFFF36 inset, -5px -5px 2px 0px #00000021 inset' }} />
                                                            <img src={data?.merchant?.logo ? data?.merchant?.logo?.file_name : '/images/logos/logo.png'} className='w-full m-r-15' alt="merchant_logo" loading='lazy' 
                                                                style={{ width: 74, height: 74, aspectRatio: 1 / 1, background: data?.merchant?.logo ? 'none' : `linear-gradient(to bottom right, #ff0000, ${theme.palette.primary.main})`, borderRadius: 5, boxShadow: data?.merchant?.logo ? 'none' : '2px 2px 4px 0px #FFFFFF36 inset, -5px -5px 2px 0px #00000021 inset' }} 
                                                            />
                                                            <Typography variant='body1'><b>{data?.merchant?.name?.[i18n.language]} ({data?.merchant?.code})</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={3} className='flex-c-m'>
                                                            <Typography variant='body1' className='txt-center p-r-10'>${currencyFormat(parseFloat(data?.redeemable_value), 2)}</Typography>
                                                            <div className={styles.textRedDisplay} style={{ width: '35%' }}>
                                                                <Typography variant='body2' className='txt-center' style={{ color: '#f0f0f0' }}>+ ${currencyFormat(globalValue, 2)}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2} className='flex-c-m'>
                                                            <Typography variant='body1' className='txt-center p-r-10'>≈ {approxUnitMerchant}</Typography>
                                                            <div className={styles.textRedDisplay} style={{ width: '35%' }}>
                                                                <Typography variant='body2' className='txt-center' style={{ color: '#f0f0f0' }}>+ {approxUnitGlobal}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                    : <Grid item xs={9} className='flex-l' style={{ position: 'relative' }}>
                                                        {/* <div style={{ position: 'absolute', width: 74, height: 74, aspectRatio: 1 / 1, borderRadius: 5, boxShadow: '2px 2px 4px 0px #FFFFFF36 inset, -5px -5px 2px 0px #00000021 inset' }} /> */}
                                                        <div style={{ lineHeight:0, width:65, minWidth:60, aspectRatio:1/1, marginRight:10}}>
                                                            <img src={data?.merchant?.logo ? data?.merchant?.logo?.file_name : '/images/logos/logo.png'} alt="merchant_logo" loading='lazy'
                                                                style={{ objectFit:'cover', borderRadius: 5, width:'100%' }}
                                                            />
                                                        </div>
                                                        <div className='flex-col-l-m w-full'>
                                                            <Typography variant='caption' noWrap style={{ lineHeight: 1.1, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}><b>{data?.merchant?.name?.[i18n.language]} ({data?.merchant?.code})</b></Typography>

                                                            <div className='flex-l-m w-full p-t-5'>
                                                                <Typography variant='caption' className='p-r-5' style={{ lineHeight: 1.1 }}>${currencyFormat(parseFloat(data?.redeemable_value), 2)}</Typography>
                                                                <div className={styles.textRedDisplay} style={{ display: 'flex', padding: '1px 5px' }}>
                                                                    <Typography variant='caption' className='txt-center' noWrap style={{ color: '#f0f0f0', lineHeight: 1.1 }}>+ ${convertToK(globalValue)}</Typography>
                                                                </div>
                                                            </div>

                                                            <div className='flex-l-m w-full p-t-5'>
                                                                <Typography variant='caption' className='p-r-5' style={{ lineHeight: 1.1 }}>≈ {approxUnitMerchant}</Typography>
                                                                <div className={styles.textRedDisplay} style={{ display: 'flex', padding: '1px 5px' }}>
                                                                    <Typography variant='caption' className='txt-center' noWrap style={{ color: '#f0f0f0', lineHeight: 1.1 }}>+ {convertToK(approxUnitGlobal)}</Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                            }

                                            <Grid item xs={3} className='flex-r-m'>
                                                {
                                                    !mobileView
                                                        ? 
                                                        <div className='flex-row'>
                                                            <Tooltip title={t('title.transfer')} >
                                                                <Button variant="contained" onClick={() => onClickTransfer(data?.merchant_id)} className={styles.buttonSmallRedDesign} style={{ minWidth: 0, aspectRatio: 1 / 1, padding: 5, width: 45 }}>
                                                                    <img src={'/images/wallets/icon_transfer_white.png'} className='s-full' alt="transfer_logo" loading='lazy' style={{ color: '#f0f0f0', aspectRatio: 1 / 1 }} />
                                                                </Button>
                                                            </Tooltip>
                                                           
                                                            {
                                                                data?.merchant?.allow_third_party_convert === true ?
                                                                    <Tooltip title={t('title.convert')}>
                                                                        <Button variant="contained" onClick={() => onClickConvert(data?.merchant_id)} className={styles.buttonSmallRedDesign} style={{ minWidth: 0, aspectRatio: 1 / 1, padding: 5, width: 45, marginLeft:10 }}>
                                                                            <img src={'/images/wallets/third_party_convert.png'} className='s-full' alt="convert_logo" loading='lazy' style={{ color: '#f0f0f0', aspectRatio: 1 / 1 }} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        :
                                                        <Button variant="contained" onClick={() => handleMerchantClick(data)} className={styles.buttonSmallRedDesign} style={{ minWidth: 0, aspectRatio: 1 / 1, padding: 5, width:40, marginLeft:10, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            <FiMoreVertical style={{ color: '#f0f0f0', fontSize: 16}} />
                                                        </Button>
                                                }
                                            </Grid>
                                            {
                                                idx !== _.size(redeemList) - 1 &&
                                                <Grid item xs={12}>
                                                    <div className={styles.dividerDesign} />
                                                </Grid>
                                            }
                                        </Grid>
                                    )
                                }
                            })
                            : <Grid container className='flex-l-m p-tb-10'>
                                <Grid item xs={12}>
                                    <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                                </Grid>
                            </Grid>
                    }

                    {
                        !mobileView && _.size(redeemList) > 0 &&
                        <ThemeProvider theme={paginationStyle} >
                            <Stack spacing={2} style={{ padding: '10px 10%' }}>
                                {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                            </Stack>
                        </ThemeProvider>
                    }
                </div>

                {
                    mobileView && _.size(redeemList) > 0 &&
                    <ThemeProvider theme={paginationStyle} >
                        <Stack spacing={2} style={{ padding: '10px 0px' }}>
                            { loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} /> }
                        </Stack>
                    </ThemeProvider>
                }

                {
                    mobileView ?
                        <Drawer
                            anchor="bottom"
                            open={drawerOpen}
                            onClose={handleCloseDrawer}
                            disableScrollLock={true}
                            classes={{ paper: styles.paperAnchorBottom }}
                        >
                            {renderDrawerContent(selectedMerchant)}
                        </Drawer>
                        :
                        null
                }
            </div>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

const useStyles = makeStyles((theme) => ({
    redeemBonusToken: {
        position: 'relative',
        padding: '50px 30px 20px',
        background: theme.palette.secondary.main,
        borderRadius: 10,
        boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 40,
    },
    redeemCurrentPrice: {
        position: 'absolute',
        top: -30,
        padding: 7,
        background: theme.palette.primary.main,
        borderRadius: 15,
    },
    shadowDisplay: {
        height: 6, 
        borderRadius: '50%', 
        margin: '30px 0px', 
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))', 
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    squareButton: {
        color: theme.palette.white.main,
        borderRadius: '10px',
        minWidth: '40px',
        height: '40px',
        marginLeft: 10,
        aspectRatio: 1 / 1,
        padding: "0px 0px",
    },
    drawerContent: {
        padding: '20px 40px 0 40px',
    },
    drawerTitle: {
        textAlign: 'center',
        marginBottom: '10px',
        color: 'rgba(82, 82, 82, 0.80)'
    },
    icon: {
        cursor: 'pointer',
        padding: 5,
        [theme.breakpoints.down('lg')]: {
            width: 40,
            height: 'auto',
            marginRight: 8,
        },
    },
    listItem: {
        cursor: 'pointer',
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
    buttonRedDesign: {
        background: theme.palette.primary.main, 
        borderRadius: '25px 0px 25px 0px', 
        boxShadow: '2px 2px 4px 0px #FFFFFF85 inset, -2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026',
        padding: '10px 0px',
    },
    buttonSmallRedDesign: {
        background: theme.palette.primary.main, 
        borderRadius: '10px 0px 10px 0px', 
        boxShadow: '2px 2px 4px 0px #FFFFFF85 inset, -2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026',
    },
    textRedDisplay: {
        background: theme.palette.primary.main, 
        borderRadius: '9px 0px 9px 0px',
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 20,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
    rectangle: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        height: '4px',
        margin: '5px 0',
        flexShrink: 0
    },
}));

export default RedeemList;