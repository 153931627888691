import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Close, ArrowForwardIos } from '@mui/icons-material';

import { Typography, Grid,  ThemeProvider, Stack, Tooltip,
    Pagination, 
    Hidden, createTheme, useMediaQuery , Dialog, DialogContent , Button
} from '@mui/material';
import { makeStyles, useTheme, styled } from '@mui/styles';
// import { Balance, Lock, LockOpen, TimeToLeave, Add } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import CircleIcon from '@mui/icons-material/Circle';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';
import BonusTokenHistoryItem from '@components/TransactionItems/BonusTokenHistoryItem';

const BonusTokenHistory = () => {
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    // let navigate = useNavigate();
    // let location = useLocation();

    const [profile, setProfile] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState('');

    const filters = [
        {title: t('transaction.filterOptions.all'), value: ''},
        {title: t('transaction.filterOptions.credit'), value: -1},
        {title: t('transaction.filterOptions.debit'), value: 1},
    ];

    const handleClickOpen = (value) => {
        setOpenDialog(true);
        setSelectedTransaction(value);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };
    const onChangePage = (page) => {
        setPage(page);
    }
    const handleChangeStatusFilter = value => {
        setSelectedStatus(value);
        setPage(1);
        setTransaction([]);
    };

    // -------- API --------

    useEffect(() => {
        callApi();

        // eslint-disable-next-line
    }, [ page, selectedStatus, searchDate ]);

    const callApi = () => {
        setLoading(true);
        
        let apiData = {
            per_page: 10,
            page: page,
            filtering: selectedStatus,
            start_date : startDate !== null ? (startDate).format('YYYY-MM-DD') : null,
            end_date: endDate !== null ? (endDate).format('YYYY-MM-DD') : null
        };

        getUrl(`/get-member-bonus-token-history`, apiData).then((response) => {
            if(response.status)
            {
                setProfile(response.profile);
                setTransaction(response.transaction.data);
                setTotal(response.transaction.last_page);
            }
            else
            {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    // -------- layout --------

    const paginationBar = () => {
        return (
            <ThemeProvider theme={paginationStyle} >
                <Stack spacing={2} style={{ padding: '10px 10%' }}>
                    {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                </Stack>
            </ThemeProvider>
        )
    }

    const dialogDisplay = () => {
        let total = currencyFormat(selectedTransaction?.active_unit + selectedTransaction?.passive_unit, 0);
        return (
            <Dialog open={openDialog} onClose={handleClickClose} className='w-full' >
                <DialogContent style={{ padding: '10px 30px 40px' }}>
                    <div className='flex-r-m'>
                        <Button onClick={handleClickClose} style={{ minWidth: 28, aspectRatio: 1/1, padding: 5, borderRadius: 100, boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A' }}>
                            <Close style={{ color: theme.palette.gray.main }} />
                        </Button>
                    </div>

                    <div className='flex-col-c-m p-b-10'>
                        <Typography className='txt-upper txt-center' style={{ color: theme.palette.gray.main, fontSize: 18, fontWeight: 700 }}>
                            { t('trade.transactionDetails') }
                        </Typography>
                    </div>

                    <div className='flex-col-c-m p-t-20'>
                        <Typography className=' txt-center' style={{ color: '#ADADAD', fontSize: 18, fontWeight: 700 }}>
                            { selectedTransaction.transaction_code_display?.[i18n.language] ?? selectedTransaction.transaction_code_display?.['en'] }
                        </Typography>
                    </div>
                    
                    <div className='flex-col-c-m '>
                        <Typography variant='h5' className='txt-upper' style={{ color: theme.palette.darkGray.main , fontSize: '1.5rem'}}>
                            <b>{selectedTransaction?.factor !== 1 ? '-' : '+'} {total}</b>
                        </Typography>
                        <Typography variant='h5' className='txt-upper' style={{ color: theme.palette.darkGray.main , fontSize: '1.2rem'}}>
                            <b>{t('trade.units')}</b>
                        </Typography>
                    </div>

                    <Grid container className={styles.dialogDetailBoxDesign}>
                        <Grid item xs={12} sm={4} className='p-t-5'>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.dateTime')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className='p-t-5'>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} className={`txt-right txt-upper`}>
                                <i>{moment(selectedTransaction?.updated_at).format('DD MMM YYYY  ·  HH:mm:ss A')}</i>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className='p-t-5'>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.tradingId')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className='p-t-5'>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2, wordBreak: 'break-all', }} className={'txt-right'}>{selectedTransaction?.ulid ?? '-'}</Typography>
                        </Grid>
                    </Grid>

                    <div className={styles.dialogDetailBoxDesign}>
                        <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold' }}>{t('receipt.remark')}</Typography>
                        <Typography variant='body2' style={{ color: theme.palette.darkGray.main }}>{selectedTransaction?.details?.remark || '-'}</Typography>
                    </div>

                    <div className={styles.dialogDetailBoxDesign}>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}} className='p-t-5'>
                            <Typography variant='body2' style={{ color: theme.palette.lightGray.main , fontWeight: 'bold' }}>{t('trade.paymentBreakdown')}</Typography>
                            <Typography variant='body2' style={{ color: theme.palette.lightGray.main , fontWeight: 'bold' }}>{selectedTransaction?.factor !== 1 ? '-' : '+'} {total} { t('trade.units') } </Typography>
                        </div>

                        {
                            parseFloat( selectedTransaction?.passive_unit ) > 0 &&
                                <div style={{ display: 'flex', justifyContent: 'space-between'}} className='p-t-5'>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <CircleIcon style={{ color: theme.palette.white.main , fontSize: 15, marginLeft: 5, borderRadius: 15, boxShadow: '1px 1px 1px 0px #FFFFFF, 1px 1px 1px 0px #00000040 inset', }}/>
                                        <Typography style={{ color: theme.palette.darkGray.main  , marginLeft: 5 }}> { t('trade.passive') } </Typography>
                                    </div>
                                    <Typography variant='body2' style={{ color: '#ADADAD' }}> { selectedTransaction.passive_unit } { t('trade.units') } </Typography>

                                </div>
                        }

                        {
                            parseFloat( selectedTransaction?.active_unit ) > 0 &&
                                <div style={{ display: 'flex', justifyContent: 'space-between'}} className='p-t-5'>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <CircleIcon style={{ color: theme.palette.primary.main , fontSize: 15, marginLeft: 5, borderRadius: 15, boxShadow: '1px 1px 1px 0px #FFFFFF, 1px 1px 1px 0px #00000040 inset', }}/>
                                        <Typography style={{ color: theme.palette.darkGray.main , marginLeft: 5 }}> { t('trade.active') } </Typography>
                                    </div>
                                    <Typography variant='body2' style={{ color: '#ADADAD' }}> { selectedTransaction?.active_unit } { t('trade.units') } </Typography>
                                </div>
                        }

                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    // -------- desktop --------

    const desktopSummary = () => {
        let activeUnit = profile?.active_unit ?? 0;
        let passiveUnit = profile?.passive_unit ?? 0;
        return (
            <div
                style={{
                    margin: 'auto',
                    padding: '40px 0',
                    width: '70%',
                }}
            >
                <div
                    style={{ 
                        backgroundColor: theme.palette.secondary.main, 
                        borderRadius: 15, 
                        padding: 20,
                        color: theme.palette.white.main,
                        boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
                        
                    }}
                >
                    <div 
                        className='clwhite title-bg ' 
                        style={{ 
                            width: '80%', 
                            margin: '-45px auto 20px' , 
                            color: theme.palette.white.main, 
                            textAlign: 'center', 
                            fontWeight: 700, 
                        }}
                    >
                        { t('trade.bonusTokenSummary') }
                    </div>

                    <div style={{ padding: '10px 20px' , overflow: 'auto' }}>
                        <Grid container className={styles.summaryStyle}>

                            <Grid 
                                item xs={3} 
                                className='summaryContent'
                            >
                                <p style={{ fontWeight: 700 , fontSize: 20 }}>{ currencyFormat( activeUnit , 0 ) }</p>
                                <p 
                                    style={{
                                        color: theme.palette.white.main,
                                        fontSize: 10 , 
                                        fontWeight: 400 ,
                                        backgroundColor: theme.palette.primary.main,
                                        boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 4px 0px #0000007A inset',
                                        width: 'fit-content',
                                        padding: '5px 10px',
                                        borderRadius: 5,
                                        margin: 'auto',
                                    }}
                                >
                                    { t('trade.active') }
                                </p>
                            </Grid>

                            <Grid item xs={1} className='summaryContent' > <p className={styles.lightGrayText} style={{ fontSize: 30 }}>+</p> </Grid>

                            <Grid 
                                item xs={3}
                                className='summaryContent'
                            >
                                <p style={{ fontWeight: 700 , fontSize: 20 }}>{ currencyFormat( passiveUnit , 0 ) }</p>
                                <p 
                                    style={{
                                        color: theme.palette.gray.main ,
                                        fontSize: 10 , 
                                        fontWeight: 400 ,
                                        boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 3px 0px #00000040 inset',
                                        backgroundColor: theme.palette.white.main,
                                        width: 'fit-content',
                                        padding: '5px 10px',
                                        borderRadius: 5,
                                        margin: 'auto',

                                    }}
                                >
                                    { t('trade.passive') }
                                </p>
                            </Grid>

                            <Grid item xs={1} className='summaryContent'> <p className={styles.lightGrayText} style={{ fontSize: 30 }}>=</p> </Grid>

                            <Grid item xs={4} className='summaryContent'>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <p style={{ fontWeight: 700 , fontSize: 20 }}>{ currencyFormat( activeUnit + passiveUnit , 0 ) }</p>
                                    <p className={styles.lightGrayText} style={{ fontSize: 17 , }}>{ t('general.total') }</p>
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </div>
                <p style={{ fontSize: 15 , padding: '5px 0 0 0', color: theme.palette.darkGray.main }}>* { t('trade.notIncluded')}</p>
            </div>

        );
    }

    const desktopTransactionListing = () => {
        return (
            <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: '20px 20px' }}>
                {
                    _.size(transaction) > 0 ?
                        _.map(transaction, (data, idx) => {
                            // let factor = data?.factor !== 1 ? '-' : '+';
                            return (
                                <div key={idx}> 
                                    <BonusTokenHistoryItem data={data} handleClickOpen={handleClickOpen} dialogShow={true}/>
                                    
                                    {
                                        idx !== _.size(transaction) - 1 &&
                                        <Grid item xs={12}>
                                            <div className={styles.dividerDesign} />
                                        </Grid>
                                    }
                                </div>
                            )
                        })
                        : <Grid container className='flex-l-m p-tb-10'>
                            <Grid item xs={12}>
                                <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                            </Grid>
                        </Grid>
                }

                {
                    !mobileView && _.size(transaction) > 0 &&
                        paginationBar()
                }
            </div>
        )
    }

    const desktopFilterBar = () => {
        return (
            <Grid container style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center' , marginTop: 20, }}>
                <Grid item xs={4} md={5}>
                    <Typography variant='body1' style={{ color: '#adadad' }}><b>{t('trade.bonusTokenHistory')}</b></Typography>
                </Grid>
                <Grid item xs={8} md={7} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {/* Date Filter */}
                    <DateDisplay startDate={startDate} endDate={endDate} />
                    <div style={{ paddingRight: 10 }}>
                        <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                    </div>
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </Grid>
            </Grid>
        )
    }

    // -------- mobile --------

    const mobileFilterBar = () => {
        return (
            <Grid container spacing={2} style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center' , marginTop: 20, }}>
                <Grid item xs={12} sm={7}>
                    {/* <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad' }}><b>{ t('trade.transaction') }</b></Typography> */}
                    <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad' }}><b>{ t('trade.bonusTokenHistory') }</b></Typography>
                    <DateDisplay startDate={startDate} endDate={endDate} />
                    {/* <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad', paddingRight: 10 }}>
                        {t('trade.dateRange', {start: (startDate)?.format('DD-MM-YYYY') || 'DD-MM-YYYY', end: (endDate)?.format('DD-MM-YYYY') || 'DD-MM-YYYY'})}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={5} className='txt-right' style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ paddingRight: 10 }}>
                        <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                    </div>
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </Grid>
            </Grid>
        )
    }

    const mobileSummary = () => {
        let activeUnit = profile?.active_unit ?? 0;
        let passiveUnit = profile?.passive_unit ?? 0;

        return (
            <div
                style={{ 
                    backgroundColor: theme.palette.secondary.main, 
                    borderRadius: 15, 
                    padding: '20px 0',
                    color: theme.palette.white.main,
                    boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
                    margin: '50px 0 30px',
                }}
            >
                <div 
                    className='clwhite title-bg ' 
                    style={{ 
                        width: '80%', 
                        margin: '-45px auto 20px' , 
                        color: theme.palette.white.main, 
                        textAlign: 'center', 
                        fontWeight: 700, 
                    }}
                >
                    { t('trade.bonusTokenSummary') }
                </div>

                <div style={{ padding: '0px 5px'}} className='flex-sa-m'>
                    <div style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Typography style={{ color: theme.palette.darkGray.main , fontSize: 10, }}> { t('trade.active') } </Typography>
                            <CircleIcon style={{ color: theme.palette.primary.main , fontSize: 10, marginLeft: 5, }}/>
                        </div>
                        <p style={{ fontWeight: 700  }}>{ currencyFormat( activeUnit , 0 ) }</p>
                    </div>

                    <p className={styles.lightGrayText} style={{ fontSize: 30 }}>+</p>

                    <div style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Typography style={{ color: theme.palette.darkGray.main , fontSize: 10, }}> { t('trade.passive') } </Typography>
                            <CircleIcon style={{ color: theme.palette.white.main , fontSize: 10, marginLeft: 5, }}/>
                        </div>
                        <p style={{ fontWeight: 700  }}>{ currencyFormat( passiveUnit , 0 ) }</p>
                    </div>

                    <p className={styles.lightGrayText} style={{ fontSize: 30 }}>=</p>

                    <div
                        style={{ textAlign: 'left' }}
                    >
                        <p className={styles.lightGrayText} style={{ fontSize: 10 , }}>{ t('general.total') }</p>
                        <p style={{ fontWeight: 700 }}>{ currencyFormat( activeUnit + passiveUnit , 0 ) }</p>
                    </div>
                </div>
                <p style={{ padding: '5px 10px 0' , color: theme.palette.darkGray.main, fontSize: '.8rem'}}>* { t('trade.notIncluded')}</p>

            </div>
        );
    }

    const mobileTransactionListing = () => {
        return (
            <>
                <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: '20px 15px' }}>
                    {
                        _.size(transaction) > 0 ?
                            _.map(transaction, (data, idx) => {
                                let factor = data?.factor !== 1 ? '-' : '+';
                                return (
                                    <div key={idx}>
                                        <BonusTokenHistoryItem data={data} handleClickOpen={handleClickOpen} dialogShow={true}/>
                                        
                                        {
                                            idx !== _.size(transaction) - 1 &&
                                            <Grid item xs={12}>
                                                <div className={styles.dividerDesign} />
                                            </Grid>
                                        }
                                    </div>
                                )
                            })
                            : <Grid container className='flex-l-m p-tb-10'>
                                <Grid item xs={12}>
                                    <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                                </Grid>
                            </Grid>
                    }
                </div>
                { paginationBar() }
            </>
           
        )
    }

    return (
        <div className='dashboard-container'>
            <Hidden mdDown>
                { desktopSummary() }
                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' , margin: 'auto'}} />
                { desktopFilterBar() }
                { desktopTransactionListing() }
            </Hidden>

            <Hidden mdUp>
                { mobileSummary() }
                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' , margin: 'auto'}} />
                { mobileFilterBar() }
                { mobileTransactionListing() }
            </Hidden>
            { dialogDisplay() }

        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    summaryStyle: {
        '& .summaryContent': {
            textAlign: 'center',
            margin: 'auto',
        }
    },
    lightGrayText: {
        color: theme.palette.lightGray.main, 
        fontWeight: 400
    },
    shadowDisplay: {
        height: 6, 
        borderRadius: '50%', 
        margin: '30px 0px', 
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))', 
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
    dialogDetailBoxDesign: {
        boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: 20,
        padding: '20px',
        marginTop: 20,
    }
}));

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

export default BonusTokenHistory;