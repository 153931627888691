import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

export default function MerchantInfo(props) {
    const { state, inputErrors, handleChange } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.gray.main }}>{t('merchant.name')}</Typography>
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='merchantNameEn'
                        placeholder={t('merchant.merchantNameEn') + '*'}
                        value={state?.name?.en || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name_en ? inputErrors.name_en : ''}
                        error={inputErrors && inputErrors.name_en ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='merchantNameCn'
                        placeholder={t('merchant.merchantNameCn') + '*'}
                        value={state?.name?.cn || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name_cn ? inputErrors.name_cn : ''}
                        error={inputErrors && inputErrors.name_cn ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='email'
                        placeholder={t('merchant.email') + '*'}
                        value={state?.email || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='phone'
                        placeholder={t('merchant.phone') + '*'}
                        value={state?.phone || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.phone ? inputErrors.phone : ''}
                        error={inputErrors && inputErrors.phone ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='code'
                        placeholder={t('merchant.merchantCode') + '*'}
                        value={state?.code || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.code ? inputErrors.code : t('merchant.noted')}
                        error={inputErrors && inputErrors.code ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='companyRegisterNumber'
                        placeholder={t('merchant.companyRegisterNumber') + '*'}
                        value={state?.companyRegisterNumber || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.company_register_number ? inputErrors.company_register_number : ''}
                        error={inputErrors && inputErrors.company_register_number ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    )
}