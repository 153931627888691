import _, { isNull, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogContent, Grid, IconButton, Link, Typography, useMediaQuery, Tabs, Tab, Hidden } from '@mui/material';
import { useTheme, styled, makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from "swiper/react";

import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import moment from 'moment-timezone';

import "swiper/css";
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import { Close } from '@mui/icons-material';
import NoticeItem from '@components/NoticeItem';
import VideoSwiper from '@components/VideoSwiper';

import UserBasicInfoCard from '@layouts/Dashboard/UserBasicInfoCard';
// import ServerTimeCard from '@layouts/Dashboard/ServerTimeCard';
import BonusProfitBalance from '@layouts/Dashboard/BonusProfitBalance';
import MarketPlaceCard from '@layouts/Dashboard/MarketPlaceCard';
import { FaArrowTrendUp, FaArrowsRotate } from "react-icons/fa6";
import PropTypes from 'prop-types';

const videos = [
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid item xs={12} lg={6} className='w-full'
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </Grid>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate();
    const theme = useTheme();
    const styles = useStyles();
    const { username, max_rank } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({});
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        key: 0,
        data: null
    });
    const [coverImg, setCoverImg] = useState({});
    const [marketPrice, setMarketPrice] = useState([]);
    // const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [tabPage, setTabPage] = useState(0);

    const GENERAL_TYPE = ['news', 'announcement'];
    const [newsActiveTab, setNewsActiveTab] = useState('news');

    const fontSize = mobileView ? '0.75rem' : '0.80rem';

    useEffect(() => {
        getUrl(`/get-notice-images`).then(response => {
            if (response.status) {
                setCoverImg(response?.data?.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoading(true);
        let params = {
            lang: i18n.language,
        }
        getUrl(`/member-homepage`, params).then(response => {
            setLoading(false);
            setState(response.data);
            if (_.size(response?.data?.announcements) > 0) {
                setDialogInfo({
                    open: true,
                    key: 1,
                    data: response?.data?.announcements[0]
                });
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        setLoading(true);
        getUrl(`/get-all-market`).then(response => {
            setLoading(false);
            if (response.status) {
                setMarketPrice(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const getImageLink = (info) => {
        let imageDetail = null;
        if (_.size(info?.content_images) > 0) {
            let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
            if (!isUndefined(tempData)) {
                imageDetail = tempData?.file_name;
            } else {
                let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                if (defaultData) {
                    imageDetail = defaultData?.file_name;
                }
            }
        }

        return imageDetail;
    }

    const closeDialog = () => {
        if (_.size(state?.announcements) > dialogInfo.key) {
            setDialogInfo({ ...dialogInfo, key: dialogInfo.key + 1, data: state?.announcements[dialogInfo.key] });
        } else {
            setDialogInfo({
                open: false,
                key: 0,
                data: null
            });
        }
    }

    const handleTabChange = (event, newValue) => {
        setNewsActiveTab(newValue);
    };

    const handleTabPageChange = (event, newValue) => {
        setTabPage(newValue);
    };

    const banner = () => {
        let imageSrc = '';
        switch (i18n.language) {
            case 'cn':
                imageSrc = 'images/dashboard/slogan-cn.png';
                break;
            case 'en':
                imageSrc = 'images/dashboard/slogan-en.png';
                break;
            default:
                imageSrc = 'images/dashboard/slogan-en.png';
                break;
        }
        return (
            <div className='m-b-30'>
                {
                    _.size(state?.banners) > 0 ?
                        <Swiper
                            className="mySwiper"
                            spaceBetween={20}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 4500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination]}
                            style={{ height: 182, "--swiper-pagination-color": "#fff", "--swiper-pagination-bullet-inactive-color": theme.palette.primary.main, "--swiper-pagination-bullet-inactive-opacity": "1" }}
                        >
                            {
                                _.map(state?.banners, (banner, index) => {
                                    return (
                                        <SwiperSlide key={index} style={{ position: 'relative' }}>
                                            <img src={banner.file_name} alt='banner' className='w-full bor-15' loading='lazy' />
                                            <div className='s-full bor-15' style={{ position: 'absolute', top: 0, boxShadow: '-2px -2px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF inset' }} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        :
                        <div>
                            <img src={imageSrc} alt='banner' className='w-full bor-15' style={{ objectFit: 'contain'}} loading='lazy' />
                        </div>
                }
            </div>
        )
    }

    const video = () => {
        return (
            <div style={{ marginBottom: 10, paddingTop: mobileView ? 0 : 25 }}>
                {
                    _.size(videos) > 0 ?
                    (
                        videos.length > 1 
                        ? 
                            (
                                <VideoSwiper videos={videos} />
                            ) 
                        : 
                            (
                                videos.map((video) => (
                                    <iframe
                                        key={video.id}
                                        title={`dashboard_video_${video.id}`}
                                        src={video.url}
                                        width="100%"
                                        height="300"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        style={{ borderRadius: 10, boxShadow: '0 6px 6px 0 #0002', marginBottom: 20 }}
                                    />
                                ))
                            )
                    )
                    :
                    null
                    // <div>
                    //     <p style={{ color: '#F0F0F0' }}>not video (video here)</p>
                    // </div>
                    
                }
                
            </div>
        )
    }

    const leftComponent = () => {
        return (
            <>
                <UserBasicInfoCard />
                {/* <ServerTimeCard /> */}
                <BonusProfitBalance />
                <MarketPlaceCard marketPrice={marketPrice?.market} />
            </>
        )
    }

    const newsComponent = () => {
        return (
            <>
                {
                    <div className='p-t-30'>
                        <div className='flex-sb-m p-b-30' style={{ color: theme.palette.secondary.main }}>
                            <Tabs
                                value={newsActiveTab}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                centered
                                sx={{ 
                                    width: "80%" , 
                                    backgroundColor: theme.palette.secondary.main, 
                                    boxShadow: 'none'
                                }}
                            >
                                {
                                    _.size(GENERAL_TYPE) > 0 && 
                                    _.map(GENERAL_TYPE, (type, typeIndex) => {
                                        return (
                                            <Tab
                                                key={typeIndex}
                                                value={type}
                                                label={
                                                    <Typography variant="body1" style={{fontSize}} >
                                                        {t(`notice.${type}`)}
                                                    </Typography>
                                                }
                                            />
                                        )
                                    })
                                }
                            </Tabs>
                            <Button variant='contained' className={styles.sortByButton} onClick={() => navigate(`/account/notices?type=${newsActiveTab === 'news' ? `news` : `announcement`}`, { state: { from: storePaths(location) } })}>
                                <Typography variant='body2' className='txt-upper'>{t('button.all')}</Typography>
                            </Button>
                        </div>
                        {_.map((newsActiveTab === 'news' ? (state?.news) : (state?.announcements_all)), data => {
                            let imageLink = null;
                            if (data?.content_type === "image" && _.size(data?.content_images) > 0) {
                                imageLink = getImageLink(data);
                            }

                            if (isNull(imageLink)) {
                                imageLink = '/images/dashboard/example.png';
                            }

                            if (isNull(data.cover_image)) {
                                imageLink = '/images/dashboard/example.png';
                            } else {
                                let filename = _.filter(coverImg, { id: data.cover_image });
                                imageLink = filename[0]?.file_name;
                            }

                            if (imageLink) {
                                return (
                                    <Link 
                                        component={RouterLink} 
                                        to={mobileView ? `/account/notice/${data?.id}` : `/account/notices?type=${newsActiveTab === 'news' ? `news` : `announcement`}`} 
                                        state={{ from: storePaths(location), id: data?.id }} 
                                        underline='none' 
                                        key={data.id}
                                        style={{
                                            backgroundColor: 'red'
                                        }}
                                    >
                                        <div 
                                            className='pos-relative flex-r p-b-30'
                                        >
                                            <div 
                                                className='news-box'
                                                style={{
                                                    backgroundColor: theme.palette.secondary.main,
                                                    color: '#F0F0F0'
                                                }}
                                            >
                                                <div className='p-l-70' style={{ fontWeight: 500 }}>
                                                    <p className='txt-truncate2 fs-15'>{data?.title[i18n.language] ? data.title[i18n.language] : data?.title?.cn}</p>
                                                    <p className='fs-12' style={{ color: '#9D9D9D' }}>{data?.created_at ? moment(data?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                                </div>
                                            </div>
                                            <div className='news-img-pos' >
                                                <img src={imageLink} alt='new-img' className='w-full max-w-full' style={{ objectFit: 'cover', borderRadius: '25px 7px' }} />
                                            </div>
                                        </div>
                                    </Link>
                                )
                            } else {
                                return null;
                            }
                        })}
                        {_.size(newsActiveTab === 'news' ? (state?.news) : (state?.announcements_all)) === 0
                            ? (
                                <div style={{textAlign: "center"}}>
                                    <img src="images/general/no-news-or-announcement.png" width="250" alt="No data" />
                                    <Typography style={{ color: "#787878" }}>{t('general.noData')}</Typography>
                                </div>
                            )
                        : null}
                    </div>
                }
                {
                    (dialogInfo.data && _.size(state?.announcements) > 0)
                    &&
                    <Dialog
                        maxWidth="xs"
                        fullWidth
                        open={dialogInfo.open}
                        onClose={() => closeDialog()}
                        sx={{
                            background: 'transparent',
                            '& .MuiPaper-root': {
                                width: 400,
                                background: dialogInfo.data?.content_type === "image" ? 'transparent' : 'white',
                                boxShadow: 'none',
                            },
                            '& .MuiBackdrop-root': {
                                backgroundColor: 'rgba(0, 0, 0, 0.78)',
                            }
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 10 }} className='w-full' >
                            <IconButton
                                onClick={() => closeDialog()}
                                sx={{
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}
                            >
                                <Close style={{ color: dialogInfo.data?.content_type === "image" ? 'white' : theme.palette.primary.main }} fontSize="medium" />
                            </IconButton>
                        </div>
                        {
                            dialogInfo.data && dialogInfo.data?.content_type === "text" &&
                            // <DialogTitle style={{ padding: '0 20px' }}>
                            <Typography variant="h6" color="primary" style={{ padding: '0 20px', wordBreak: 'break-word' }}>{dialogInfo.data?.title[i18n.language] ? dialogInfo.data?.title[i18n.language] : dialogInfo.data?.title?.cn}</Typography>
                            // </DialogTitle>
                        }
                        <DialogContent>
                            <NoticeItem info={dialogInfo.data} popup={true} />
                        </DialogContent>
                    </Dialog>
                }
            </>
        )
    }

    return (
        <>
            <Hidden mdUp>
                <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#2E3035' , paddingTop:90 }}>
                    {/* <Tabs
                        value={tabPage}
                        onChange={handleTabPageChange}
                        variant="fullWidth"
                        style={{
                            borderRadius: 3,
                            boxShadow: '0px 2px 2px 0px #0000001A',
                            background: '#2E3035',
                            paddingTop: 90,
                            width: '100%',
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Tab label={t('general.banner')} {...a11yProps(0)}
                            style={{
                                fontSize: 14,
                                background: 'transparent',
                                boxShadow: 'none',
                                color: theme.palette.lightGray.main,
                            }}
                            sx={{
                                '&.Mui-selected': {
                                    color: `${theme.palette.white.main} !important`,
                                },
                            }}
                        />
                        <Tab label={t('general.video')} {...a11yProps(1)}
                            style={{
                                fontSize: 14,
                                background: 'transparent',
                                boxShadow: 'none',
                                color: theme.palette.lightGray.main,
                            }}
                            sx={{
                                '&.Mui-selected': {
                                    color: `${theme.palette.white.main} !important`,
                                },
                            }}
                        />
                    </Tabs> */}
                </div>
            </Hidden>

            <CustomDiv
                className='dashboard-container'
            >
                <Hidden lgUp>
                    <Grid container spacing={3}>
                        <CustomTabPanel value={tabPage} index={0}>
                            {banner()}
                            {leftComponent()}
                            {newsComponent()}
                        </CustomTabPanel>

                        <CustomTabPanel value={tabPage} index={1}>
                            {video()}
                            {leftComponent()}
                            {newsComponent()}
                            {/* <>{tradeHistoryListingDisplay()}</> */}
                        </CustomTabPanel>
                    </Grid>
                </Hidden>

                <Hidden lgDown>
                    <Grid container spacing={5} style={{ paddingBottom: 50 }}>
                        <Grid item xs={12} md={6}>
                            {banner()}
                            {video()}
                            {newsComponent()}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {leftComponent()}
                        </Grid>
                    </Grid>
                </Hidden>
            </CustomDiv>
        </>
    );
}

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 80,
    },
});

const useStyles = makeStyles((theme) => ({
    sortByButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: 1 / 1,
        minWidth: 65,
        maxHeight: 40,
        borderRadius: 10,
        background: theme.palette.primary.main,
    },
}));