import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Grid, Dialog, DialogContent, Button, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Close, Diamond, LanguageOutlined, MailOutlineOutlined, LocalPhoneOutlined } from '@mui/icons-material';

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';

export default function MerchantListing() {
    const theme = useTheme();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { t, i18n } = useTranslation();
    const { addAlert , setLoading , loading } = useNotificationLoading();

    const [listing, setListing] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState({});
    const [merchantType, setMerchantType] = useState({});

    // --------------- Api -------------------
    useEffect(() => {
        try {
            callApi();
        } catch (error) {
            addAlert('', t('error.contactSupport'), 'error', '');
        }
        // eslint-disable-next-line
    }, []);

    // --------------- Functions -------------------
    const callApi = () => {
        setLoading(true);
        getUrl(`/member-get-merchant-list`).then(response => {
            if (response.status) {
                if (_.size(response.listing) > 0) {
                    setListing(response.listing);
                    setMerchantType(response.merchant_type);
                }
            } else {
                addAlert("", response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleClickOpen = (item) => {
        setSelectedMerchant(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // --------------- End of Lines -------------------

    return (
        <div className='dashboard-container'>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Typography variant='h6' className='txt-left' style={{ color: "#ADADAD", margin: '0 0 15px'}}>{t('merchant.merchantListing')} ({_.size(listing) || 0})</Typography>

                <Grid container spacing={2}>
                    {
                        _.size(listing) > 0
                            ? _.map(listing, (detail, idx) => {
                                return (
                                    <Grid item xs={12} sm={6} lg={4} key={idx}>
                                        <Grid container className={styles.merchantListingDesign} onClick={() => {handleClickOpen(detail)}}>
                                            <Grid item xs={3}>
                                                <div className={styles.merchantLogoDesign} style={{ width: '100%', backgroundImage: detail?.logo  ? `url('${detail?.logo?.file_name}')` : `url(/images/logos/logo.png)` }} />
                                            </Grid>
                                            <Grid item xs={7} className='p-l-10'>
                                                <Typography variant='body1' className='txt-left p-b-10' style={{ lineHeight: 1.0, color: theme.palette.darkBase.main }}><b>{detail?.name?.[i18n.language]} ({detail?.code})</b></Typography>
                                                <Typography variant='body2' className={`txt-left ${styles.aboutShopDesign}`}>{detail?.about_shop}</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ position: 'absolute', top: -1, right: 20 }}>
                                                {
                                                    detail?.merchant_type === 2 &&
                                                    <>
                                                        <svg width="37" height="48" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M36.8908 47.6855L18.6212 38.9462L0.351562 47.6855V0.42041H36.8908V47.6855Z" fill="url(#paint0_linear_3899_27475)" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear_3899_27475" x1="18.6212" y1="0.42041" x2="18.6212" y2="47.6855" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#9E1E1E" />
                                                                    <stop offset="0.11" stopColor="#D42C2C" />
                                                                    <stop offset="0.305" stopColor="#B51111" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                        <Diamond style={{ fontSize: 25, color: '#f0f0f0', position: 'absolute', top: 10, right: 6 }} />
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }) : null
                    }
                </Grid>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogContent className='w-full' style={{ padding: '15px 30px 30px' }}>
                        <div className='flex-r-m'>
                            <Button onClick={handleClose} style={{ minWidth: 28, aspectRatio: 1 / 1, padding: 5, borderRadius: 100, boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A' }}>
                                <Close style={{ color: theme.palette.darkGray.main }} />
                            </Button>
                        </div>

                        <Typography variant='body1' className='txt-center p-b-20' style={{ color: theme.palette.darkBase.main }}><b>{t('merchant.details')}</b></Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <div className='flex-c-m'>
                                    <div className={styles.merchantLogoDesign} style={{ width: mobileView ? '30%' : '100%', backgroundImage: selectedMerchant?.logo ? `url('${selectedMerchant?.logo?.file_name}')` : `url(/images/logos/logo.png)` }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={9} className='p-l-10'>
                                <div className={mobileView ? 'p-t-5' : 'p-t-0'}>
                                    <div className={mobileView ? 'flex-c-m' : 'flex-l-m'}>
                                        <Typography variant='body2' className={mobileView ? 'txt-center' : 'txt-left'} style={{ lineHeight: 1.2, color: theme.palette.darkBase.main }} >{merchantType?.[selectedMerchant?.merchant_type]?.name?.[i18n.language]}</Typography>
                                        {
                                            selectedMerchant?.merchant_type === 2 &&
                                            <Diamond className='p-l-5' style={{ fontSize: 25, color: theme.palette.primary.main }} />
                                        }
                                    </div>
                                    <Typography variant='body2' className={mobileView ? 'txt-center' : 'txt-left'} style={{ lineHeight: 1.2, color: theme.palette.darkBase.main }} ><i>{selectedMerchant?.code}</i></Typography>
                                    <Typography variant='body1' className={mobileView ? 'txt-center' : 'txt-left'} style={{ lineHeight: 1.2, color: theme.palette.darkBase.main }} ><b>{selectedMerchant?.name?.[i18n.language]}</b></Typography>
                                </div>
                                <br />

                                <div className='flex-col'>
                                    <div className='flex-m'>
                                        <LanguageOutlined style={{ fontSize: 16, color: theme.palette.lightGray.main }} />
                                        <a href={selectedMerchant?.website} className={styles.linkDesign}>
                                            <Typography variant='body2' className='p-l-5' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.website}</Typography>
                                        </a>
                                    </div>
                                    <div className='flex-m'>
                                        <MailOutlineOutlined style={{ fontSize: 16, color: theme.palette.lightGray.main }} />
                                        <a href={`mailto:${selectedMerchant?.email}`} className={styles.linkDesign}>
                                            <Typography variant='body2' className='p-l-5' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.email}</Typography>
                                        </a>
                                    </div>
                                    <div className='flex-m'>
                                        <LocalPhoneOutlined style={{ fontSize: 16, color: theme.palette.lightGray.main }} />
                                        <a href={`tel:${selectedMerchant?.mobile}`} className={styles.linkDesign}>
                                            <Typography variant='body2' className='p-l-5' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.mobile}</Typography>
                                        </a>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='p-t-20'>
                            <Typography variant='body2' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.about_shop}</Typography>
                            <br />

                            <Typography variant='body1' className='txt-left' style={{ color: theme.palette.lightGray.main }}>{t('merchant.address1')}</Typography>
                            <Typography variant='body2' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.addresses?.[0]?.full_address || '-'}</Typography>
                            <br />

                            <Typography variant='body1' className='txt-left' style={{ color: theme.palette.lightGray.main }}>{t('merchant.productSell')} ({_.size(_.split(selectedMerchant?.product_sell, ','))})</Typography>
                            <Typography variant='body2' style={{ color: theme.palette.darkBase.main }}>{selectedMerchant?.product_sell}</Typography>
                        </div>
                        {
                            selectedMerchant.allow_third_party_convert && selectedMerchant.allow_third_party_convert === true ?
                                <div className='p-t-20 flex-row'>
                                    <Button variant='contained' style={{width:'50%'}} onClick={() => navigate(`/spend?m=${btoa(selectedMerchant?.id)}`, { state: { from: storePaths(location) } })}>
                                        {t('title.spend')}
                                    </Button>
                                    <Button variant='contained' style={{width:'50%'}} onClick={() => navigate(`/to-thirdparty/form?m=${btoa(selectedMerchant?.id)}`, { state: { from: storePaths(location) } })}>
                                        {t('title.convert')}
                                    </Button>
                                </div>
                                :
                                <div className='p-t-20'>
                                    <Button variant='contained' fullWidth onClick={() => navigate(`/spend?m=${btoa(selectedMerchant?.id)}`, { state: { from: storePaths(location) } })}>
                                        {t('title.spend')}
                                    </Button>
                                </div>
                        }
                        
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    merchantListingDesign: {
        boxShadow: '2px 2px 4px 0px #FFFFFF inset, -3px -3px 4px 0px #00000033 inset, 4px 4px 8px 0px #0000004D',
        background: '#f0f0f0',
        borderRadius: 15,
        padding: '15px 20px',
        cursor: 'pointer',
        height: '100%',
        position: 'relative',
        '&:hover': {
            boxShadow: '2px 2px 4px 0px #0000004D inset, -3px -3px 4px 0px #00000033',
        }
    },
    merchantLogoDesign: {
        boxShadow: '2px 2px 4px 0px #00000040 inset',
        background: theme.palette.primary.main,
        aspectRatio: 1/1,
        objectPosition: 'center',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 5,
    },
    linkDesign: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
        }
    },
    aboutShopDesign: {
        fontSize: 13, 
        color: theme.palette.darkBase.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    }
}));