import React, { memo, useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Navigate, Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl, getUrl, deleteUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, FormControl, InputLabel, Select, FormHelperText, MenuItem, Typography, Stepper, Step, StepLabel, } from '@mui/material';
import Switch from '@mui/material/Switch';

import { storePaths } from '@utils/Tools';
import Frame from '@layouts/Frame';

const INITIAL_STATE = {
    user_name: '',
    user_mobile: '',
    address_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    full_address: '',
    status: '1',
};

const AddressDetails = memo(() => {
    let { id } = useParams();
    const [isId, setIsId] = useState(!!id);
    let navigate = useNavigate();
    let location = useLocation();
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const [addressOption, setAddressOption] = useState();
    const [filteredStateList, setFilteredStateList] = useState([]);
    const [filteredCityList, setFilteredCityList] = useState([]);

    // address options
    useEffect(() => {
        setLoading(true);

        getUrl(`/address-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setAddressOption(response.addressList)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    const callApi = () => {
        if (isId) {
            setLoading(true);
    
            // Assuming getUrl is a function that performs the API request
            getUrl(`/user/${id}/addressInfo`)
                .then(response => {
                    setLoading(false);
                    if (response.status) {
                        // Assuming INITIAL_STATE is defined elsewhere
                        setState(response.data || INITIAL_STATE);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    // Assuming addAlert is a function for displaying alerts
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
        }
    };
    
    useEffect(() => {
        callApi();
    }, [isId, id, setLoading, addAlert, t]);
    
    // address info
    useEffect(() => {
        if(isId){
            setState({
                ...state,
                address1: state.address1,
                address2: state.address2,
                address_name: state.address_name,
                city: state.city,
                state: state.state,
                zip: state.zip,
                user_name: state.user_name,
                user_mobile: state.user_mobile,
                country: state.country,
            });
        }
    
        let tempStateList = _.filter(addressOption?.stateList, function (o) {
            if (o.country_code === state?.country) {
                return true;
            }
        });

        if (_.size(tempStateList) > 0) {
            setFilteredStateList(tempStateList);
        }

        let tempCityList = _.filter(addressOption?.cityList, function (o) {
            if (o.state_id === state?.state) {
                return true;
            }
        });

        if (_.size(tempCityList) > 0) {
            setFilteredCityList(tempCityList);
        }
        
    }, [isId, state, addressOption?.stateList, addressOption?.cityList, setFilteredStateList, setFilteredCityList]);

    // filter the country and state
    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'country':
                if (value !== 0) {
                    let tempStateList = _.filter(addressOption?.stateList, function (o) {
                        return o.country_code === value;
                    });
                    setFilteredStateList(tempStateList);
                    setState({
                        ...state,
                        country: value,
                        city: '',
                        state: '',
                    });
                } else {
                    setState({
                        ...state,
                        country: '',
                        city: '',
                        state: '',
                    });
                }
                break;
            
            case 'state':
                if (value !== 0) {
                    let tempCityList = _.filter(addressOption?.cityList, function (o) {
                        return parseInt(o.state_id) === parseInt(value);
                    });
                    setFilteredCityList(tempCityList);
                    setState({
                        ...state,
                        city: '',
                        state: value.toString(),
                    });
                } else {
                    setState({
                        ...state,
                        city: '',
                        state: '',
                    });
                }
                break;
    
            case 'zip':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, [name]: value });
                break;

            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, [name]: value });
                break;
    
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    // submit data
    const handleSubmit = () => {
        setLoading(true);
        setInputErrors();
    
        let params = {
            address1: state.address1,
            address2: state.address2,
            address_name: state.address_name,
            city: state.city,
            state: state.state,
            zip: state.zip,
            user_name: state.user_name,
            user_mobile: state.user_mobile,
            country: state.country,
        };

        if(isId){
            putUrl(`/user/address/${id}`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    navigate(`/account/address`, { state: { from: storePaths(location) } });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response?.message || t('error.editError'), 'error', '');

                    if (response.errors.user_name || response.errors.user_mobile) {
                        setActiveStep(0);
                    } else if (response.errors.address1 || response.errors.address2 || response.errors.address_name || response.errors.zip) {
                        setActiveStep(1);
                    } else if (response.errors.city || response.errors.state || response.errors.country) {
                        setActiveStep(2);
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }else{
            putUrl(`/user/address`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/account/address`, { state: { from: storePaths(location) } });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response?.message || t('error.createError'), 'error', '');

                    if (response.errors.user_name || response.errors.user_mobile) {
                        setActiveStep(0);
                    } else if (response.errors.address1 || response.errors.address2 || response.errors.address_name || response.errors.zip) {
                        setActiveStep(1);
                    } else if (response.errors.city || response.errors.state || response.errors.country) {
                        setActiveStep(2);
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    };

    const handleAddressDefault = (addressId) => {
        setLoading(true);
        setInputErrors();

        // Check if the address is already the default address
        if (state.default === 1) {
            setLoading(false);
            addAlert('', 'Address is already set as default', 'info', '');
            return;
        }

        putUrl(`/user/set-default/${addressId}`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response?.message, 'success', '');
                callApi();
            } else {
                addAlert('', response?.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };
    
    const pageTitle = isId ? t('title.editAddress') : t('title.addAddress');

    // tabs
    const steps = [
        t('stepper.personalInfo'),
        t('stepper.address'),
        t('stepper.country'),
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    let currSectionFilled = false;

    const handleNext = () => {
        handleSectionFilled(state);

        if (!currSectionFilled) {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSectionFilled = (curr) => {
        if (activeStep === 0 && curr.user_name !== '' && curr.user_mobile !== '') {
            currSectionFilled = true;
        } else if (activeStep === 1 && curr.address1 !== '' && curr.address2 !== '' && curr.address_name !== '' && curr.zip !== '') {
            currSectionFilled = true;
        } else if (activeStep === 2 && curr.city !== '' && curr.state !== '' && curr.country !== '') {
            currSectionFilled = true;
        } else {
            currSectionFilled = false;
        }
        return currSectionFilled;
    };

    return (
        <div className='dashboard-container'>
            <div className='dashboard-w'>
                  <div className='txt-center p-b-20'>
                    <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1.1 }} >{pageTitle}</Typography>
                </div>
                <Box sx={{ width: 700, maxWidth: '100%', margin: '0 auto' }}>
                    <Stepper activeStep={activeStep} alternativeLabel className='p-b-30' >
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>


          
                {activeStep === 0 ? (
                    <>
                        {state.id ? (
                            <Grid item xs={12} className={classes.textFieldStyle} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Typography variant="body2">{t('user.setAsDefault')}</Typography>
                                <Switch
                                    checked={state.default === 1}
                                    onChange={() => handleAddressDefault(state.id)}
                                    color="primary"
                                    name="defaultSwitch"
                                    inputProps={{ 'aria-label': 'default switch' }}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12} className={classes.textFieldStyle}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='user_name'
                                placeholder={t('user.name') + '*'}
                                value={state?.user_name}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.user_name ? inputErrors.user_name : ''}
                                error={inputErrors && inputErrors.user_name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='user_mobile'
                                placeholder={t('user.mobile') + '*'}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.user_mobile ? inputErrors.user_mobile : ''}
                                error={inputErrors && inputErrors.user_mobile ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.user_mobile}
                                className={classes.textFieldStyle}
                            />
                        </Grid>
                    </>
                ) : <>
                    {activeStep === 1 ? (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='address_name'
                                    placeholder={t('user.addressName') + '*'}
                                    value={state?.address_name}
                                    onChange={handleChange}
                                    required
                                    helperText={inputErrors && inputErrors.address_name ? inputErrors.address_name : ''}
                                    error={inputErrors && inputErrors.address_name ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='address1'
                                    placeholder={t('user.address') + '*'}
                                    value={state?.address1}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                                    error={inputErrors && inputErrors.address1 ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='address2'
                                    placeholder={state.country === 'CN' ? t(`user.province`) : t(`user.address2`)}
                                    value={state?.address2}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                    error={inputErrors && inputErrors.address2 ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='zip'
                                    placeholder={t('user.zip') + '*'}
                                    value={state?.zip}
                                    onChange={handleChange}
                                    required
                                    helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                    error={inputErrors && inputErrors.zip ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </Grid>
                        </div>
                    ) :
                        <div>
                            {
                                _.size(addressOption?.countryList) > 0 &&
                                <Grid item xs={12}>
                                    <Box sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                        <FormControl fullWidth error={inputErrors && inputErrors.country ? true : false}>
                                            {/* <InputLabel>{t('user.country') + '*'}</InputLabel> */}
                                            <Select
                                                value={state?.country || 0}
                                                // label={t('user.country') + '*'}
                                                name="country"
                                                onChange={handleChange}
                                                displayEmpty
                                            >
                                                <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t('user.country')} `} --- </MenuItem>
                                                {
                                                    _.map(addressOption?.countryList, (data, key) => {
                                                        return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            {
                                                inputErrors && inputErrors?.country &&
                                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.country}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Box>
                                </Grid>
                            }
                            {
                                _.size(filteredStateList) > 0 ?
                                    <Grid item xs={12}>
                                        <Box sx={{ '& > :not(style)': { marginTop: 2, width: '100%' }, }} >
                                            <FormControl fullWidth error={inputErrors && inputErrors.state ? true : false}>
                                                {/* <InputLabel>{t('user.state') + '*'}</InputLabel> */}
                                                <Select
                                                    value={state?.state || 0}
                                                    // label={t('user.state') + '*'}
                                                    name="state"
                                                    onChange={handleChange}
                                                    displayEmpty
                                                >
                                                    <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t('user.state')} `} --- </MenuItem>
                                                    {
                                                        _.map(filteredStateList, (data, key) => {
                                                            return <MenuItem key={key} value={data.id}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                {
                                                    inputErrors && inputErrors?.state &&
                                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.state}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Box sx={{ '& > :not(style)': { marginTop: 2, width: '100%' }, }} >
                                            <TextField
                                                placeholder={t('user.state') + '*'}
                                                variant="standard"
                                                value={state.state || ''}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                                error={inputErrors && inputErrors.state ? true : false}
                                                name="state"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Grid>
                            }
                            {
                                _.size(filteredCityList) > 0 ?
                                    <Grid item xs={12}>
                                        <Box sx={{ '& > :not(style)': { marginTop: 2, width: '100%' }, }} >
                                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                                {/* <InputLabel>{t('user.city') + '*'}</InputLabel> */}
                                                <Select
                                                    value={state?.city || 0}
                                                    // label={t('user.city') + '*'}
                                                    name="city"
                                                    onChange={handleChange}
                                                    displayEmpty
                                                >
                                                    <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t('user.city')} `} --- </MenuItem>
                                                    {
                                                        _.map(filteredCityList, (data, key) => {
                                                            return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                {
                                                    inputErrors && inputErrors?.city &&
                                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.city}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Box sx={{ '& > :not(style)': { marginTop: 2, width: '100%' }, }} >
                                            <TextField
                                                placeholder={t('user.city') + '*'}
                                                variant="standard"
                                                value={state.city || ''}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                                error={inputErrors && inputErrors.city ? true : false}
                                                name="city"
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Grid>
                            }
                        </div>
                    }
                </>
                }

                <div style={{ fontWeight: 500 }}>
                    <Box className="flex-sb-m p-t-15 pos-relative" style={{zIndex:2}}>
                        <Button

                            className={activeStep === 0 ? 'btn-disable' : 'btn-white'}
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            {t('button.back')}
                        </Button>
                        {activeStep === steps.length - 1 ? <Button onClick={handleSubmit} style={{ color: '#ffffffcc' }} className='big-button'>
                            {t('button.submit')}
                        </Button> :
                            <Button className='big-button' style={{ color: '#ffffffcc' }} onClick={handleNext}>
                                {t('button.next')}
                            </Button>
                        }
                    </Box>
                </div>
                <Frame />
            </div>
            
        </div>
    )
});

export default AddressDetails;

const useStyles = makeStyles(theme => ({
    titleBox: {
        backgroundImage: `url('/images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: 320,
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));