// import moment from 'moment-timezone';
import _ from 'lodash';
import React, { useEffect, useState , useRef } from 'react';

import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate ,useParams } from 'react-router-dom';
import { storePaths, currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

import { 
    // Card, 
    // CardContent, 
    // Accordion, 
    // AccordionActions,
    // AccordionSummary, 
    // AccordionDetails,
    // DialogTitle,
    // DialogActions, 
    // TabContext,
    // TabList,
    // TabPanel,
    // Dialog, DialogContent,
    // IconButton,
    // SpeedDial, SpeedDialAction, SpeedDialIcon,
    // createTheme,
    // ThemeProvider , 
    // Tabs, Tab,
    Button, 
    Box,
    Hidden,
    Pagination , 
    Typography, 
    Stack, LinearProgress,
    Grid, Select, MenuItem , FormControl
} from '@mui/material';
// import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import { makeStyles, useTheme, styled } from '@mui/styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TbTransfer } from "react-icons/tb";

import theme from '../../../Theme';

const INITIAL_STATE = {
    
}
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '90%' }}>
                <Stack spacing={2} >
                    <LinearProgress 
                        style={{
                            height: 10, 
                            borderRadius: 5,
                            backgroundColor: 'transparent',
                            boxShadow: '0px 1px 2px 0px #000000DB inset,  0px 2px 3px 0px #FFFFFF12',
                            
                        }}
                        sx={{
                            '& .MuiLinearProgress-barColorPrimary':{
                                borderRadius: 5,
                            },
                        }}
                        variant="determinate" 
                        {...props} 
                    />
                </Stack>
            </Box>
            <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`${Math.round(
                props.value,
            )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};
  
export default function MarketPriceDetails() {
    const { t, i18n } = useTranslation();
    let { range } = useParams();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();
    const navigate = useNavigate();
    const { addAlert , setLoading , loading } = useNotificationLoading();
    const [data, setData] = useState([]);
    const [selectedRange, setSelectedRange] = useState('');
    const [currentRate, setCurrentRate] = useState('');

    const [priceRange, setPriceRange] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    // ------------ setup call api function ------------

    const callAll = () => {

        let apiData = {
            price_range: selectedRange ?? range,
            per_page: 10,
            page: page,
        };

        getUrl(`/member-get-price-range`, apiData).then(response => {
            setLoading(false);
            if (response.status) 
            {
                setPriceRange(response.price_range);
                setData(response.listing.data);
                setTotalPage(response.listing.last_page);
                if (!selectedRange)
                {
                    setSelectedRange(range);
                }
                setCurrentRate(response.current_rate);
            } 
            else 
            {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        setLoading(true);
        callAll();
        
        // eslint-disable-next-line
    }, [page, selectedRange]);

    const handleChange  = ({target}) => {
        setSelectedRange(target.value);
        setPage(1);
        goToLink(`/market-price-details/${target.value}`);
    }
    // ------------ layout ------------

    const desktopMarketBar = () => {
        return (
            <div className={classes.menuBar}>
                <div className='leftItem'>
                    <p style={{ color: '#ADADAD' , fontSize: 18 , fontWeight: 400 }} >{ t('general.rateRange') }</p>
                    <FormControl fullWidth>
                        <Select
                            value={selectedRange}
                            name="selectedRange"
                            onChange={ handleChange }
                            style={{
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                color: theme.palette.white.main,
                                fontSize: 25,
                                fontWeight: 'bold',
                                padding: 0,
                                marginTop: -8,
                            }}
                            IconComponent={(props) => (<KeyboardArrowDownIcon {...props} style={{ color:theme.palette.white.main, fontSize:'xx-large' }}/>)}
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    right: -5,
                                    left:"unset",
                                },
                            }}
                        >
                            {
                                _.map(priceRange, (value, key) => {
                                    return <MenuItem key={key} value={parseFloat(value?.min_price_range)}>{value.min_price_range} - {value.max_price_range}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>

                <div className='rightItem' >
                    <p style={{ color: theme.palette.white.main , fontSize: 36 , fontWeight: 700 }}> { currencyFormat(_.find(priceRange, pr => parseInt(pr.min_price_range) === parseInt(selectedRange))?.balance ?? 0 , 0) } </p>
                    <p style={{ color: '#ADADAD' , fontSize: 16 , fontWeight: 400 , marginTop: -8 }}>{ t('general.availableBalance') }</p>
                </div>

            </div>
        )
    }

    const desktopMarkrtListing = () => {
        return (
            <div 
                style={{ 
                    backgroundColor: theme.palette.secondary.main, 
                    borderRadius: 15, 
                    padding: 20,
                    color: theme.palette.white.main
                }}
            >
                <div className='clwhite title-bg ' style={{ maxWidth: '100%', width: '100%', margin: '-45px 0 20px' , color: theme.palette.white.main, textAlign: 'left', fontWeight: 700, }}>
                    <Grid container spacing={1} className={ classes.listingTable }>
                        <Grid item xs={2} className='listingContent'>
                            <p style={{ fontWeight: "bold" }}>{t('general.rate')}</p>
                        </Grid>
                        <Grid item xs={2} className='listingContent'>
                            <p style={{ fontWeight: "bold" }}>{t('general.total')}</p>
                        </Grid>
                        <Grid item xs={2} className='listingContent'>
                            <p style={{ fontWeight: "bold" }}>{t('general.sold')}</p>
                        </Grid>
                        <Grid item xs={2} className='listingContent'>
                            <p style={{ fontWeight: "bold" }}>{t('general.balance')}</p>
                        </Grid>
                        <Grid item xs={3} className='listingContent' />
                        <Grid item xs={1} className='listingContent' />
                    </Grid>
                </div>

                <div style={{ padding: '10px 20px' , overflow: 'auto' }}>
                    {
                        _.size(data) > 0 ?
                        data.map((item, index) => {
                            return (
                                <div key={index} style={{ paddingTop: 10}}>
                                    <Grid container spacing={1} className={classes.listingTable} >

                                        <Grid item xs={2} className='listingContent'>
                                            <p>{ currencyFormat(item.price, 3) }</p>
                                        </Grid>
                                        
                                        <Grid item xs={2} className='listingContent'>
                                            <p>{ currencyFormat(item.total_trade_unit, 0) }</p>
                                        </Grid>

                                        <Grid item xs={2} className='listingContent'>
                                            <p>{ currencyFormat(item.total_sold_unit, 0) }</p>
                                        </Grid>

                                        <Grid item xs={2} className='listingContent'>
                                            <p>{ currencyFormat((item.total_trade_unit - item.total_sold_unit), 0) }</p>
                                        </Grid>

                                        <Grid item xs={3} className='listingContent'>
                                            <Box sx={{ width: '90%' , minWidth: 100,}}>
                                                <LinearProgressWithLabel value={ Number(( (item.total_trade_unit - item.total_sold_unit) / item.total_trade_unit ) * 100) } />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={1} className='listingContent'>
                                            <Button 
                                                variant="contained" 
                                                sx={{
                                                    "&.Mui-disabled": {
                                                        backgroundColor: "#D9D9D9" ,
                                                        color: "#ADADAD",
                                                        boxShadow: '2px 2px 4px 0px #00000040 inset',
                                                    },
                                                }}
                                                disabled={ ( (item.total_trade_unit - item.total_sold_unit) > 0 && parseInt(item.status) === 20 && parseFloat(currentRate) !== parseFloat(item.price) ) ? false : true}
                                                onClick={() => goToLink(`/trade/sell?rate=${item.price}`)}
                                            >
                                                { t('title.tradeAction') }
                                            </Button>
                                        </Grid>

                                    </Grid>
                                    { !(index === data.length - 1) && <div className="divider-list-black" /> }
                                </div>
                            )
                        })
                    :
                        ( 
                        <div className='p-b-10 p-tb-10' >
                            <div className='flex-sb-m w-full' style={{ fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                                <p>{t('general.noDisplay')}</p>
                            </div>
                        </div>
                        )
                    }
                </div>
                {
                    _.size(data) > 0 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '20px 0px',
                            }}
                        >
                            <Pagination 
                                count={totalPage} 
                                page={page} 
                                onChange={(event, page) => setPage(page)} 
                                sx={{
                                    '& .MuiPaginationItem-root ': {
                                        color: theme.palette.white.main
                                    },
                                }}
                            />
                        </div>
                }
            </div>
        )
    }
    
    const mobileMarketBar = () => {
        return (
            <div className={classes.menuBar}>
                <div className='rightItem' >
                    <p style={{ color: theme.palette.white.main , fontSize: 35 , fontWeight: 700 }}> { currencyFormat( _.find(priceRange, pr => parseInt(pr.min_price_range) === parseInt(selectedRange))?.balance ?? 0, 0) } </p>
                    <p style={{ color: '#ADADAD' , fontSize: 15 , fontWeight: 400 , marginTop: -8, }}>{ t('general.availableBalance') }</p>
                </div>
            </div>
        )
    }

    const mobileMarkrtListing = () => {
        return (
            <>
                <div 
                    style={{ 
                        backgroundColor: theme.palette.secondary.main, 
                        borderRadius: 15, 
                        padding: 20,
                        color: '#F0F0F0'
                    }}
                >
                    <div 
                        style={{
                            maxWidth: '100%', 
                            width: '100%', 
                            margin: '-60px 0 20px' , 
                            color: '#F0F0F0', 
                            fontWeight: 700, 
                            textAlign: 'left', 

                        }}
                    >
                        <p style={{ color: theme.palette.white.main , fontSize: 12 , fontWeight: 400, padding: '0px 20px', textTransform: "uppercase", }} >{ t('general.rateRange') }</p>
                        <div 
                            style={{ 
                                padding: '0px 20px',
                                background: theme.palette.primary.linear,
                                borderRadius: 8,
                                boxShadow: '0px 4px 5px 0px #D13C3C2B',
                            }}
                        >
                            <div className='leftItem'>
                                <FormControl fullWidth>
                                    <Select
                                        value={selectedRange}
                                        name="selectedRange"
                                        onChange={ handleChange }
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            color: theme.palette.white.main,
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            padding: 0,
                                            height: '40px'
                                        }}
                                        IconComponent={(props) => (<KeyboardArrowDownIcon style={{ color: "#f0f0f0" }} {...props}/>)}
                                    >
                                        {
                                            _.map(priceRange, (value, key) => {
                                                return <MenuItem key={key} value={parseFloat(value?.min_price_range)}>{value.min_price_range} - {value.max_price_range}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        {
                            _.size(data) > 0 ?
                            data.map((item, index) => {
                                return (
                                    <div key={index} style={{ margin: '5px 0'}}>
                                        <div 
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div style={{ width: '95%', padding: '5px 0'}}>
                                                <div className='flex-sb-m' style={{  maxWidth: '90%'}}>
                                                    <Typography style={{ fontSize: 14 , fontWeight: 700 }} >{ currencyFormat(item.price, 3) }</Typography>
                                                    <Typography style={{ fontSize: 11 , fontWeight: 400,  }} >{ currencyFormat((item.max_unit - item.sold_unit), 0) } / { currencyFormat( item.max_unit , 0) }</Typography>
                                                </div>
                                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                                    <Box sx={{ width: '90%' }}>
                                                        <Stack spacing={2} >
                                                            <LinearProgress 
                                                                style={{
                                                                    height: 10, 
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'transparent',
                                                                    boxShadow: '0px 1px 2px 0px #000000DB inset,  0px 2px 3px 0px #FFFFFF12',
                                                                    
                                                                }}
                                                                sx={{
                                                                    '& .MuiLinearProgress-barColorPrimary':{
                                                                        borderRadius: 5,
                                                                    },
                                                                }}
                                                                variant="determinate" 
                                                                value={ Number(( (item.max_unit - item.sold_unit) / item.max_unit ) * 100) }
                                                            />
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </div>
                                            <Button 
                                                variant="contained" 
                                                style={{
                                                    minWidth: 'auto',
                                                    borderRadius: '10px 0',
                                                    padding: 1,
                                                    aspectRatio: 1/1,
                                                    maxHeight: 30 , 
                                                    height: 30,
                                                }}
                                                sx={{
                                                    "&.Mui-disabled": {
                                                        backgroundColor: "#D9D9D9" ,
                                                        color: "#ADADAD",
                                                        boxShadow: '2px 2px 4px 0px #00000040 inset',
                                                    },
                                                }}
                                                disabled={ ((item.max_unit - item.sold_unit) > 0 && parseInt(item.status) === 20 ) ? false : true}
                                                onClick={() => goToLink(`/trade/sell?rate=${item.price}`)}
                                            >
                                                <TbTransfer style={{ fontSize: '20px', margin: 3, }}/>
                                            </Button>
                                            {/* <div 
                                                style={{ 
                                                    maxHeight: 30 , 
                                                    height: 30,
                                                    aspectRatio: 1/1,
                                                    boxShadow: '2px 2px 4px 0px #FFFFFF36 inset, -2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026',
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.white.main,
                                                    borderRadius: '10px 0',
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                    padding: 1,
                                                    alignSelf: 'center',
                                                }}
                                                onClick={() => goToLink(`/trade/sell?rate=${item.price}`)}
                                            >
                                                <TbTransfer style={{ fontSize: '20px', margin: 3, }}/>
                                            </div> */}
                                        </div>
                                        { !(index === data.length - 1) && <div className="divider-list-black" /> }
                                    </div>
                                )
                            })
                        :
                            ( 
                            <div className='p-b-10 p-tb-10' >
                                <div className='flex-sb-m w-full' style={{ fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                                    <p>{t('general.noDisplay')}</p>
                                </div>
                            </div>
                            )
                        }
                    </div>
                </div>
                {
                    _.size(data) > 0 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '20px 0px',
                            }}
                        >
                            <Pagination 
                                count={totalPage} 
                                page={page} 
                                onChange={(event, page) => setPage(page)} 
                                sx={{
                                    '& .MuiPaginationItem-root ': {
                                        color: theme.palette.white.main
                                    },
                                }}
                            />
                        </div>
                }
            </>
        )
    }

    // ------------ layout ------------

    return (
        <>
            <Hidden mdDown>
                <div className='dashboard-container'>
                    { desktopMarketBar() }
                    { desktopMarkrtListing() }
                </div>
            </Hidden>

            <Hidden mdUp>
                <div style={{ padding: '12vh 50px', }}>
                    { mobileMarketBar() }
                    { mobileMarkrtListing() }
                </div>
            </Hidden>
        </>
        
    );
}

const useStyles = makeStyles(theme => ({
    menuBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px 60px 10px',
        '& .leftItem': {
            textAlign: 'left',
        },
        '& .rightItem': {
            textAlign: 'right',
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            '& .leftItem': {
                textAlign: 'center',
            },
            '& .rightItem': {
                textAlign: 'center',
                padding: '20px 10px',
            },
        },
    },
    listingTable: {
        '& .listingContent': {
            
        }
    }
}));