import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

import { currencyFormat } from '@utils/Tools';
// import { storePaths } from '@utils/Tools';
// import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import { FaCheck, FaArrowCircleDown } from "react-icons/fa";

const MerchantRecieveReceipt = props => {
    // const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { data, completedLink , status } = props;

    // let location = useLocation();
    // let navigate = useNavigate();
    const containerRef = useRef();
    // let currDate = moment();

    const [isBottom, setIsBottom] = useState(false);
    
    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 10;

            if (isAtBottom) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
        };

        // Attach the scroll event listener to the container
        container.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => { container.removeEventListener('scroll', handleScroll); };
    }, []);

    // Extract the decimal value from the wallet data, default to 2 if not available
    // const decimalPlaces = data?.wallet_balance?.wallet?.decimal || 2;

    // Format the amount with the appropriate number of decimal places
    // const formattedAmount = parseFloat(type === 'transaction' ? data?.amount : parseFloat(data?.price)).toFixed(decimalPlaces);

    // Determine the sign and color based on the transaction.factor
    // const sign = data?.factor === 1 ? '+' : (data?.factor === -1 ? '-' : '');

    const handleClose = () => {
        // Call the handleClose callback passed from TransactionItem
        props.handleClose();
    };

    return (
        <Box>
            <Box ref={containerRef} style={{ width: '100%', height: 500, overflow: 'scroll', background: 'transparent' }}>
                <Box style={{ padding: '30px', background: 'transparent'}}>
                    {/* Close button */}
                    <Box style={{ width: '100%', textAlign: 'right', position: 'relative', zIndex: 2 }}>
                        <IconButton className={styles.colorGrey} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box style={{
                        marginBottom: 30,
                        // backgroundImage: 'url(/images/trade/mountain.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom center'
                    }}>
                        <Box className="flex-c-m">
                            <FaCheck className={styles.checkIcon} />
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                <Typography
                                    className={
                                        styles.colorGreen
                                    }
                                    style={{ fontWeight: 'bold' }}
                                    variant='h6'
                                >
                                    {'succcess'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                <Typography variant='body2' className={styles.colorGrey}>{t('merchantRecieve.subtitle')}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Receipt Details */}
                    <div>
                        {/* refNo */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey} style={{ marginRight: 20 }}>{t('receipt.refNo')}</Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ width: '70%', wordBreak: 'break-word' }}>{data?.trade_transaction?.ulid}</Typography>
                        </Box>
                        {/*Date Time */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey}>
                                {t('receipt.dateTime')}
                            </Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>
                                {data?.created_at}
                            </Typography>
                        </Box>
                    </div>

                    <div className={styles.rectangle} />

                    <div>
                        <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 10 }}>{t('merchantRecieve.summary')} </Typography>

                        {/* user value */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey}>
                                {t('merchantRecieve.user')}
                            </Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>
                                {data?.trade_transaction?.trade_profile?.user?.username}
                            </Typography>
                        </Box>

                        {/* using unit */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey}>
                                {t('general.unit')}
                            </Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>
                                {currencyFormat(data?.unit, 2)} {t('general.unit')}
                            </Typography>
                        </Box>

                        {/* using value */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey}>
                                {t('general.value')}
                            </Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>
                                $ {currencyFormat(data?.value, 2)}
                            </Typography>
                        </Box>

                    </div>

                    <div className={styles.rectangle} />

                    {/* remark admin*/}
                    {
                        data?.remark
                            ? (
                                <>
                                    <Box style={{ marginBottom: 10 }}>
                                        <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 5 }}>{t('receipt.remark')} </Typography>
                                        {
                                            data?.remark?.member_remark
                                                ? <Typography variant="body2" className={styles.colorGrey}>{data?.remark?.member_remark ? data?.remark?.member_remark : '-'}</Typography>
                                                : <Typography variant="body2" className={styles.colorGrey}>{data?.remark?.admin_remark ? data?.remark?.admin_remark : '-'}</Typography>
                                        }
                                    </Box>
                                    <div className={styles.rectangle}></div>
                                </>
                            )
                            : (null)
                    }

                    {/* total amount */}
                    <Box className={styles.detailsLayout} style={{ margin: '10px 0 0 0' }}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }} className={styles.colorGrey}>{t('receipt.amount')}</Typography>
                        <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", color: '#565656', width: '70%' }}>
                            {'$ ' + currencyFormat(parseFloat(data?.value), 2)}
                        </Typography>
                    </Box>

                </Box>
            </Box>

            {
                !isBottom &&
                <div style={{ position: 'absolute', bottom: 25, width: '100%', textAlign: 'center' }}>
                    <FaArrowCircleDown style={{ fontSize: 25, color: !isBottom ? '#a4a4a4' : 'transparent' }} />
                </div>
            }
            
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    colorGreen: {
        color: "#39A47D"
    },
    colorGrey: {
        color: "#565656"
    },
    colorLightGrey: {
        color: '#a4a4a4',
    },
    colorRed: {
        color: '#FF0101',
    },
    checkIcon: {
        color: "#39A47D",
        width: 100,
        height: 50
    },
    detailsLayout: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15
    },
    rectangle: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        height: '4px',
        margin: '5px 0px 15px 0px'
    },
}));

export default MerchantRecieveReceipt;