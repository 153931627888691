import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, TextField, Typography, Button, InputLabel, Link, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { ImCross } from "react-icons/im";
import { TbAlertTriangleFilled } from "react-icons/tb";

import useNotificationLoading from '@utils/useNotificationLoading';
import { buildFormData } from '@utils/Tools';
import { postUrl } from '@utils/ApiAction';

export default function ProcessMerchant(props) {
    const { state, setState, setResubmitForm } = props;

    const theme = useTheme();
    const styles = useStyles();
    const containerRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState({});
    const [uploadExtraDoc, setUploadExtraDoc] = useState(false);
    const [extraDocs, setExtraDocs] = useState({
        ssm58: '',
        ssm78: '',
        halal: '',
    });
    const [isUploading, setIsUploading] = useState(false);
    const [isRight, setIsRight] = useState(true);
    const [isLeft, setIsLeft] = useState(false);
    const [hasScroll, setHasScroll] = useState(false);

    // --------------- Api -------------------
    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const checkForScrollbar = () => {
                setHasScroll(container.scrollWidth > container.clientWidth);
            };

            checkForScrollbar();

            const handleScroll = () => {
                let scrolling = container.scrollLeft + container.clientWidth;
                if (scrolling >= (container.scrollWidth - 10)) {
                    setIsRight(false);
                    setIsLeft(true);
                } else if (scrolling > container.clientWidth && scrolling < container.scrollWidth) {
                    setIsRight(true);
                    setIsLeft(true);
                } else if (scrolling <= container.clientWidth) {
                    setIsRight(true);
                    setIsLeft(false);
                }
            };

            // Attach the scroll event listener to the container
            container.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', checkForScrollbar);

            // Clean up the event listener when the component unmounts
            return () => {
                container.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', checkForScrollbar);
            };
        }
    }, []);

    // --------------- Functions -------------------
    const handleFilesDelete = (name) => {
        switch (name) {
            case 'ssm58':
                setExtraDocs({ ...extraDocs, ssm58: '' });
                break;
            case 'ssm78':
                setExtraDocs({ ...extraDocs, ssm78: '' });
                break;
            case 'halal':
                setExtraDocs({ ...extraDocs, halal: '' });
                break;
        }
    };

    const handleChange = ({ target }) => {
        let { name, value, files } = target;
        switch (name) {
            case 'ssm58':
            case 'ssm78':
            case 'halal':
                if (files[0]?.size > 5242880) {
                    target.value = null;
                    setExtraDocs({ ...extraDocs, [name]: null })
                    setInputErrors({ [name]: t('general.maxFileSize', { S: 5120 }) })
                    addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
                } else {
                    setInputErrors({})
                    const file = target.files[0];
                    setExtraDocs({ ...extraDocs, [name]: files[0] });

                    if (file ? file instanceof Blob: false) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            switch ( name ) 
                            {
                                default:
                                    break;
                            }
                        };
                        reader.readAsDataURL(file);
                    } else {
                        console.error("File is not a Blob or it's missing");
                    }
                }
                break;
            default:
                setExtraDocs({ ...extraDocs, [name]: value });
                break;
        }
    };

    const submitExtraFile = () => {
        const formData = new FormData();
        buildFormData(formData, extraDocs);

        try {
            setIsUploading(true);
            postUrl(`/member-upload-extra-file`, formData).then(response => {
                if (response.status) {
                    let tempArray = [];
                    if (response?.data?.ic_front || response?.data?.ic_back) {
                        tempArray.push(response?.data?.ic_front);
                        tempArray.push(response?.data?.ic_back);
                    }
                    setState({
                        ...state,
                        id: response?.data?.id,
                        name: response?.data?.name,
                        email: response?.data?.email,
                        phone: response?.data?.mobile,
                        code: response?.data?.code,
                        companyRegisterNumber: response?.data?.company_register_number,
                        website: response?.data?.website,
                        businessNature: response?.data?.bussiness_nature,
                        aboutShop: response?.data?.about_shop,
                        productSell: response?.data?.product_sell,
                        address: {
                            country: response?.data?.addresses?.[0]?.country,
                            state: response?.data?.addresses?.[0]?.state,
                            city: response?.data?.addresses?.[0]?.city,
                            zip: response?.data?.addresses?.[0]?.zip,
                            province: response?.data?.addresses?.[0]?.province,
                            address1: response?.data?.addresses?.[0]?.address1,
                            fullAddress: response?.data?.addresses[0]?.full_address,
                        },
                        ic: _.size(tempArray) > 0 ? tempArray : {},
                        icFront: response?.data?.ic_front,
                        icBack: response?.data?.ic_back,
                        supportDoc: response?.data?.support_doc,
                        newLogo: response?.data?.logo,
                        status: response?.data?.status,
                    });
                    setUploadExtraDoc(false);
                    addAlert("", response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert("", response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setIsUploading(false);
            });
        } catch (error) {
            // do nothing
        }
    }

    let productSellNum = 0;
    if (state?.productSell) {
        productSellNum = _.size(_.split(state?.productSell, ','));
    }

    // ----------------- Displays -------------------
    const UploadButton = ({icon}) => {
        let IconDisplay = null;
        let IconText = '';
        let IconFileDisplay = '';
        let helperText = '';
        switch (icon) {
            case 'ssm58':
                IconDisplay = <HiOutlineDocumentText className={styles.uploadButtonIconDesign} />;
                IconText = t('merchant.ssm58');
                IconFileDisplay = 'ssm58';
                helperText = 'ssm58';
                break;
            case 'ssm78':
                IconDisplay = <HiOutlineDocumentText className={styles.uploadButtonIconDesign} />;
                IconText = t('merchant.ssm78');
                IconFileDisplay = 'ssm78';
                helperText = 'ssm78';
                break;
            case 'halal':
                IconDisplay = <div className={`m-b-10 ${styles.imagePreviewDisplayDesign}`} style={{ width: '60%', backgroundImage: `url(images/merchants/halal_logo.png)`, objectFit: 'contain' }} />;
                IconText = t('merchant.halalCert');
                IconFileDisplay = 'halal';
                helperText = 'halal';
                break;
        }

        return (
            <div className={styles.uploadButtonDesign} style={{ position: 'relative' }}>
                <InputLabel htmlFor={IconFileDisplay} style={{ textAlign: '-webkit-center', cursor: 'pointer', background: '#EEE', padding: 25, borderRadius: 15, whiteSpace: 'inherit', lineHeight: 1.1 }}>
                    {
                        icon === 'ssm58' && extraDocs?.ssm58
                            ?
                            <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }}>
                                <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                    <MdModeEditOutline style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                </div>
                            </div>
                            : icon === 'ssm78' && extraDocs?.ssm78
                                ?
                                <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }}>
                                    <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                        <MdModeEditOutline style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                    </div>
                                </div>
                                : icon === 'halal' && extraDocs?.halal
                                    ?
                                    <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }}>
                                        <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                            <MdModeEditOutline style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {IconDisplay}
                                        <Typography style={{ color: theme.palette.darkGray.main }}>{IconText}</Typography>
                                    </>
                    }
                </InputLabel>

                <TextField
                    type={"file"}
                    sx={{ display: "none" }}
                    id={IconFileDisplay}
                    name={IconFileDisplay}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors[helperText] ? inputErrors[helperText] : ''}
                    error={inputErrors && inputErrors[helperText] ? true : false}
                />
            </div>
        )
    }

    const extraUploadFile = () => {
        return (
            <>
                <Typography variant='h5' className='txt-left' style={{ color: "#ADADAD", margin: '0 0 15px' }}>{t('merchant.additionalDoc')}</Typography>
                <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.gray.main, margin: "-5px 0 15px 0" }}>{t('merchant.uploadNoted')}</Typography>

                {
                    _.size(inputErrors) > 0 &&
                    <div style={{ margin: "-5px 0 15px 0" }}>
                        {
                            inputErrors?.ssm58 &&
                            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.ssm58}</Typography>
                        }
                        {
                            inputErrors?.ssm78 &&
                            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.ssm78}</Typography>
                        }
                        {
                            inputErrors?.halal &&
                            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.halal}</Typography>
                        }
                    </div>
                }

                <Grid container spacing={4} style={{ justifyContent: 'space-around' }}>
                    <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                        <UploadButton icon='ssm58' />
                        <div className='m-tb-10 m-r-10'>
                            {
                                extraDocs?.ssm58
                                    ?
                                    <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                        <p style={{ color: theme.palette.darkGray.main, width: '80%' }}>{extraDocs?.ssm58?.name} </p>
                                        <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('ssm58')} />
                                    </div>
                                    : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.fileTypes')}</Typography>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                        <UploadButton icon='ssm78' />
                        <div className='m-tb-10 m-r-10'>
                            {
                                extraDocs?.ssm78
                                    ?
                                    <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                        <p style={{ color: theme.palette.darkGray.main, width: '80%' }}>{extraDocs?.ssm78?.name} </p>
                                        <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('ssm78')} />
                                    </div>
                                    : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.fileTypes')}</Typography>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                        <UploadButton icon='halal' />
                        <div className='m-tb-10 m-r-10'>
                            {
                                extraDocs?.halal
                                    ?
                                    <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                        <p style={{ color: theme.palette.darkGray.main, width: '80%' }}>{extraDocs?.halal?.name} </p>
                                        <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('halal')} />
                                    </div>
                                    : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.fileTypes')}</Typography>
                            }
                        </div>
                    </Grid>
                </Grid>

                <div className='flex-sb' style={{ margin: '30px 0 0'}}>
                    <Button
                        variant="contained"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: theme.palette.darkBase.main,
                                color: theme.palette.white.main,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#D9D9D9",
                                color: "#ADADAD",
                                boxShadow: '2px 2px 4px 0px #00000040 inset',
                            },
                        }}
                        onClick={() => {setUploadExtraDoc(false);}}
                    >
                        {t('button.back')}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            "&.Mui-disabled": {
                                backgroundColor: "#D9D9D9",
                                color: "#ADADAD",
                                boxShadow: '2px 2px 4px 0px #00000040 inset',
                            },
                        }}
                        onClick={submitExtraFile}
                        disabled={(extraDocs?.ssm58 && extraDocs?.ssm78 && extraDocs?.halal) && !isUploading ? false : true}
                    >
                        {isUploading ? t('button.loading') : t('button.uploadFile')}
                    </Button>
                </div>
            </>
        )
    }

    const processing = () => {
        return (
            <>
                <Typography variant='h5' className='txt-center' style={{ color: "#ADADAD", margin: '0 0 15px' }}>
                    {state?.status === 40 ? t('merchant.submitFail') : state?.status === 35 ? t('merchant.additionalDoc') : t('merchant.submitSuccess')}
                </Typography>

                <Grid container spacing={4} style={{ textAlign: 'left' }}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={12} lg={4}>
                                <div className={styles.logoPreviewDisplayDesign} style={{ backgroundImage: `url(${state?.newLogo?.file_name})` }} />
                            </Grid>
                            <Grid item xs={12} sm={8} md={12} lg={8}>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}><i>{state?.code ? state?.code : '-'}</i></Typography>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}><b>{state?.name ? state?.name?.en : '-'}</b></Typography>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}><b>{state?.name ? state?.name?.cn : '-'}</b></Typography>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}><i>{state?.companyRegisterNumber ? state?.companyRegisterNumber : '-'}</i></Typography>
                                <br />

                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.website ? state?.website : '-'}</Typography>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.email ? state?.email : '-'}</Typography>
                                <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.phone ? state?.phone : '-'}</Typography>
                            </Grid>
                        </Grid>

                        <div className='p-t-10'>
                            <Typography variant='body2' style={{ color: '#ADADAD' }}>{t('merchant.address1')}</Typography>
                            <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.address?.fullAddress}</Typography>
                            <br />

                            <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.aboutShop ? state?.aboutShop : '-'}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='body2' style={{ color: '#ADADAD' }}>{t('merchant.businessNature')}</Typography>
                        <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.businessNature ? state?.businessNature : '-'}</Typography>
                        <br />

                        <Typography variant='body2' style={{ color: '#ADADAD' }}>{t('merchant.productSell')} ({productSellNum})</Typography>
                        <Typography variant='body2' style={{ color: theme.palette.gray.main }}>{state?.productSell ? state?.productSell : '-'}</Typography>
                        <br />

                        <Typography variant='body2' style={{ color: '#ADADAD' }}>{t('merchant.supportDocuments')} ({_.size(state?.ic) + _.size(state?.supportDoc)})</Typography>
                        <div className='p-tb-10' style={{ position:'relative' }} >
                            {
                                isLeft && hasScroll && 
                                <div className='flex-m' style={{ background: `linear-gradient(to left, transparent, ${theme.palette.darkGray.main})`, height: '100%', position: 'absolute', top: 0, left: 0 }}>
                                    <ArrowBackIos style={{ paddingLeft: 5, fontSize: 30 }} />
                                </div>
                            }

                            <div ref={containerRef} style={{ display: 'flex', overflowX: 'auto' }}>
                                {
                                    state?.icFront &&
                                    <div className='m-r-15 flex-col-c'>
                                        <Link target="_blank" href={state?.icFront?.file_name} underline='none' className='flex-col-c' style={{ width: '80%' }} >
                                            <div className={styles.previewDocsDesign} style={{ padding: 10, marginBottom: 10 }}>
                                                <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                            </div>
                                            <Typography variant='caption' className='txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{state?.icFront?.name || state?.icFront?.descr}</Typography>
                                        </Link>
                                    </div>
                                }
                                {
                                    state?.icBack &&
                                    <div className='m-r-15 flex-col-c'>
                                        <Link target="_blank" href={state?.icBack?.file_name} underline='none' className='flex-col-c' style={{ width: '80%' }} >
                                            <div className={styles.previewDocsDesign} style={{ padding: 10, marginBottom: 10 }}>
                                                <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                            </div>
                                            <Typography variant='caption' className='txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{state?.icBack?.name || state?.icBack?.descr}</Typography>
                                        </Link>
                                    </div>
                                }
                                {
                                    _.size(state?.supportDoc) > 0 &&
                                    _.map(state?.supportDoc, (doc, idx) => (
                                        <div key={idx} className='m-r-15 flex-col-c'>
                                            <Link target="_blank" href={doc?.file_name} underline='none' className='flex-col-c' style={{ width: '80%', lineHeight: 1.0 }} >
                                                <div className={styles.previewDocsDesign} style={{ padding: 10, marginBottom: 10 }}>
                                                    <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }} />
                                                </div>
                                                <Typography variant='caption' className='txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{doc?.name || doc?.descr}</Typography>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>

                            {
                                isRight && hasScroll &&
                                <div className='flex-m' style={{ background: `linear-gradient(to right, transparent, ${theme.palette.darkGray.main})`, height: '100%', position: 'absolute', top: 0, right: 0 }}>
                                    <ArrowForwardIos style={{ paddingRight: 5, fontSize: 30 }} />
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>

                {state?.status !== 30 && <div className={styles.whiteDividerDesign} />}

                <div className={state?.status === 30 ? 'flex-r' : 'flex-sb'} style={{ margin: '30px 0 0', alignItems: mobileView ? 'center' : 'flex-start', flexDirection: mobileView ? 'column' : 'row' }}>
                    {
                        (state?.status === 40 || state?.status === 35) &&
                        <Grid container className='flex-m' style={{ width: '80%' }}>
                            <Grid item xs={12} sm={2}>
                                {
                                    state?.status === 40
                                        ? <ImCross size={35} style={{ color: theme.palette.primary.secondary }} />
                                        : <TbAlertTriangleFilled size={40} style={{ color: '#E7B73D' }} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                {
                                    state?.status === 40
                                        ? <div className='flex-sb' style={{ flexDirection: mobileView ? 'column' : 'row' }}>
                                            <Typography variant='body2' className='txt-left txt-upper' style={{ color: theme.palette.primary.secondary, lineHeight: 1.2 }}><b>{t('merchant.failRegister')}</b></Typography>
                                            <Typography variant='body2' className='txt-left' style={{ color: theme.palette.primary.secondary, lineHeight: 1.2 }}><b>{state?.remarkLog?.date_time}</b></Typography>
                                        </div>
                                        : <Typography variant='body2' className='txt-left txt-upper' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }}><b>{t('merchant.warningRegister')}</b></Typography>
                                }
                                <Typography variant='body2' className='txt-left' style={{ color: (state?.status === 40 ? theme.palette.primary.secondary : theme.palette.darkGray.main), lineHeight: 1.2 }}>{state?.remarkLog?.remark}</Typography>
                            </Grid>
                        </Grid>
                    }

                    <Button
                        variant="contained"
                        sx={{
                            "&.Mui-disabled": {
                                backgroundColor: "#D9D9D9",
                                color: "#ADADAD",
                                boxShadow: '2px 2px 4px 0px #00000040 inset',
                            },
                        }}
                        disabled={state?.status === 40 || state?.status === 35 ? false : true}
                        onClick={() => { 
                            if (state?.status === 40) 
                                {setResubmitForm(true);} 
                            else if (state?.status === 35) 
                                { setUploadExtraDoc(true);} 
                        }}
                    >
                        { state?.status === 40 ? t('button.resubmit') : state?.status === 35 ? t('button.uploadFile') : t('button.review') }
                    </Button>
                </div>
            </>
        )
    }

    return (
        <div>
            <div style={{ margin: 'auto', textAlign: 'center', padding: mobileView ? '0px' : '0px 20px'}}>
                <div style={{ margin: 'auto', width: '80%', textAlign: 'center', }}>
                    <Typography variant='h5' className='p-t-60 p-b-30'>{t('merchant.processRegister')}</Typography>
                </div>

                <div className='p-all-40 m-lr-10 m-b-10 m-t-20' style={{ background: '#EEE', borderRadius: 15 }}>
                    {
                        uploadExtraDoc
                        ? extraUploadFile()
                        : processing()
                    }
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    uploadButtonDesign: {
        width: '100%',
        aspectRatio: 1/1,
        alignContent: 'center',
        boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A',
        borderRadius: 10,
    },
    previewDocsDesign: {
        width: 60,
        aspectRatio: 1/1,
        alignContent: 'center',
        boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A',
        borderRadius: 10,
    },
    uploadButtonIconDesign: {
        fontSize: 100,
        color: theme.palette.darkGray.main,
    },
    editButtonDesign: {
        position: 'absolute',
        right: 5,
        bottom: 5,
        borderRadius: '50%',
        aspectRatio: 1/1,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '2px 2px 4px 0px #FFFFFF36 inset, -2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026',
    },
    imagePreviewDisplayDesign: {
        width: '100%',
        aspectRatio: 1/1,
        objectPosition: 'center',
        overflow: 'hidden',
        borderRadius: 5,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    logoPreviewDisplayDesign: {
        width: '100%',
        aspectRatio: 1/1,
        objectPosition: 'center',
        borderRadius: 5,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxShadow: '2px 2px 4px 0px #00000040 inset', 
        objectFit: 'cover',
    },
    boxDesign: {
        width:'100%',
        height: 250,
        borderRadius: 10,
        textAlign: 'left',
        padding: '20px 30px', 
        boxShadow: '-3px -3px 4px 0px #FFFFFF inset, 2px 2px 3px 0px #0000001A inset',
    },
    whiteDividerDesign: {
        margin: '10px 0px',
        width: '100%',
        height: 4,
        boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF',
    },
}));