import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Button , ThemeProvider, createTheme, Tab, Tabs} from '@mui/material';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';

import { QRCodeSVG } from 'qrcode.react';
import CustomSelect from '@components/CustomSelect';
import { Link as RouterLink, useParams , useLocation , useSearchParams} from 'react-router-dom';

const TOKENS = ['USDT', 'KEY', 'CARD', 'NFT'];
const NETWORK = ['ERC20', 'KEY', 'CARD', 'NFT'];

const INITIAL_STATE = {
    bsc: [],
    trx: [],
    wallet: '',
    details: []
    // tokens: "",
    // network: "",
    // depositAddress: '0x0000000000000000000000000000000000000000',
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Deposit = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const { addAlert, setLoading } = useNotificationLoading();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState(INITIAL_STATE);
    const [activeTab, setActiveTab] = useState('trx');

    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };


    // const [qrcode, setQrcode] = useState('');
    
    // const handleChange = ({ target }) => {
    //     let { name, value } = target;
    //     setState({ ...state, [name]: value });
    // };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const getDepositAddress = () => {
        setLoading(true);

        try {
            let apiData = {
                wallet: parseInt(atob(searchParams.get('w'))),
            };
            
            getUrl('wallets/deposit_address', apiData).then(response => {
                if (response.status) 
                {
                    setState({ ...state, trx: response.trx , bsc: response.bsc , details: response.details});
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            })
        }
        catch (error) {
            
            addAlert(JSON.stringify(error.message));
        }
    }

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDepositAddress();
        
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='dashboard-container'>
                {/* <Typography style={{ fontSize: 12, padding: '15px 40px 0 ', width: '100%'}}>
                    {t('deposit.noti', {'value': state?.details?.min_amount})}
                </Typography> */}
                {
                    state.trx && state.bsc &&
                    (
                        <>
                            <ThemeProvider theme={tabStyle}>
                                <div style={{ width: 550, margin: ' 0 auto', zIndex: 2 }} className='max-w-full pos-relative'>
                                    <Tabs value={activeTab} onChange={handleTabChange} style={{ overflow: 'unset', }}>
                                        <Tab label={t('wallet.trx')} value="trx" />
                                        <Tab label={t('wallet.bsc')} value="bsc" />
                                    </Tabs>
                                </div>
                            </ThemeProvider>

                            <div className='dashboard-w' style={{ borderTopLeftRadius: 0, paddingTop: 40 }} >
                                <div className='pos-relative ' style={{zIndex:2}}>
                                <div className='txt-center w-full p-b-30 p-t-20'>                 
                                    <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main }} >{state[activeTab] === state.trx ?t('wallet.trx') : t('wallet.bsc')}  {t('title.deposit')}</Typography>
                                </div>
                                <div className='pos-relative' style={{ zIndex: 2 }}>
                                    {
                                        activeTab &&
                                        (
                                            <div style={{ display: 'grid', placeItems: 'center' }}>
                                                <div>
                                                    <QRCodeSVG
                                                        bgColor="transparent"
                                                        value={state[activeTab]?.address}
                                                        size={150}
                                                    />
                                                </div>
                                                <div>
                                                    <p className='p-lr-5 p-tb-15 txt-center txt-nero fs-14' style={{ wordBreak: 'break-all'}}>
                                                        {
                                                            state[activeTab]?.address
                                                        }
                                                    </p>
                                                </div>

                                                <Button
                                                    variant="contained"
                                                    className='big-button'
                                                    fullWidth
                                                    onClick={() => copyShareLink(state[activeTab]?.address)}
                                                >
                                                    {t('button.copyAddress')}
                                                </Button>
                                                <div className='txt-center p-t-20 fs-14 txt-nero'>
                                                    <p>
                                                        {t('deposit.notiwarrning')} {t('deposit.depositFee', { 'min_amount': state?.details?.deposit_fee?.top_up_minimum_amount, 'deposit_fee': state?.details?.deposit_fee?.type == 'fixed' ? state?.details?.deposit_fee?.amount : state?.details?.deposit_fee?.amount + "%" })}
                                                    </p>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
   
}

const useStyles = makeStyles(theme => ({
    // depositBox: {
    //     borderRadius: 6,
    //     backgroundColor: '#D6A364',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     marginTop: 20,
    //     paddingLeft: 5,
    //     paddingRgiht: 5,
    //     justifyContent: 'center'
    // },
    // optionWalletBonusLayout: {
    //     borderRadius: '48px',
    //     background: '#E7E7E7',
    //     boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
    // },
    // tabButton: {
    //     borderRadius: '48px',
    //     cursor: 'pointer',
    //     transition: 'background-color 0.3s',
    //     color: '#949494',
    // },
    // activeTab: {
    //     color: 'white',
    //     background: 'linear-gradient(91deg, #86552E 2.67%, #DDA253 99.35%)',
    //     boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
    // },
}));

const tabStyle = createTheme({
    components: {
        // Name of the component
        MuiTab: {
        styleOverrides: {
            // Name of the slot
            root: {
            // Some CSS
            minWidth:150,
            background:'#565656',
            borderRadius: '15px 15px 0 0',
            color: '#AFAFAF',
            fontSize: 18,
            fontWeight: 700,
            // boxShadow:'-3px -3px 4px 0px #FFF, 3px -2px 3px #0000000f',
            '&.Mui-selected':{
                background:'#EEE',
                color: '#2E3133 !important',
                // boxShadow:'-3px -3px 4px 0px #FFF, 3px -2px 3px #0000000f',
            }
            }
        },
    },
    MuiTabs:{
    styleOverrides: {
        indicator: {
            backgroundColor:'transparent',  
            },
            scroller:{
            overflow:'unset!important',
            paddingTop:20
            }
    }
    },

  }});

export default Deposit;