import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';

const DialogTradeDetails = (props) => {
    const { reservedHistroy = [] } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const totalSum = reservedHistroy.reduce((sum, item) => sum + (Number(item.price) || 0), 0);

    return (
        <div className='w-full'>
            <Grid
                container
                className='p-lr-20 p-tb-30 flex-c-m m-t-30'
                style={{
                    borderRadius: 8,
                    position: 'relative',
                    background: theme.palette.white.main,
                    boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
                    maxHeight: '80vh',
                    overflow: 'auto'
                }}
            >
                <Grid item sm={12}>
                    <Typography style={{ color: '#ADADAD', fontWeight: 700, fontSize: 18, textAlign: 'center' }}>{t('title.reservedValueHistory')}</Typography>
                </Grid>

                <hr
                    style={{
                        backgroundColor: '#F1F1F1',
                        boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF',
                        height: 4,
                        display: 'inline-block',
                        border: 0,
                        width: '100%'
                    }}
                />

                <div className={classes.contentContainer}>
                    {reservedHistroy.length === 0 ? (
                        <Grid
                            container
                            className={classes.noDataContainer}
                            style={{ textAlign: 'center', padding: '20px'}}
                        >
                            <Grid item xs={12}>
                                <Typography style={{ color: '#787878', fontWeight: 400, fontSize: 20 }}>
                                    {t('No data available')}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        reservedHistroy.map((item, index) => (
                            <Grid
                                container
                                key={index}
                            >
                                <Grid item xs={6}>
                                    <Typography style={{ color: '#787878', fontWeight: 400, fontSize: 17 }}>
                                        {formatDate(item.created_at)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Typography style={{ color: '#787878', fontWeight: 400, fontSize: 17, fontStyle: 'italic' }}>
                                        ${currencyFormat(Number(item.price) || 0, 2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    )}

                    {reservedHistroy.length > 0 && (
                        <Grid
                            container
                            className={classes.totalItem}
                            style={{ borderTop: '1px solid #ccc', paddingTop: '10px', marginTop: '10px' }}
                        >
                            <Grid item xs={6}>
                                <Typography style={{ color: '#787878', fontWeight: 700, fontSize: 20 }}>
                                    {t('Total')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Typography style={{ color: '#787878', fontWeight: 700, fontSize: 20 }}>
                                    ${currencyFormat(totalSum, 2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </Grid>
        </div>
    );
}

export default DialogTradeDetails;

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        overflowY: 'auto',
        flexGrow: 1,
        maxHeight: '60vh',
    },
    totalItem: {
        padding: '10px 0',
        marginTop: '10px',
        fontWeight: 'bold',
    },
}));
