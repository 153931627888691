import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _, { isNaN, isNil, } from 'lodash';

import jsQR from "jsqr";
import { Grid, Typography, Button, IconButton, TextField, InputAdornment , Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff , FiEdit } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths, tradingAmountFormat } from '@utils/Tools';

const INITIAL_STATE = {
    tradeValue:'',
    usedActiveUnit:0,
    usedPassiveUnit:0,
    usedTotalUnit:0,
    usedRedeemable:0,
    usedSystemRedeemable:0,
    securityPassword: '',
    showSecurityPassword: false,
    openCamera: true,
};

export default function Spend() {
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useState(INITIAL_STATE);
    const [userTrade , setUserTrade] = useState(null);
    const [inputErrors, setInputErrors] = useState({});
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [result, setResult] = useState(null);


    const params = new URLSearchParams(window.location.search);
    const mid = params.get('m');

    const steps = [
        t('spend.merchant'),
        t('spend.insertValue'),
        t('spend.makePayment'),
        t('spend.rateAdjustment'),
        t('spend.paymentSuccess'),
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getMerchantInfo = (value) => {
        if (!isNaN(value) && value > 0) {
            setLoading(true);
            getUrl(`/merchant-info`, { 'id': value }).then(response => {
                setLoading(false);
                let { status, message, error, data } = response;

                if (status) {
                    setSelectedMerchant(data);
                    setActiveStep(1);
                } else {
                    addAlert('', message || t('error.contactSupport'), 'error', '');
                    setState({ ...state, openCamera: true });
                    startCamera();
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const handleScan = () => {
        const video = videoRef.current;

        if (!video) {
            // Video element not yet initialized, exit function
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context) {
            console.error('Canvas context not available');
            return;
        }

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        
        if (code) {
            let splitText = (code.data).split("?m=");
            if (_.size(splitText) > 0) {
                let param = splitText[1];
                if (window.location.href === splitText[0]) {
                    let decodedId = atob(param);
                    if (decodedId > 0) {
                        cancelAnimationFrame(handleScan);
                        getMerchantInfo(decodedId);
                        setState({ ...state, openCamera: false });
                        stopCamera();
                    }
                }else{
                    addAlert('', t('spend.invalidLink'), 'error', '');
                    setState({ ...state, openCamera: true });
                    startCamera();
                }
            }
        } else {
            // Repeat capture process
            requestAnimationFrame(handleScan);
            console.log('QR code not detected');
        }

    };

    // Access the camera and start the video stream
    const startCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
            .then(stream => {
                const video = videoRef.current;
                video.srcObject = stream;
                video.onloadedmetadata = () => {
                    video.play()
                        .then(() => {
                            console.log('Video started successfully.');
                            handleScan(); // Delay scanning for 1 second to ensure video has started
                        })
                        .catch(error => {
                            console.error('Error starting video playback:', error);
                        });
                };
            })
            .catch(error => {
                console.error('Error accessing the camera:', error);
            });
    };

    // Stop the video stream when the component unmounts
    const stopCamera = () => {
        const video = videoRef.current;
        if (video && video.srcObject) {
            const stream = video.srcObject;
            const tracks = stream.getTracks();
    
            tracks.forEach(track => {
                try {
                    track.stop();
                } catch (error) {
                    console.error('Error stopping track:', error);
                }
            });
            video.srcObject = null; // Reset video source object
        } else {
            console.warn('No video element or source object found.');
        }
    };

    useEffect(() => {
        const handleNavigation = () => {
            stopCamera();
        };
    
        window.addEventListener('camera', handleNavigation);
    
        return () => {
            window.removeEventListener('camera', handleNavigation);
        };
    }, []);

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (state?.openCamera) {
                    startCamera();
                }
                break;
            case 1:
                stopCamera();
                isMountedRef.current = true;
                setLoading(true);
                getUrl(`/user-trade-profile`, { merchant_id: selectedMerchant?.id }).then(response => {
                    setLoading(false);
                    if (isMountedRef.current) {
                        let { status, message, error, data } = response;

                        if (status) {
                            setUserTrade(data);
                        } else {
                            if (error || message) {
                                if (_.size(error) > 0) {
                                    _.map(error, (value, key) => {
                                        message += "\n " + value[0];
                                    })
                                }
                                addAlert('', message || t('error.contactSupport'), 'error', '');
                            }
                        }
                    }

                }).catch(error => {
                    if (isMountedRef.current) {
                        setLoading(false);
                        addAlert('', JSON.stringify(error.message), 'error', '');
                    }
                });
                return () => { isMountedRef.current = false };

            default:
                stopCamera();
                return;
                break;
        }
    }, [activeStep, state?.openCamera]);

    useEffect(() => {
        if (!isNil(mid)) {
            const decodedId = atob(mid);
            setState(prevState => ({
                ...prevState,
                openCamera:false,
            }));
            stopCamera();
            getMerchantInfo(decodedId);
        }
    }, [mid]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'tradeValue':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to two
                const parts = value.split('.');
                if (parts[1] && parts[1].length > 2) {
                    value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                setState({ ...state, [name]: value });
                break;

            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedMerchant(null);
        setResult({});
        setState(INITIAL_STATE);
        navigate(`/spend`, { state: { from: storePaths(location) } });
    };

    const clearMerchant = () =>{
        setSelectedMerchant(null);
        setActiveStep(0);
        startCamera();
        navigate(`/spend`, { state: { from: storePaths(location) } });
    }

    const estimatePayment = () =>{
        if (state?.tradeValue > 0) {
            if (state?.tradeValue <= parseFloat(userTrade?.redeemable)+parseFloat(userTrade?.system_redeemable)) {
                //estimate redeemable value
                let left = _.clone(state?.tradeValue);
                let usedRedeemable= 0;
                let systemRedeemable = 0;
                if(userTrade?.redeemable > 0){
                    usedRedeemable = Math.min(left, userTrade?.redeemable);
                    left = left - usedRedeemable;
                }

                if (left > 0 && userTrade?.system_redeemable > 0) {
                    systemRedeemable = Math.min(left, userTrade?.system_redeemable);
                    left = left - userTrade?.system_redeemable;
                }

                //estimate unit
                let sumUnit = Math.ceil(_.clone(state?.tradeValue)/userTrade?.current_price);
                let leftUnit = sumUnit;
                let usedPassive = 0;
                let usedActive = 0
                if(userTrade?.sum_passive > 0){
                    usedPassive = Math.min(leftUnit, userTrade?.sum_passive);
                    leftUnit = leftUnit - usedPassive;
                }

                if (leftUnit > 0 && userTrade?.sum_active > 0) {
                    usedActive = Math.min(leftUnit, userTrade?.sum_active);
                    leftUnit = leftUnit - usedActive;
                }

                if(leftUnit == 0){
                    setState(prevState => ({
                        ...prevState,
                        usedRedeemable: usedRedeemable,
                        usedSystemRedeemable: systemRedeemable,
                        usedPassiveUnit: usedPassive,
                        usedActiveUnit: usedActive,
                        usedTotalUnit: sumUnit,
                    }));
                    if(activeStep === 1){
                        handleNext();
                    }
                }else{
                    addAlert('', t('error.unitInsufficientBalance'), 'error', '');
                }
            } else {
                addAlert('', t('error.valueInsufficientBalance'), 'error', '');
            }
        } else {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
        }
    }

    const makePayment = () =>{
        setLoading(true);
        setInputErrors({});
        let params = {
            spend_value: state?.tradeValue,
            security_password: state?.securityPassword,
            merchant_id: selectedMerchant?.id,
            current_rate: userTrade?.current_price,
        }

        postUrl(`/spend`, params).then(response => {
            setLoading(false);
            let { status, message, data , errors } = response;

            if (status) {
                setActiveStep(4);
                setResult(data.data);
                addAlert('', message || t('success.purchaseSuccess'), 'success', '');
                
            } else {
                if(errors?.current_rate){
                    setActiveStep(3);
                    setUserTrade(prevUserTrade => ({
                        ...prevUserTrade,
                        current_price: data?.current_price,
                        old_current_price: userTrade?.current_price,
                    }));
                    estimatePayment();
                }else{
                    addAlert('', message || t('error.contactSupport'), 'error', '');
                    setInputErrors(errors);
                }
            }

        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } });
    };

    const stepOne = () => {
        return (
            <div className='flex-c-m s-full pos-relative'>
                <video ref={videoRef} style={{ borderRadius:15 }} autoPlay playsInline muted className='s-full' />
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
        )
    };

    const stepTwo = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2}  className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.gray.main }}>{t('plan.merchantName')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sb-m' style={{ backgroundColor:theme.palette.backgroundColor , boxShadow:'-3px -3px 4px 0px #FFFFFF inset,2px 2px 3px 0px #0000001A inset' , borderRadius:10, padding:20 }}>
                                <Hidden mdDown>
                                    <div style={{ width: 70, height: 70 }} className='flex-c-m'>
                                        <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                    </div>
                                </Hidden>
                                <div className='w-full p-l-20' style={{ display:'flex', flexDirection:'column'}}>
                                    <Typography style={{ color:theme.palette.gray.main , fontWeight:'bold',wordBreak:'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                                    <Typography style={{ color:theme.palette.darkGray.main , fontStyle:'italic', wordBreak:'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', minHeight:70 , cursor:'pointer' }} onClick={clearMerchant}>
                                   <div className='flex-c-m' style={{ backgroundColor:theme.palette.primary.main , boxShadow:'2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026' , padding:7 , borderRadius:5 , top:0  }}><FiEdit style={{ color:'white' }} /></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m'>
                            <Typography style={{ color: '#ADADAD', fontWeight: 'bold' }}>{t('spend.availableBalance')}</Typography>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.redeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color:theme.palette.gray.main}}>{`$ ${tradingAmountFormat(userTrade?.redeemable) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.systemRedeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(userTrade?.system_redeemable) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.passiveUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                <Typography variant="body2" style={{ color: '#949494' , fontStyle:'italic' }}>{` ≈ $${tradingAmountFormat(parseFloat(userTrade?.sum_passive * userTrade?.current_price).toFixed(3))}`}</Typography>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main , marginLeft:10  }}>{userTrade?.sum_passive || 0}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.activeUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                <Typography variant="body2" style={{ color: '#949494' , fontStyle:'italic' }}>{` ≈ $${tradingAmountFormat(parseFloat(userTrade?.sum_active * userTrade?.current_price).toFixed(3))}`}</Typography>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main , marginLeft:10  }}>{userTrade?.sum_active || 0}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main , fontWeight:'bold' }}>{t('spend.approximatelyTotalValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight:'bold' }}>{`$${tradingAmountFormat(parseFloat((userTrade?.sum_active * userTrade?.current_price)+(userTrade?.sum_passive * userTrade?.current_price)).toFixed(3))}`}</Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} container className='flex-sb-m' style={{ marginTop:10 }} >
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.currentMarketPrice')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${userTrade?.current_price || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='tradeValue'
                                placeholder={t('spend.value')}
                                value={state?.tradeValue}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.tradeValue ? inputErrors.tradeValue : ''}
                                error={inputErrors && inputErrors.tradeValue ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                className={styles.textFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                className='pos-absolute'
                                fullWidth
                                onClick={estimatePayment}
                            >
                                {t('button.continue')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const stepThree = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.gray.main }}>{t('plan.merchantName')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sb-m' style={{ backgroundColor: '#EEEEEE', boxShadow: '-3px -3px 4px 0px #FFFFFF inset,2px 2px 3px 0px #0000001A inset', borderRadius: 10, padding: 20 }}>
                                <Hidden mdDown>
                                    <div style={{ width: 70, height: 70 }} className='flex-c-m'>
                                        <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                    </div>
                                </Hidden>
                                <div className='w-full p-l-20' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ color: '#565656', fontWeight: 'bold', wordBreak:'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                                    <Typography style={{ color: '#787878', fontStyle: 'italic', wordBreak:'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m'>
                            <Typography style={{ color: '#ADADAD', fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.redeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedRedeemable).toFixed(3)) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.systemRedeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedSystemRedeemable).toFixed(3)) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.passiveUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" style={{ color: '#949494', fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedPassiveUnit * userTrade?.current_price).toFixed(3))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.gray.main, marginLeft: 10 }}>{`≈ ${state?.usedPassiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.activeUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" style={{ color: '#949494', fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedActiveUnit * userTrade?.current_price).toFixed(3))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.gray.main, marginLeft: 10 }}>{`≈ ${state?.usedActiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m' style={{ paddingTop:0}}>
                            <div className='flex-l-m'>
                                <Typography variant="caption" style={{ color: theme.palette.gray.main, fontStyle:'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                            </div>
                            <div>
                                <Typography variant="caption" style={{ color: theme.palette.gray.main, fontStyle: 'italic' }}>{`$ ${userTrade?.current_price || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>{`$${tradingAmountFormat(state?.tradeValue)}`}</Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('user.securityPassword')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle securityPassword visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state?.showSecurityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                name='securityPassword'
                                type={state.showSecurityPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.securityPassword}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                            />
                        </Grid>
                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    onClick={handleBack}
                                    style={{
                                        boxShadow:'2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover':{
                                            boxShadow:'2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                >
                                    {t('button.back')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={makePayment}
                                >
                                    {t('button.purchaseNow')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const stepFour = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.gray.main , textAlign:'center' }}>{t('spend.rateAdjustmentDesc')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sa-m' style={{ backgroundColor: theme.palette.backgroundColor , boxShadow: '-3px -3px 4px 0px #FFFFFF inset,2px 2px 3px 0px #0000001A inset', borderRadius: 10, padding: 20 }}>
                                <div>
                                    <Typography variant="h6" style={{ color: theme.palette.gray.main }}>{userTrade?.old_current_price}</Typography>
                                </div>
                                <div>
                                    <Typography variant='subtitle1' style={{ color:'#ADADAD'}}>{t('spend.to')}</Typography>
                                </div>
                                <div><Typography variant="h6" style={{ color: theme.palette.gray.main , fontWeight:'bold' }}>{userTrade?.current_price}</Typography></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m' style={{ marginTop:10 }}>
                            <Typography style={{ color: '#ADADAD', fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.redeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedRedeemable).toFixed(3)) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.systemRedeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedSystemRedeemable).toFixed(3)) || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.passiveUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" style={{ color: '#949494', fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedPassiveUnit * userTrade?.current_price).toFixed(3))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.gray.main, marginLeft: 10 }}>{`≈ ${state?.usedPassiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('spend.activeUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" style={{ color: '#949494', fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedActiveUnit * userTrade?.current_price).toFixed(3))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.gray.main, marginLeft: 10 }}>{`≈ ${state?.usedActiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m' style={{ paddingTop: 0 }}>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontStyle: 'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontStyle: 'italic' }}>{`$ ${userTrade?.current_price || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>{`$${tradingAmountFormat(state?.tradeValue)}`}</Typography>
                            </div>
                        </Grid>

                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    onClick={handleBack}
                                    style={{
                                        boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover': {
                                            boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                >
                                    {t('button.back')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={makePayment}
                                >
                                    {t('button.purchaseNow')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepFive = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m' style={{ textAlign:'center'}}>
                        <Grid item xs={12}>
                            <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ width: 60 , aspectRatio: 1 / 1 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ color:theme.palette.gray.main , fontWeight:'bold', wordBreak:'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 5}}>
                            <Typography style={{ color:theme.palette.darkGray.main , fontStyle:'italic' , wordBreak:'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-c-m w-full' style={{ backgroundColor: theme.palette.white.main , boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset' , padding:15, borderRadius:10 , flexDirection:'column', flexWrap:'wrap' }}>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color:theme.palette.darkGray.main }}>{t('trade.dateTime')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main , fontStyle:'italic' }}>{result?.trade_transaction_record?.trade_transaction?.created_at || '-'}</Typography>
                                </div>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main }}>{t('spend.transactionId')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main , fontStyle:'italic', wordBreak:'break-all' , textAlign:'end', width:'45%' }}>{result?.trade_transaction_record?.trade_transaction?.ulid || '-'}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-c-m w-full' style={{ backgroundColor: theme.palette.white.main , boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset', padding: 15, borderRadius: 10, flexDirection: 'column' }}>
                                <div className='w-full' style={{ textAlign:'left' }}>
                                    <Typography style={{ color: '#ADADAD', fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                                </div>
                                <div className='flex-sb-m w-full p-tb-5'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main }}>{t('spend.redeemableValue')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.tradeValue).toFixed(3)) || 0}`}</Typography>
                                </div>
                                <div className='flex-sb-m w-full p-tb-5'>
                                    <Typography variant="subtitle1" style={{ color: '#787878' }}>{t('spend.passiveUnit')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main  }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.passive_unit) || 0}</Typography>
                                </div>
                                <div className='flex-sb-m w-full p-t-5'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main }}>{t('spend.activeUnit')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.active_unit) || 0}</Typography>
                                </div>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="caption" style={{ color: theme.palette.darkGray.main , fontStyle:'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                                    <Typography variant="caption" style={{ color: theme.palette.gray.main , fontStyle:'italic' }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.trade_price) || 0}</Typography>
                                </div>
                                <div className='flex-sb-m w-full m-t-10'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.darkGray.main, fontWeight:'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main, fontWeight:'bold' }}>{tradingAmountFormat(parseFloat(state?.tradeValue).toFixed(2)) || 0}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    style={{
                                        boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover': {
                                            boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                    onClick={() => goToLink("/spend-history")}
                                >
                                    {t('button.history')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={handleReset}
                                >
                                    {t('button.newPayment')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            case 2:
                return stepThree();
            case 3:
                return stepFour();
            case 4:
                return stepFive();
        }
    }

    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    {
                        activeStep === 4 &&
                        <div className='flex-c-m w-full' style={{ height: 50, marginBottom:10 }}>
                            <FaCheck className={styles.checkIcon} />
                        </div>
                    }
                    <div className='flex-c-m'>
                        <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.darkBase.main, fontWeight: 700, fontSize: 25 }} >
                            <b>
                                {steps[activeStep]}
                            </b>
                        </Typography>
                    </div>
                    {stepperDisplay(activeStep)}
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    checkIcon: {
        color: "#47AB8D",
        width: 100,
        height: 50,
    },
}));