import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import { Hidden, Typography, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ArrowForwardIos } from '@mui/icons-material';

import { currencyFormat } from '@utils/Tools';

const BuyTradeHistoryItem = props => {
    const { data, mobileView, units, displayOnly = false } = props;
    const createdAt = data?.[0]?.created_at || 'N/A';
    const { t, i18n } = useTranslation();

    return (
        <>
            <Grid container className={`flex-l-m p-tb-5 ${mobileView ? 'p-lr-5' : 'p-lr-20'} w-full`} style={{ cursor: data?.[0]?.status === 20 && displayOnly ? 'pointer' : 'auto' }} columnSpacing={mobileView ? 2 : 3}>
                <Grid item xs={mobileView ? 3 : 1} className='flex-l-m' style={{ flexDirection: 'column' }}>
                    <Typography variant='h5' style={{ lineHeight: 1.0 }}>{moment(createdAt).format('DD')}</Typography>
                    <Typography variant='body2' className='txt-upper'>{moment(createdAt).format('MMM')}</Typography>
                </Grid>

                <Grid item xs={mobileView ? 6 : displayOnly ? 7 : 8} className='flex-l p-r-10' style={{ flexDirection: 'column' }}>
                    <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }} noWrap>
                        <b>{t('trade.packagePurchase')} ({data?.[0]?.plan_history?.plan_name?.[i18n.language]})</b>
                    </Typography>
                    <Typography variant='caption' style={{ color: '#787878', lineHeight: 1.2 }} noWrap>{t('trade.remark', { remark: data?.[0]?.details?.remark || '-' })}</Typography>
                </Grid>

                <Grid item xs={3} className='flex-b' style={{ flexDirection: 'column' }}>
                    <Typography variant='body2' noWrap><b>${currencyFormat(data?.[0]?.plan_history?.price, 2)}</b></Typography>
                    {
                        data?.[0]?.status !== 20
                            ? <Typography variant='caption' className='txt-upper' style={{ color: '#787878', lineHeight: 1.0 }} noWrap>{t('trade.pending')}</Typography>
                            : <Typography variant='caption' style={{ color: '#787878', lineHeight: 1.0 }} noWrap>{currencyFormat(units, 0)} {t('trade.unit')}</Typography>
                    }
                </Grid>

                <Hidden mdDown>
                    {
                        data?.[0]?.status === 20 && displayOnly &&
                        <Grid item xs={1} className='flex-c-m'>
                            <ArrowForwardIos style={{ fontSize: 18, color: '#f0f0f0' }} />
                        </Grid>
                    }
                </Hidden>
            </Grid>
        </>
    )
}

export default BuyTradeHistoryItem;