import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Typography, Box, CircularProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const ChartData = props => {
    const { data, lockView, total, totalTitle } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const bgColors = [
        theme.palette.primary.main,
    ];

    // Calculate the total value of all data points
    const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

    // Calculate the angle for each data point
    let startAngle = -90;
    const segments = data.map((item, index) => {
        // if (!lockView[index]) {
            const segment = {
                startAngle: startAngle,
                endAngle: !isNaN(totalValue) && totalValue !== 0
                            ? (startAngle + ((item.value / totalValue) * 360)) === 270 
                                ? 269.99 
                                : startAngle + ((item.value / totalValue) * 360)
                            : startAngle,
                color: bgColors[index] || '#DE9D49', // You can customize the color for each segment
            };
            startAngle = segment.endAngle;
            return segment;
        // }
    });

    // Function to generate SVG path description for an arc
    function describeArc(x, y, radius, startAngle, endAngle) {
        const startRadians = (startAngle * Math.PI) / 180;
        const endRadians = (endAngle * Math.PI) / 180;
        const largeArcFlag = endRadians - startRadians <= Math.PI ? '0' : '1';
        const startX = x + radius * Math.cos(startRadians);
        const startY = y + radius * Math.sin(startRadians);
        const endX = x + radius * Math.cos(endRadians);
        const endY = y + radius * Math.sin(endRadians);
        const arc = [
            `M ${startX} ${startY}`,
            `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        ].join(' ');
        return arc;
    }
    


    return (
        <Box style={{ position: 'relative', display: 'inline-flex', background: '#1E1E1E', boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 4px 0px #0000007A inset', maxWidth: 185, aspectRatio: 1 / 1, borderRadius: '100%', padding: 15 , width:'100%' }}>
            <div className='w-full h-full' style={{ position: 'relative', background: '#CCCCCC', boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset', borderRadius: '100%' }}>
                <CircularProgress
                    variant="determinate"
                    value={0} // Set value to 100 to hide the default progress bar
                    sx={{
                        circle: {
                            color: '#7D4E2B',
                            strokeLinecap: 'round', // Makes the ends of the progress bar rounded
                        },
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    thickness={4} // Thickness of the circle
                />
                {segments.map((segment, index) => {
                    // if (!lockView[index]) {
                        if (segment.startAngle !== segment.endAngle) {
                            return (
                                <svg
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: segments.length - index,
                                    }}
                                    viewBox="0 0 100 100"
                                >
                                    <g filter={index === 0 ? "url(#3d-effect-outer)" : "url(#3d-effect-inner)"}>
                                        <path
                                            className='circular-fill'
                                            fill="none"
                                            stroke={bgColors[index]}
                                            strokeWidth="10"
                                            strokeLinecap="round"
                                            d={describeArc(50, 50, 45, segment.startAngle, segment.endAngle)}
                                            filter={index === 0 ? "url(#inset-shadow)" : null}
                                        />
                                    </g>

                                    {/* 3D Effect Outer */}
                                    <defs>
                                        <filter id="3d-effect-outer" x="-1.33057" y="-1.33691" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.57 0" />
                                            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow_62_35777" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="-2" dy="-2" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="multiply" in2="effect1_innerShadow_62_35777" result="effect2_innerShadow_62_35777" />
                                        </filter>
                                    </defs>

                                    {/* 3D Effect Inner */}
                                    <defs>
                                        <filter id="3d-effect-inner" x="0.518066" y="0.663086" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="4" dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_62_35776" />
                                        </filter>
                                    </defs>
                                </svg>
                            )
                        }
                    // }
                })}
            </div>

            <Box style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                <div className='s-full' style={{ background: '#1E1E1E', boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 4px 0px #0000007A inset', borderRadius: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h6' ><b>{isNaN(total) ? 0 : (total).toLocaleString()}</b></Typography>
                    <Typography variant='caption' className='txt-center txt-upper p-lr-10' style={{ color: theme.palette.white.main, whiteSpace: 'break-spaces', lineHeight: 1.1, fontWeight: 500, }}>
                        {totalTitle}
                    </Typography>
                </div>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({

}))

export default ChartData;