import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

const MAIN_COLOR = '#CB2020';
const SECONDARY_COLOR = '#B51111';

let theme = createTheme({
    palette: {
        primary: {
            main: MAIN_COLOR,
            secondary: SECONDARY_COLOR,
            linear: `linear-gradient(90deg, ${MAIN_COLOR} 0%, ${MAIN_COLOR} 78.5%, ${SECONDARY_COLOR} 100%)`,
        },
        secondary: {
            main: '#1E1E1E',
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        gray: {
            main: '#565656',
            secondary: '#424242',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        darkBase: {
            main: '#2E3133',
        },
        lightGray: {
            main: '#A7A7A7',
        },
        darkGray: {
            main: '#787878',
        },
        white: {
            main: '#F0F0F0'
        },
        darkBlack: {
            main: '#000000',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: MAIN_COLOR,
            borderMain: `2px solid ${MAIN_COLOR}`,
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        backgroundColor: '#EEEEEE',
        mainBackground: '#021B32',
    },
    components: {
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: 63,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    minWidth:'20%'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 100,
                    textTransform: 'capitalize',
                    borderTopLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root:hover::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Default state of text input field font.
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: '#2E3133',
                    },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: '#2E3133',
                    },

                    '& .MuiInputBase-root': {
                        color: '#2E3133',
                    },

                    '& .MuiFormLabel-root.Mui-focused': {
                        color: '#2E3133',
                    },

                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: '#2E3133',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },

                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                        color: '#2E3133',
                    },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: '#EEEEEE',
                    borderRadius: 10,
                    flexShrink: 0,
                    boxShadow: '-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset',
                    height: 60,
                    padding: 15,
                    textDecorationLine: "none",
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: '#565656',
                        borderRadius: '0px !important',
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                    '&::placeholder': {
                        color: '#565656',
                        opacity: 1
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#565656',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    ":after": {
                        borderTop: "thin solid #ffffff"
                    },
                    ":before": {
                        borderTop: "thin solid #ffffff"
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#565656',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#F0F0F0',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#565656',
                    borderRadius: '48px',
                    textTransform: 'uppercase',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    minHeight: "40px",
                    height: "40px"
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderRadius: '48px',
                    background: '#1E1E1E',
                    // boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
                    minHeight: "40px",
                    height: "40px", 
                },
                indicator: {
                    backgroundColor: 'transparent',
                },
                scrollButtons: {
                    color: '#565656',
                }
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#787878',
                },
                arrow: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#2E3133',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
                underline: {
                    "&&&:before": {
                        borderBottom: "none"
                    },
                    "&&:after": {
                        borderBottom: "none"
                    }
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    ".MuiOutlinedInput-notchedOutline": {
                        border: 0
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                },
                select: {
                    padding: 0
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D6A364',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    color: '#82512D'
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    gap: 16,
                    borderRadius: 12,
                    alignItems: 'center',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#82512D',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#2E3133',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
                & .MuiBottomNavigation-root &.Mui-selected {
                    background: ${MAIN_COLOR};
                    color: #FFF !important;
                },
                & .MuiTab-root.Mui-selected{
                    color: white !important;
                    background: ${MAIN_COLOR};
                },
                &.MuiPaginationItem-root.Mui-selected{
                    background-color: ${MAIN_COLOR} !important;
                    color:#fff
                }
            `,
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    maxWidth: '100%',
                    margin: '0 auto',
                    backgroundColor:'#F7F7F7'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiDialog-paper": {
                        backgroundColor:'#f0f0f0',
                        boxShadow:'2px 2px 4px 0px rgba(255, 255, 255, 1) inset, -3px -3px 4px 0px rgba(0, 0, 0, 0.2) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.3)'
                    },
                   
                }
            }
        },
        MuiDialogContent:{
            styleOverrides:{
                root:{
                    backgroundColor:'transparent !important',
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                backgroundColor:'#e9e9e9',
                boxShadow:'3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset'
              },
              bar1Determinate: {
                background: MAIN_COLOR,
                borderRadius:48,
                boxShadow: '2px 0px 2px 0px rgba(0, 0, 0, 0.10), -2px -2px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.44) inset;'
              }
            },
        },
    }
});

theme = responsiveFontSizes(theme);

export default theme;