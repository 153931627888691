import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Typography, Stepper, Step, StepLabel, Button, useMediaQuery, MobileStepper, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl,deleteUrl } from '@utils/ApiAction';

import MerchantInfo from './MerchantInfo';
import MerchantIntro from './MerchantIntro';
import MerchantAddress from './MerchantAddress';
import MerchantDocument from './MerchantDocument';
import MerchantPreview from './MerchantPreview';
import MerchantRequest from './MerchantRequest';

export default function MerchantIndex(props) {
    const { hasData, callApi, logoImage, icFront, icBack, supportDocs, setIcFront, setIcBack, setSupportDocs, setLogoImage, state, setState, checked, setChecked, inputErrors, setInputErrors, submitData } = props;
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [activeStep, setActiveStep] = useState(0);
    const [countries, setCountries] = useState([]);
    const [countryState, setCountryState] = useState([]);
    const [previewDetail, setPreviewDetail] = useState(false);
    const [loadCountry, setLoadCountry] = useState(false);
    const [loadCountryState, setLoadCountryState] = useState(false);

    let currSectionFilled = false;

    const steps = [
        t('stepper.material'),
        t('stepper.introduce'),
        t('stepper.address'),
        t('stepper.uploadDocuments'),
        t('stepper.submitRequest'),
    ];

    const titleStepper = [
        t('merchant.material'),
        t('merchant.introduce'),
        t('merchant.address'),
        t('merchant.uploadDocuments'),
        t('merchant.submitRequest'),
    ];

    // --------------- Api -------------------
    useEffect(() => {
        if (inputErrors) {
            detectPageForErrors();
        }

        callCountries();
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if (state?.address?.country) {
            callStates();
        }
        // eslint-disable-next-line
    }, [state?.address?.country]);

    useEffect(() => {
        if (!state?.icFront && !state?.icBack) {
            setState({ ...state, ic: {} });
        }

        // eslint-disable-next-line
    }, [state?.icFront, state?.icBack])

    // --------------- Functions -------------------
    const detectPageForErrors = () => {
        let errorStep = 0;
        if (inputErrors?.['name.en'] || inputErrors?.['name.cn'] || inputErrors?.['code'] || inputErrors?.['mobile'] || inputErrors?.['email'] || inputErrors?.['company_register_number']) { 
            errorStep = 0; 
        } else if (inputErrors?.['website'] || inputErrors?.['bussiness_nature'] || inputErrors?.['about_shop'] || inputErrors?.['product_sell']) { 
            errorStep = 1; 
        } else if (inputErrors?.['address.address1'] || inputErrors?.['address.province'] || inputErrors?.['address.zip'] || inputErrors?.['address.country'] || inputErrors?.['address.state'] || inputErrors?.['address.city']) { 
            errorStep = 2; 
        } else if (inputErrors?.['new_logo'] || inputErrors?.['ic_front'] || inputErrors?.['ic_back'] || inputErrors?.['support_doc']) { 
            errorStep = 3; 
        }
        setActiveStep(errorStep);
    };

    const callCountries = () => {
        try {
            setLoadCountry(true);
            getUrl(`/member-get-countries`).then(response => {
                if (response.status) {
                    setCountries(response.countries);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoadCountry(false);
            });
        } catch (error) {
            // do nothing
        }
    };

    const callStates = () => {
        try {
            setLoadCountryState(true);
            let apiData = {
                country: state?.address?.country
            };
            getUrl(`/member-get-states`, apiData).then(response => {
                if (response.status) {
                    setCountryState(response.state);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoadCountryState(false);
            });
        } catch (error) {
            // do nothing
        }
    };

    const handleSectionFilled = () => {
        if (activeStep === 0 && !_.isEmpty(state?.name?.en) && !_.isEmpty(state?.name?.cn) && !_.isEmpty(state?.email) && !_.isEmpty(state?.phone) && !_.isEmpty(state?.code) && !_.isEmpty(state?.companyRegisterNumber)) {
            currSectionFilled = true;
        } else if (activeStep === 1 && !_.isEmpty(state?.businessNature) && !_.isEmpty(state?.aboutShop) && !_.isEmpty(state?.productSell)) {
            currSectionFilled = true;
        } else if (activeStep === 2 && !_.isEmpty(state?.address?.address1) && !_.isEmpty(state?.address?.zip) && !_.isEmpty(state?.address?.city) && !_.isEmpty(state?.address?.state) && !_.isEmpty(state?.address?.country)) {
            currSectionFilled = true;
        } else if (activeStep === 4 && checked) {
            currSectionFilled = true;
        } else {
            currSectionFilled = false;
        }
        return currSectionFilled;
    };

    const handleFilesDelete = (name, value) => {
        const dataTransfer = new DataTransfer();
        let icFiles = state?.ic;
        let tempIc = _.filter(icFiles, (file, key) => key !== value);
        
        switch (name) {
            case 'newLogo':
                setLogoImage('');
                setState({ ...state, newLogo: '' });
                break;
            case 'icFront':
                if (!hasData) {
                    tempIc.forEach((file) => dataTransfer.items.add(file));
                }
                setState({ ...state, ic: hasData ? tempIc : dataTransfer.files, icFront: '' });
                break;
            case 'icBack':
                if (!hasData) {
                    tempIc.forEach((file) => dataTransfer.items.add(file));
                }
                setState({ ...state, ic: hasData ? tempIc : dataTransfer.files, icBack: '' });
                break;
            case 'supportDoc':
                let docFiles = state?.supportDoc;
                let tempDoc = _.filter(docFiles, (file, key) => key !== value);
                if (!hasData) {
                    tempDoc.forEach((file) => dataTransfer.items.add(file));
                }
                setState({ ...state, supportDoc: hasData ? tempDoc : dataTransfer.files });
                break;
        }

        if (hasData && (!logoImage || !icFront || !icBack || !supportDocs) && state?.[name]?.id) {
            let fileId = '';
            switch(name){
                case 'newLogo':
                case 'icFront':
                case 'icBack':
                    fileId = state?.[name]?.id;
                    break;
                case 'supportDoc':
                    fileId = _.filter(state?.supportDoc, (file, key) => key === value)?.[0]?.id;
                    break;
            }

            deleteUrl(`/member-delete-file/${fileId}/${state?.id}`).then(response => {
                setLoading(false);
                if (response.status) {
                    // addAlert('', t('success.deleteSuccess'), 'success', '');
                    callApi();
                } else {
                    addAlert('', response?.message || t('error.deleteError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    };

    const handleChange = ({ target }) => {
        let { name, value, files } = target;
        switch (name) {
            case 'code':
                setState({ ...state, [name]: value.toLowerCase() });
                break;
            case 'check':
                setChecked(!checked);
                break;
            case 'phone':
                setState({ ...state, [name]: !/^\s*$/.test(value) && !isNaN(value) ? value : "" });
                break;
            case 'merchantNameEn':
                setState({ ...state, name: {...state.name, en: value } });
                break;
            case 'merchantNameCn':
                setState({ ...state, name: {...state.name, cn: value } });
                break;
            case 'country':
                if (value !== 0 ) { 
                    setState({...state, address: {...state.address, country: value, state: ''}});
                } else{
                    setState({...state, address: {country: '', state: '', city: '', zip: '', province: '', address1: ''}});
                }
                break;
            case 'state':
            case 'city':
            case 'zip':
            case 'province':
            case 'address1':
                if (name === 'state') {
                    value = value.toString();
                }
                setState({   ...state, address:  { ...state.address,  [name]: value, }});
                break;
            case 'newLogo':
            case 'ic':
            case 'supportDoc':
                if (files[0]?.size > 5242880) {
                    target.value = null;
                    setState({ ...state, [name]: null })
                    setInputErrors({ [name]: t('general.maxFileSize', { S: 5120 }) })
                    addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
                } else {
                    setInputErrors({})
                    const file = target.files[0];
                    const dataTransfer = new DataTransfer();

                    if (_.size(state?.[name]) > 0) {
                        let fileList = state?.[name];
                        let newFiles = files;
                        if (name === 'ic') {
                            if (fileList?.length <= 1) {
                                Array.from(fileList).forEach((file) => dataTransfer.items.add(file));
                            }
                            Array.from(newFiles).slice(0, 2).forEach((file) => dataTransfer.items.add(file));
                            setState({ ...state, [name]: dataTransfer.files, icFront: dataTransfer.files[0], icBack: dataTransfer.files[1] });
                            setIcFront(true);
                            setIcBack(true);
                        } else {
                            Array.from(fileList).forEach((file) => {
                                if (file instanceof File) {
                                    dataTransfer.items.add(file);
                                }
                            });
                            Array.from(newFiles).forEach((file) => {
                                if (file instanceof File) {
                                    dataTransfer.items.add(file)
                                }
                            });
                            setState({ ...state, [name]: dataTransfer.files });
                        } 
                    } else {
                        if (name === 'ic') {
                            Array.from(files).slice(0, 2).forEach((file) => dataTransfer.items.add(file));
                            setState({ ...state, [name]: dataTransfer.files, icFront: files[0], icBack: files[1] });
                            setIcFront(true);
                            setIcBack(true);
                        } else {
                            setState({ ...state, [name]: files });
                        }  
                    }

                    if (file ? file instanceof Blob: false) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            switch ( name ) 
                            {
                                case 'newLogo':
                                    setLogoImage(reader.result);
                                    break;
                                case 'supportDoc':
                                    setSupportDocs(true);
                                    break;
                                default:
                                    break;
                            }
                        };
                        reader.readAsDataURL(file);
                    } else {
                        console.error("File is not a Blob or it's missing");
                    }
                }
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    // --------------- Stepper -------------------
    const stepper = () => {
        return (
            <Stepper activeStep={activeStep} alternativeLabel orientation={mobileView ? 'vertical' : 'horizontal'}>
                {steps.map((label) => {
                    // const stepProps = {};
                    // const labelProps = {};
                    return (
                        <Step key={label} >
                            <StepLabel style={{ wordBreak: 'break-all' }} ><p style={{ color: 'white' }} >{label}</p></StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        )
    }

    const actionLayout = () => {
        return (
            <div className='p-all-40 m-lr-10 m-b-10 m-t-20' style={{ background: '#EEE', borderRadius: 15 }}>
                {
                    mobileView
                    ? null
                    : <Typography variant='h5' className='txt-left' style={{ color: "#ADADAD", margin: '0 0 15px'}}>{titleStepper[activeStep]}</Typography>
                }
                
                { previewDetail ? step4() : renderStep() }
                
                <div className='flex-sb' style={{ margin: '30px 0 0'}}>
                    <Button
                        variant="contained"
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: theme.palette.darkBase.main,
                                color: theme.palette.white.main,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#D9D9D9",
                                color: "#ADADAD",
                                boxShadow: '2px 2px 4px 0px #00000040 inset',
                            },
                        }}
                        disabled={parseInt(activeStep) <= 0 ? true : false}
                        onClick={activeStep === 3 && previewDetail ? () => setPreviewDetail(!previewDetail) : backButton}
                    >
                        {t('button.back')}
                    </Button>

                    {
                        activeStep === 3 && previewDetail === false ? (
                            <Button 
                                variant="contained"
                                sx={{
                                    "&.Mui-disabled": {
                                        backgroundColor: "#D9D9D9",
                                        color: "#ADADAD",
                                        boxShadow: '2px 2px 4px 0px #00000040 inset',
                                    },
                                }}
                                onClick={() => setPreviewDetail(!previewDetail)} 
                                disabled={(state?.newLogo && _.size(state?.ic) > 0 && _.size(state?.supportDoc) > 0) ? false : true}
                            >
                                {t('button.next')}
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={activeStep === steps.length - 1 ? () => {if (checked) {setActiveStep(0); setPreviewDetail(false);} submitData();} : nextButton}>
                                {activeStep === steps.length - 1 ? t('button.registerNow') : t('button.next')}
                            </Button>
                        )
                    }
                </div>
            </div>
        );
    };

    const renderStep = () => {
        const activeStepNumber = parseInt(activeStep);
        switch (activeStepNumber) {
            case 0:
                return step0();
            case 1:
                return step1();
            case 2:
                return step2();
            case 3:
                return step3();
            case 4:
                return step5();
            default:
                return null;
        }
    };
    
    const backButton = () => {
        const activeStepNumber = parseInt(activeStep); 
        setActiveStep( activeStepNumber - 1 );
        setPreviewDetail(false);
    }

    const nextButton = () => {
        handleSectionFilled();
        if (!currSectionFilled && activeStep !== 3) {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
            return;
        } else {
            const activeStepNumber = parseInt(activeStep);
            setActiveStep(activeStepNumber + 1);
            setPreviewDetail(false);
        }
    }

    const mobileStepperDisplay = () => {
        return (
            <MobileStepper
                variant="text"
                steps={_.size(steps)}
                position={"static"}
                activeStep={activeStep}
                style={{ background: 'transparent', color: '#f0f0f0', justifyContent: 'center' }}
                nextButton={
                    <IconButton
                        disabled={
                            (state?.newLogo && _.size(state?.ic) > 0 && _.size(state?.supportDoc) > 0) && activeStep !== _.size(steps) - 1
                                ? false 
                                : true
                        }
                        onClick={() => {
                            activeStep === 3 && previewDetail === false
                                ? setPreviewDetail(!previewDetail)
                                : activeStep === steps.length - 1
                                    ? checked && (setActiveStep(0), setPreviewDetail(false)) || submitData()
                                    : nextButton()
                        }}
                    >
                        <KeyboardArrowRightRounded style={{ color: activeStep === _.size(steps) - 1 ? theme.palette.darkBase.main : '#f0f0f0' }} />
                    </IconButton>
                }
                backButton={
                    <IconButton
                        disabled={parseInt(activeStep) <= 0 ? true : false}
                        onClick={() => {
                            activeStep === 3 && previewDetail
                                ? setPreviewDetail(!previewDetail)
                                : backButton()
                        }}
                    >
                        <KeyboardArrowLeftRounded style={{ color: activeStep === 0 ? theme.palette.darkBase.main : '#f0f0f0' }} />
                    </IconButton>
                }
            />
        )
    }

    // --------------- Step Contents -------------------
    const step0 = () => {
        return (<MerchantInfo state={state} inputErrors={inputErrors} handleChange={handleChange} />);
    }

    const step1 = () => {
        return (<MerchantIntro state={state} inputErrors={inputErrors} handleChange={handleChange} />)
    }

    const step2 = () => {
        return (<MerchantAddress state={state} inputErrors={inputErrors} handleChange={handleChange} countries={countries} countryState={countryState} loadCountry={loadCountry} loadCountryState={loadCountryState} />);
    }

    const step3 = () => {
        return (<MerchantDocument state={state} inputErrors={inputErrors} handleChange={handleChange} logoImage={logoImage} handleFilesDelete={handleFilesDelete} />);
    }

    const step4 = () => {
        return (<MerchantPreview state={state} logoImage={logoImage} countries={countries} countryState={countryState} />);
    }

    const step5 = () => {
        return (<MerchantRequest checked={checked} handleChange={handleChange} />);
    }

    // --------------- End of Lines -------------------

    return (
        <>
            <div style={{ margin: 'auto', width: '80%', textAlign: 'center', }}>
                <Typography variant='h5' className='p-t-60 p-b-30'>{t('merchant.becomeMerchant')}</Typography>
                {mobileView ? null : stepper()}
            </div>

            {
                mobileView
                    ? <div>
                        {mobileStepperDisplay()}
                        <Typography>{titleStepper[activeStep]}</Typography>
                        {actionLayout()}
                        {mobileStepperDisplay()}
                    </div>
                    : actionLayout()
            }
            
        </>
    );
}