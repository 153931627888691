import _ from 'lodash';
import React, { useEffect, useState , useRef } from 'react';
// import moment from 'moment-timezone';

import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { storePaths , currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

import { 
    // Card, 
    // CardContent, 
    // Accordion, 
    // AccordionActions,
    // AccordionSummary, 
    // AccordionDetails,
    // DialogTitle,
    // DialogActions, 
    // TabContext,
    // TabList,
    // TabPanel,
    // Dialog, DialogContent,
    // Box,
    // Hidden,
    // IconButton,
    // SpeedDial, SpeedDialAction, SpeedDialIcon,
    // Button, 
    // createTheme,
    // ThemeProvider , 
    // Pagination , 
    // Stack , 
    // Typography, 
    // Tabs, Tab,
    Grid
} from '@mui/material';

import { makeStyles, useTheme, styled } from '@mui/styles';

const INITIAL_STATE = {
    
}

export default function MarketPrice() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();
    const navigate = useNavigate();
    const { addAlert , setLoading , loading } = useNotificationLoading();
    const [data, setData] = useState([]);

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    // ------------ setup call api function ------------

    const callApi = () => {
        try {
            // let apiData = {
                
            // };
            getUrl(`/member-get-market-price`).then(response => {
                if (response.status) {
                    setData(response.price_range)
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
                // navigate(`/`);
            }).finally(() => {
                setLoading(false);
            })
            ;
        } catch (error) {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        setLoading(true);
        callApi();
        
        // eslint-disable-next-line
    }, []);

    return (
        <div className='dashboard-container'>
            {
                _.size(data) > 0 &&
                <Grid container spacing={3}>
                    {
                        _.map(data, (value, key) => {
                            return (
                                <Grid 
                                    item 
                                    xs={12} sm={6} md={4} lg={3} 
                                    key={key}
                                    onClick={() => goToLink(`/market-price-details/${parseFloat(value.min_price_range)}`)}
                                >   
                                    <div 
                                        className={classes.listingItem}
                                    >
                                        <div className='line1' />
                                        <div className='line2' />
                                        
                                        <div className='itemsContent'>
                                            <div 
                                                style={{
                                                    textAlign: 'center',
                                                    padding: '5px 5px 10px 5px',
                                                }}
                                            >
                                                <div 
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        padding: '5px 5px 0px 5px',
                                                        fontSize: 21,
                                                    }}
                                                >
                                                    <p className='titleText' >{value.min_price_range}</p>
                                                    <p className='titleText' >-</p>
                                                    <p className='titleText' >{value.max_price_range}</p>
                                                </div>
                                                <p className='normalText' style={{ fontSize: 13, lineHeight: 1, textTransform: "uppercase" }} >{t('general.rate')}</p>
                                            </div>

                                            <div className='divider' />

                                            {/* <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: 15,
                                                    padding: '10px 5px',
                                                }}
                                            >
                                                <p className='normalText' >{t('general.availableBalance')}</p>
                                                <p className='normalText' >{ currencyFormat(value.balance, 0)}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                    
                </Grid>
            }
           
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listingItem: {
        '& .line1': {
            height: 10, 
            margin: 'auto' , 
            width: '80%', 
            borderRadius: '15px 15px 0 0',
            background: '#F0F0F040',

        },
        '& .line2': {
            height: 10, 
            margin: 'auto' , 
            width: '85%' , 
            borderRadius: '15px 15px 0 0',
            background: '#f0f0f066'
        },
        '& .itemsContent': {
            margin: '0 5px',
            padding: 10,
            backgroundColor: theme.palette.backgroundColor,
            borderRadius: 15,
            boxShadow: '2px 2px 4px 0px #FFFFFF inset, -3px -3px 4px 0px #00000033 inset,  4px 4px 8px 0px #0000004D',
        },
        '& .divider': {
            height: 4,
            boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF',
        },
        '& .titleText': {
            color: theme.palette.gray.main,
            fontWeight: 'bold',
        },
        '& .normalText': {
            color: theme.palette.lightGray.main,
        },
        '&:hover': {
            cursor: 'pointer',
            '& .itemsContent': {
                boxShadow: '2px 2px 4px 0px #FFFFFF inset, -3px -3px 4px 0px #00000033 inset, 4px 4px 8px 0px #0000004D',
                color: theme.palette.backgroundColor,
                backgroundColor: theme.palette.primary.main,
            },
            '& .divider': {
                boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF3D',
                border: `1px solid var(--Button-red, ${theme.palette.primary.main})`,
            },
            '& .titleText': {
                color: theme.palette.backgroundColor,
            },
            '& .normalText': {
                color: theme.palette.backgroundColor,
            },
            '& .line1': {
                background: '#FF000040',
            },
            '& .line2': {
                background: '#ff000078',
            },
        },
    },
}));