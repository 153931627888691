import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Grid, TextField, MenuItem, FormControl, Select } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { KeyboardArrowDown, Autorenew } from '@mui/icons-material';

export default function MerchantAddress(props) {
    const { state, inputErrors, handleChange, countries, countryState, loadCountry, loadCountryState } = props;
    const { t, i18n } = useTranslation();
    
    const theme = useTheme();
    const styles = useStyles();

    return (
        <Grid container spacing={2} style={{ height: '100%' }}>
            <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                                value={state?.address?.country || 0}
                                name="country"
                                onChange={handleChange}
                                style={{
                                    textAlign: 'left',
                                    color: theme.palette.gray.main,
                                }}
                                IconComponent={(props) => (
                                    loadCountry
                                        ? <Autorenew {...props} className={styles.rotatingIcon} style={{ fontSize: 'xx-large' }} />
                                        : <KeyboardArrowDown {...props} style={{ fontSize: 'xx-large' }} />
                                )}
                                disabled={loadCountry ? true : false}
                            >
                                <MenuItem value={0} disabled>{t('merchant.country')}</MenuItem>
                                {
                                    countries.map((country) =>
                                        <MenuItem key={country.code} value={country.code}>
                                            {i18n.language === 'cn' ? country.name_cn : country.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                                value={state?.address?.state || 0}
                                name="state"
                                onChange={handleChange}
                                style={{
                                    textAlign: 'left',
                                    color: theme.palette.gray.main,
                                }}
                                IconComponent={(props) => (
                                    loadCountryState
                                        ? <Autorenew {...props} className={styles.rotatingIcon} style={{ fontSize: 'xx-large' }} />
                                        : <KeyboardArrowDown {...props} style={{ fontSize: 'xx-large' }} />
                                )}
                                disabled={!state?.address?.country || _.size(countryState) <= 0}
                            >
                                <MenuItem value={0} disabled>{t('merchant.state')}</MenuItem>
                                {
                                    countryState.map((cnState) =>
                                        <MenuItem key={cnState.code} value={cnState.id}>
                                            {i18n.language === 'cn' ? cnState.name_cn : cnState.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name='city'
                            placeholder={t('merchant.city')}
                            value={state?.address?.city || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.address ? inputErrors.address?.city : ''}
                            error={inputErrors && inputErrors.address ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name='zip'
                            placeholder={t('merchant.zipCode')}
                            value={state?.address?.zip || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.address ? inputErrors.address?.zip : ''}
                            error={inputErrors && inputErrors.address ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                    {
                        !['MY'].includes(state?.address.country) &&
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                name='province'
                                placeholder={t('merchant.province')}
                                value={state?.address?.province || ''}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.address ? inputErrors.address?.province : ''}
                                error={inputErrors && inputErrors.address ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                fullWidth
                            />
                        </Grid>
                    }

                    <Grid item xs={12} style={{ flex: 1, height: '100%' }}>
                        <TextField
                            variant="standard"
                            name='address1'
                            placeholder={t('merchant.address1')}
                            value={state?.address?.address1 || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.address ? inputErrors.address?.address1 : ''}
                            error={inputErrors && inputErrors.address ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            style={{
                                height: '100%'
                            }}
                            sx={{
                                "&.MuiTextField-root .MuiInputBase-root": {
                                    height: '100%',
                                    alignItems: 'baseline',
                                    overflow: 'scroll',
                                }
                            }}
                            // maxRows={!['MY'].includes(state?.address.country) ? 7 : 11}
                            multiline
                            rows={!['MY'].includes(state?.address.country) ? 7.7 : 11}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    '@keyframes infiniteRotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    rotatingIcon: {
        animation: '$infiniteRotate 2s linear infinite',
    },
}));