import _ from 'lodash';
import React, { useState , useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl , postUrl } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Button, TextField, FormHelperText, IconButton, InputAdornment} from '@mui/material';

import { Link as RouterLink, useParams , useLocation , useSearchParams , useNavigate} from 'react-router-dom';

import { FiEye, FiEyeOff, FiLock } from "react-icons/fi";
import { currencyFormat } from '@utils/Tools';

// import CustomSelect from '@components/CustomSelect';
import useNotificationLoading from '@utils/useNotificationLoading';
import TwoFA from '@components/TwoFA';
import WalletItem from '@components/WalletItem';
import { isSet } from 'lodash';

const INITIAL_STATE = {
    wallet: "",
    amount: "",
    remark: "",
    authentication_code: "",
    security_password: "",
}

const Withdraw = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [searchParams] = useSearchParams();
    const [inputErrors, setInputErrors] = useState();
    const [state, setState] = useState(INITIAL_STATE);
    let navigate = useNavigate();

    const [twoFASetting, setTwoFASetting] = useState(false);
    const [submitAction, setSubmitAction] = useState(true);
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState({
        security_password: false,
    });

    const [walletSetting, setWalletSetting] = useState({});
    const [conditionError, setConditionError] = useState([]);
    const [wallet, setWallet] = useState({});
    const [walletBalance, setWalletBalance] = useState('');

    useEffect(() => {
        callApi();

        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        setLoading(true);
        try {

            if (typeof searchParams.get("w") !== 'string') {
                setLoading(false);
                navigate(`/`);
                return;
            }
    
            let wallet_id = parseInt(atob(searchParams.get("w")));
            
            if (isNaN(wallet_id)) {
                setLoading(false);
                navigate(`/`);
                return;
            }
    
            setState({
                ...state,
                wallet_id: wallet_id,
            });
        
            let apiData = {
                wallet_id: wallet_id,
            };

            getUrl(`/get-withdraw-details`, apiData).then(response => {
                if (response.status) {
                    setWalletSetting(response.wallet_settings);
                    setWalletBalance(response.wallet_balance);
                    setWallet(response.wallet);
                    setSubmitAction(false);
                    setTwoFASetting(response.twofa);
                }
                else
                {
                    setSubmitAction(true);
                    setConditionError(response.err_msg);
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });

        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            security_password: !showPassword.security_password
        });
    };

    function totalCal()
    {
        let sum_amount = 0;
        let user_input_amount = state?.amount;
        let current_fee_mode = String(walletSetting?.fee_mode);
        let display_amount = '';
        if (current_fee_mode === 'both')
        {
            sum_amount = walletSetting?.fee_amount + (user_input_amount * walletSetting?.fee_percent / 100 ); 
        }
        else
        {
            sum_amount = Math.max( walletSetting?.fee_amount , walletSetting?.fee_percent ) ;
        }
        
        display_amount = (user_input_amount - sum_amount)
        // display_amount = (state?.amount - 
        //     (
        //         String(walletSetting?.fee_mode) === 'both' 
        //         ? 
        //             ( walletSetting?.fee_amount + (state?.amount * walletSetting?.fee_percent / 100 )) 
        //         : 
        //             ( Math.max( walletSetting?.fee_amount , walletSetting?.fee_percent ) ) 
        //     )).toFixed(2);

        return display_amount;
    }

    function displayFee()
    {
        let setText = '';

        if(!walletSetting?.fee_amount && walletSetting?.fee_percent){
            return walletSetting?.fee_percent + '%';
        }
        if(walletSetting?.fee_amount && !walletSetting?.fee_percent){
            return '$'+ walletSetting?.fee_amount;
        }

        if(walletSetting?.fee_amount && walletSetting?.fee_percent){
            if (String(walletSetting?.fee_mode) === 'both')
            {
                setText = '$'+ walletSetting?.fee_amount + ' + ' + walletSetting?.fee_percent + '%';
            }
            else if (String(walletSetting?.fee_mode) === 'higher')
            {
                setText = walletSetting?.fee_percent + ' % & $ ' +walletSetting?.fee_amount + t(`withdraw.heigherCodition`);
            }
        }

        return (
            setText
        )
    }

    const handleOpenDrawer = () => {
        if ( state?.amount !== '' && state?.security_password ) {
            setOpen(true);
        } else {
            addAlert('', t('error.requiredFields', {'value': t(`user.securityPassword`) + " ," + t(`transfer.amount`) } ), 'error', '');
        }
    }

    const nextFunction = () => {
        return twoFASetting ? handleOpenDrawer : submitData ;
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();

        let apiData = {
            wallet_id: parseInt(atob(searchParams.get('w'))),
            amount: state.amount,
            remark: state.remark,
            authentication_code: state.authentication_code,
            security_password: state.security_password,
        };

        postUrl('/wallets/withdrawal', apiData).then(response => {
            setLoading(false);
            if (response.status) {
                callApi();
                setState(INITIAL_STATE);
                addAlert('', t('success.withdrawalsSuccess'), 'success', '');
                setOpen(false);

                // navigate('/');
            }
            else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.purchaseError'), 'error', '');
                
                let datas = ['remark', 'security_password', 'amount'];

                // version 1
                datas.some(element => {
                    if(response.errors && (Object.keys(response?.errors)).includes(element)){
                        setOpen(false);
                    }
                })

                // version 2
                // const errors = response?.errors;
                // if(!errors) return; 
                // const invalidFields = datas.filter(field => field in errors);
                // if(invalidFields.length > 0) {
                //     setOpen(false); 
                // }

            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        ;
    }

    return (

        <div className='dashboard-container'>
            <div className='dashboard-w' style={{paddingBottom:40}}>
                <div className='pos-relative' style={{zIndex:2}}>
                    <Typography variant='h6' className='txt-center p-b-30' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main }} >{t('withdraw.title')}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <Box className="m-b-30" style={{ width: '320px' }}>
                            <WalletItem
                                walletValue={walletBalance}
                                walletDecimal={wallet?.decimal}
                                walletCode={wallet?.code}
                                walletName={wallet?.name ? (wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.cn) : ''}
                            />
                        </Box>
                    </div>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >

                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='amount'
                                // label={t('withdraw.withdrawalAmount')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                                error={inputErrors && inputErrors.amount ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state.amount || ''}
                                placeholder={t('withdraw.withdrawalAmount')}
                            />
                            <FormHelperText>
                                {t('withdraw.amountToReceive')} :
                                {
                                    state?.amount && parseFloat(totalCal()) > 0
                                        ?
                                        currencyFormat(totalCal(), 2)
                                        :
                                        '0.00'
                                }
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='remark'
                                // label={t('withdraw.remark')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                error={inputErrors && inputErrors.remark ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state.remark}
                                placeholder={t('withdraw.remark')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name="security_password"
                                placeholder={t('profile.currentSecurityPassword')}
                                type={showPassword.security_password ? 'text' : 'password'}
                                value={state.security_password || ''}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClickShowPassword('securityPassword')}
                                            >
                                                {showPassword.security_password ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                className={styles.textFieldBox}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {
                                _.size(conditionError) <= 0 ?
                                    <>
                                        <p className='fs-14 txt-nero'>
                                            <b>{t('withdraw.withDrawConditionTitle')}</b>
                                        </p>
                                        <ol className="withdraw-ol txt-nero" style={{paddingTop:0}}>
                                            {
                                                walletSetting?.min_amount > 0
                                                    ?
                                                    <li>
                                                        <p className='fs-13' >
                                                            {t('withdraw.withDrawCondition1', { 'min_amount': '$' + currencyFormat(walletSetting?.min_amount,0) })}
                                                        </p>
                                                    </li>
                                                    :
                                                    null
                                            }
                                            {
                                                walletSetting?.max_amount > 0
                                                    ?
                                                    <li>
                                                        <p className='fs-13' >
                                                            {t('withdraw.withDrawCondition2', { 'max_amount': '$' + currencyFormat(walletSetting?.max_amount, 0) })}
                                                        </p>
                                                    </li>
                                                    :
                                                    null
                                            }
                                            {
                                                displayFee()
                                                    ?
                                                    <li>
                                                        <p className='fs-13' >
                                                            {t('withdraw.withDrawCondition3', { 'value': displayFee() })}
                                                        </p>
                                                    </li>
                                                    :
                                                    null
                                            }
                                            {
                                                walletSetting?.multiple_of > 0
                                                    ?
                                                    <li>
                                                        <p className='fs-13' >
                                                            {t('withdraw.withDrawCondition4', { 'value': walletSetting?.multiple_of })}
                                                        </p>
                                                    </li>
                                                    :
                                                    null
                                            }
                                        </ol>
                                    </>
                                    :
                                    <>
                                        <Typography variant='body2' style={{color: theme.palette.darkBase.main }}>
                                            {t('withdraw.withDrawErrorConditionTitle')}
                                        </Typography>
                                        <ol className="withdraw-ol">
                                            {
                                                _.map(conditionError, (data, key) => {
                                                    return <li key={key}><Typography variant='caption' style={{color: theme.palette.darkBase.main }}>{data}</Typography></li>;
                                                })
                                            }
                                        </ol>
                                    </>
                            }
                        </Grid>

                    </Grid>

                    <Button
                        variant="contained"
                        fullWidth
                        className='big-button'
                        style={{ marginBottom: 30, marginTop: 30 }}
                        onClick={nextFunction()}
                        disabled={submitAction}
                    >
                        {t('button.withdrawNow')}
                    </Button>

                    <TwoFA
                        inputErrors={inputErrors?.authentication_code}
                        open={open}
                        onClose={() => setOpen(false)}
                        value={state?.authentication_code || ''}
                        handleChange={handleChange}
                        onClick={submitData}
                        name={"authentication_code"}
                    />
                </div>
            </div>
        </div>

    )
}

const useStyles = makeStyles(theme => ({
    depositBox: {
        borderRadius: 6,
        backgroundColor: '#D6A364',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 10,
        padding: 12,
        justifyContent: 'center'
    }
}));

export default Withdraw;