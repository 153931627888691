import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _, { isNaN } from 'lodash';
import moment from 'moment';

import { Grid, Typography, Button, Dialog, DialogContent, Hidden, Menu, MenuItem, ThemeProvider, Stack, Pagination, createTheme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import { getUrl } from '@utils/ApiAction';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';

// icons
import PendingIcon from '@mui/icons-material/Pending';
import { TbSettingsUp } from "react-icons/tb";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FaMoneyBillTransfer } from "react-icons/fa6";

//Pending 
import { GoAlert } from "react-icons/go";
// Processing
import { GiSandsOfTime } from "react-icons/gi";
// Cancel
import { MdOutlineCancel } from "react-icons/md";
// Refund
import { BsCashCoin } from "react-icons/bs";
// Complete
import { GoCheckCircle } from "react-icons/go";

const ThirdPartyConvert = () => {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    let navigate = useNavigate();
    const location = useLocation();

    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const [thirdPartyConvertList, setThirdPartyConvertList] = useState({});
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    // sum up the price depend status
    const [sumPendingPrice, setSumPendingPrice] = useState(0.00);
    const [sumProcessingPrice, setSumProcessingPrice] = useState(0.00);
    const [sumCompletedPrice, setSumCompletedPrice] = useState(0.00);
    const [sumCanceledPrice, setSumCanceledPrice] = useState(0.00);
    const [sumRefundedPrice, setSumRefundedPrice] = useState(0.00);
    
    const filters = [
        { title: t('thirdPartyConvert.filterOptions.all'), value: '' },
        { title: t('thirdPartyConvert.filterOptions.pending'), value: 10 },
        { title: t('thirdPartyConvert.filterOptions.processing'), value: 20 },
        { title: t('thirdPartyConvert.filterOptions.completed'), value: 30 },
        { title: t('thirdPartyConvert.filterOptions.canceled'), value: 40 },
        { title: t('thirdPartyConvert.filterOptions.refunded'), value: 50 },
    ];

    const [selectedStatus, setSelectedStatus] = useState('');

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, page, selectedStatus, searchDate]);

    const callApi = () => {
        setLoading(true);
        let apiData = {
            per_page: 10,
            page: page,
            filtering: selectedStatus,
            start_date : startDate !== null ? (startDate).format('YYYY-MM-DD') : null,
            end_date: endDate !== null ? (endDate).format('YYYY-MM-DD') : null
        }

        getUrl(`/third-party`, apiData).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = response;
                let { data } = response.data;
                // setTradeProfile(data.profile);
                // setCurrentPrice(currencyFormat(data.current_price, 3));
                // setTotalMerchantValue(parseFloat(data.total_merchant_value));

                let { current_page, last_page } = response.data;
                setTotal(last_page);
                setCurrPage(current_page);

                if (status) {
                    setThirdPartyConvertList(data);
                }

                setSumPendingPrice(response.sum_pending_price);
                setSumProcessingPrice(response.sum_processing_price);
                setSumCompletedPrice(response.sum_completed_price);
                setSumCanceledPrice(response.sum_canceled_price);
                setSumRefundedPrice(response.sum_refunded_price);
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }

    const handleClickOpen = (value) => {
        setOpenDialog(true);
        setSelectedTransaction(value);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const handleChangeStatusFilter = value => {
        setSelectedStatus(value);
        setPage(1);
        setThirdPartyConvertList([]);
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    const getStatusIcon = (status) => {
        switch (status) {
            case 10:
                return <GoAlert className='fs-24 clwhite'/>;
            case 20:
                return <GiSandsOfTime  className='fs-24 clwhite'/>;
            case 30:
                return <GoCheckCircle  className='fs-24 clwhite'/>;
            case 40:
                return <MdOutlineCancel   className='fs-24 clwhite'/>;
            case 50:
                return <BsCashCoin   className='fs-24 clwhite'/>;
            default:
                return null;
        }
    }

    return (
        <div className='dashboard-container' style={{ paddingBottom: 20 }}>
            <div className='flex-col-c-m w-full p-b-10'>
                <div className={styles.redeemBonusToken} style={{ width: mobileView ? '100%' : '80%' }}>
                    <div className={styles.redeemCurrentPrice} style={{ width: mobileView ? '85%' : '70%' }}>
                        <Typography variant='body1' className='txt-center'><b>{t('thirdPartyConvert.title')}</b></Typography>
                        {/* <Typography variant='body2' className='txt-center'><i>{t('trade.currentPrice')}: ${parseFloat(currentPrice).toFixed(3)}</i></Typography> */}
                    </div>

                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('thirdPartyConvert.sumPendingPrice')}</Typography>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(sumPendingPrice, 2) || 0.00}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('thirdPartyConvert.sumProcessingPrice')}</Typography>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(sumProcessingPrice, 2) || 0.00}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('thirdPartyConvert.sumCompletedPrice')}</Typography>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(sumCompletedPrice, 2) || 0.00}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('thirdPartyConvert.sumCanceledPrice')}</Typography>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(sumCanceledPrice, 2) || 0.00}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' style={{ color: '#adadad' }}>{t('thirdPartyConvert.sumRefundedPrice')}</Typography>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Typography variant='body1' className='txt-right' style={{ color: '#adadad' }}>${currencyFormat(sumRefundedPrice, 2) || 0.00}</Typography>
                        </Grid>
                    </Grid>
                </div>

                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' }} />

                <Grid container spacing={2} style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center' }}>
                    <Grid item xs={12} sm={7} md={5}>
                        <Typography variant='body1' style={{ color: '#adadad' }}><b>{t('thirdPartyConvert.titleList')}</b></Typography>
                        {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                    </Grid>
                    <Grid item xs={12} sm={5} md={7} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                        <div style={{ paddingRight: 10 }}>
                            <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                        </div>
                        <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                    </Grid>
                </Grid>

                {/* Third Party Convert Data */}
                <div className='w-full' style={{ background: theme.palette.secondary.main, borderRadius: 15, marginTop: 20, padding: '20px 20px' }}>
                    {
                        _.size(thirdPartyConvertList) > 0 ?
                            _.map(thirdPartyConvertList, (data, idx) => {
                                let factor = data?.factor !== 1 ? '-' : '+';
                                const statusIcon = getStatusIcon(parseInt(data?.status));
                                return (
                                    <div key={idx} onClick={() => handleClickOpen(data)}>
                                        <Grid container className={`flex-l-m p-tb-5 ${mobileView ? 'p-lr-5' : 'p-lr-20'} w-full`} columnSpacing={mobileView ? 2 : 3}>
                                            <Grid item xs={mobileView ? 1 : 1} className='flex-l-m' style={{ flexDirection: 'column' }}>
                                                <Typography variant='h5' style={{ lineHeight: 1.0 }}>{moment(data.updated_at).format('DD')}</Typography>
                                                <Typography variant='body2' className='txt-upper'>{moment(data.updated_at).format('MMM')}</Typography>
                                            </Grid>

                                            <Grid item xs={mobileView ? 2 : 1}>
                                                {statusIcon}
                                            </Grid>
                                            
                                            <Grid item xs={mobileView ? 7 : 7} className='flex-l p-r-10' style={{ flexDirection: 'column' }}>
                                                <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
                                                    <b>{t(`thirdPartyConvert.statusList.${data.status}`)}</b>
                                                </Typography>
                                                <Typography variant='caption' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} noWrap>{t('thirdPartyConvert.convertTo')} {data?.target_platform}</Typography>
                                            </Grid>

                                            <Grid item xs={mobileView ? 2 : 3} className='flex-b' style={{ flexDirection: 'column' }}>
                                                <Typography variant='body2' noWrap><b>{factor} ${currencyFormat(parseFloat(data?.price), 2)}</b></Typography>
                                                {/* <Typography variant='caption' style={{ color: theme.palette.darkGray.main, lineHeight: 1.0 }} noWrap>{factor} {Math.round(parseFloat(data?.redeemable_value) / currentPrice) || 0} {t('trade.unit')}</Typography> */}
                                            </Grid>

                                            {/* <Hidden mdDown>
                                                {
                                                    displayOnly &&
                                                    <Grid item xs={1} className='flex-c-m'>
                                                        <ArrowForwardIos style={{ fontSize: 18, color: theme.palette.white.main }} />
                                                    </Grid>
                                                }
                                            </Hidden> */}
                                        </Grid>
                                        { idx < _.size(thirdPartyConvertList) - 1 && <div className={styles.dividerDesign} />}
                                    </div>
                                )
                            })
                            : 
                            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                <div className="w-full" style={{ textAlign: 'center' }}>
                                    <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                                </div>
                                <p className='txt-center p-t-20 p-b-30' style={{ fontWeight: 500, color: '#a0a0a0', textTransform: 'uppercase' }}>{t('table.noRecord')}</p>
                            </div>
                    }

                    {
                        !mobileView && _.size(thirdPartyConvertList) > 0 &&
                        <ThemeProvider theme={paginationStyle} >
                            <Stack spacing={2} style={{ padding: '10px 10%' }}>
                                {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                            </Stack>
                        </ThemeProvider>
                    }
                </div>

                {/* <Dialog open={openDialog} onClose={handleClickClose} className='w-full'>
                    <DialogContent style={{ padding: '10px 30px 40px' }}>
                        <div className='flex-r-m'>
                            <Button onClick={handleClickClose} style={{ minWidth: 28, aspectRatio: 1 / 1, padding: 5, borderRadius: 100, boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A' }}>
                                <Close style={{ color: theme.palette.darkGray.main }} />
                            </Button>
                        </div>

                        <div className='flex-col-c-m p-b-10'>
                            <img src={'/images/logos/logo.png'} className='w-full m-all-10' alt="merchant_logo" loading='lazy' style={{ width: 100, height: 100, aspectRatio: 1 / 1 }} />
                            <Typography variant='body2' className='txt-upper txt-center' style={{ color: theme.palette.darkGray.main }}>
                                <i>
                                    {t('trade.merchant')} :&nbsp;
                                    {
                                        selectedTransaction?.trade_redeemable?.merchant_id !== null
                                            ? <>{selectedTransaction?.trade_redeemable?.merchant?.name?.[i18n.language]} ({selectedTransaction?.trade_redeemable?.merchant?.code})</>
                                            : <>{t('trade.system')}</>
                                    }
                                </i>
                            </Typography>
                        </div>

                        <div className='flex-col-c-m p-t-20'>
                            <Typography variant='body2' className='txt-upper' style={{ color: theme.palette.darkGray.main }}><i>{t('trade.transferUnit')}</i></Typography>
                            <Typography variant='h5' className='txt-upper' style={{ color: theme.palette.darkGray.main }}>
                                <b>{selectedTransaction?.factor !== 1 ? '-' : '+'} {Math.round(parseFloat(selectedTransaction?.redeemable_value) / currentPrice) || 0} {t('trade.unit')} ($ {currencyFormat(parseFloat(selectedTransaction?.redeemable_value), 2)})</b>
                            </Typography>
                            <Typography variant='body2' className='txt-upper' style={{ color: theme.palette.darkGray.main }}>{t('trade.rate')}: {currentPrice}</Typography>
                        </div>

                        <Grid container className={styles.dialogDetailBoxDesign}>
                            {
                                // selectedTransaction?.trade_transaction_id !== null
                                //     ? <>
                                //         <Grid item xs={6}>
                                //             <Typography style={{ color: theme.palette.darkGray.main, fontWeight: 'bold' }}>{t('transfer.transferTo')}</Typography>
                                //         </Grid>
                                //         <Grid item xs={6}>
                                //             <Typography style={{ color: theme.palette.darkGray.main }} className='txt-right'>Unknown 1</Typography>
                                //         </Grid>
                                //     </>
                                //     : 
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.transferType')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} className={mobileView ? 'txt-left' : 'txt-right'}>{selectedTransaction?.transaction_code_display?.[i18n.language]}</Typography>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={6} className='p-t-10'>
                                <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.dateTime')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`p-t-${mobileView ? 0 : 10}`}>
                                <Typography variant='body2' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} className={`${mobileView ? 'txt-left' : 'txt-right'} txt-upper`}>
                                    <i>{moment(selectedTransaction?.updated_at).format('DD MMM YYYY  ·  HH:mm:ss A')}</i>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} className='p-t-10'>
                                <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.transactionId')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`p-t-${mobileView ? 0 : 10}`}>
                                <Typography variant='body2' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} className={mobileView ? 'txt-left' : 'txt-right'}>{selectedTransaction?.trade_transaction?.ulid || '-'}</Typography>
                            </Grid>
                        </Grid>

                        <div className={styles.dialogDetailBoxDesign}>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main, fontWeight: 'bold' }}>{t('receipt.remark')}</Typography>
                            <Typography variant='body2' style={{ color: theme.palette.darkGray.main }}>{selectedTransaction?.details?.remark || '-'}</Typography>
                        </div>
                    </DialogContent>
                </Dialog> */}

                {
                    mobileView && _.size(thirdPartyConvertList) > 0 &&
                    <ThemeProvider theme={paginationStyle} >
                        <Stack spacing={2} style={{ padding: '10px 0px' }}>
                            {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                        </Stack>
                    </ThemeProvider>
                }
            </div>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

const useStyles = makeStyles((theme) => ({
    redeemBonusToken: {
        position: 'relative',
        padding: '50px 30px 20px',
        background: theme.palette.secondary.main,
        borderRadius: 10,
        boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 40,
    },
    redeemCurrentPrice: {
        position: 'absolute',
        top: -20,
        padding: 7,
        background: theme.palette.primary.main,
        borderRadius: 15,
    },
    shadowDisplay: {
        height: 6,
        borderRadius: '50%',
        margin: '30px 0px',
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))',
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    dialogDetailBoxDesign: {
        boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: 20,
        padding: '20px',
        marginTop: 20,
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
}));

export default ThirdPartyConvert;