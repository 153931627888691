import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@mui/styles';
import { Typography, Hidden } from '@mui/material';

import RegisterForm from '@layouts/RegisterForm';

const Recruit = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div className='dashboard-container'>
            <div className="dashboard-w">
                <div className='txt-center p-b-30'>
                    <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1.1, color: '#2E3133' }}>{t('title.register')}</Typography>
                </div>
                <div className='pos-relative' style={{zIndex:2}}>
                <RegisterForm />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    }
}));

export default Recruit