import _ from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { storeProfile } from '@actions';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const TwoFactorAuth = () => {
    const styles = useStyles();
    const theme = useTheme();

    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        secret: '',
        authenticationCode: ''
    });
    const [enableAuth, setEnableAuth] = useState(false);
    const [disableAuth, setDisableAuth] = useState(false);
    const { two_factor_confirmed_at } = useSelector(state => state.user);
    const [errorFormState, setErrorFormState] = useState({
        authentication_code: ''
    })
    const [qrcode, setQrcode] = useState([]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        setState({ ...state, [name]: newValue });
    }

    const getSecret = () => {
        setLoading(true);
        setErrorFormState({});
        let params = {
            type: 'enable',
        }
        postUrl(`authenticator`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, secret: response.secret });
                setQrcode(response.url);
                setEnableAuth(true);
            } else {
                addAlert('', JSON.stringify(response.message), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const verifyCode = () => {
        setLoading(true);
        setErrorFormState("");
        let params = {
            type: 'confirm',
            authentication_code: state.authenticationCode,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authenticationCode: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                addAlert('', t('success.enable2FASuccess'), 'success', '');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem;
                    })
                    setErrorFormState(allErrorField);
                    addAlert('', JSON.stringify(_.map(response.errors).join(' ')), 'error', '');
                } else {
                    addAlert(t('error.enable2FAError'), response.message, 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const disableTwoFactorAuth = () => {
        setLoading(true);
        let params = {
            type: 'disable',
            authentication_code: state.authenticationCode,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authenticationCode: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                setDisableAuth(false);
                setEnableAuth(false);
                addAlert('', t('success.disable2FASuccess'), 'success', '');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem;
                    })
                    setErrorFormState(allErrorField);
                    addAlert('', JSON.stringify(_.map(response.errors).join(' ')), 'error', '');
                } else {
                    addAlert(t('error.disable2FAError'), response.message, 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    return (
        <div className='dashboard-container'>
            <div className='dashboard-w'>
                <div className='txt-center lh-11'>
                    <Typography variant='h6' style={{ color: theme.palette.darkBase.main }}><b>{two_factor_confirmed_at ? t('user.disable2FA') : t('profile.twoFactorAuth')}</b></Typography>
                    <p className='txt-center  fs-14 lh-12 m-tb-10' style={{ color: '#565656' }}>{two_factor_confirmed_at ? t('user.enabled2FA') : t('user.2FASubtitle')}</p>
                </div>
                <div>
                    {
                        !two_factor_confirmed_at && !enableAuth &&
                        <>
                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <Typography variant="subtitle2" style={{ fontWeight: "bold" ,color: theme.palette.darkBase.main}}>{t('user.enable2FA')}</Typography>
                            </Grid>
                            <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                                <Button variant="contained" onClick={getSecret} className='big-button' style={{ marginTop: 20 }} fullWidth>{t('button.enable')}</Button>
                            </Grid>
                        </>
                    }
                    {
                        two_factor_confirmed_at && !disableAuth &&
                        <>
                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <Typography variant="subtitle2" style={{ fontWeight: "bold" , color: theme.palette.darkBase.main}}>{t('user.disable2FA')}</Typography>
                            </Grid>
                            <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                                <Button variant="contained" onClick={() => setDisableAuth(true)} className='big-button' style={{ marginTop: 20 }} fullWidth>{t('button.disable')}</Button>
                            </Grid>
                        </>
                    }
                    {
                        two_factor_confirmed_at && disableAuth &&
                        <>
                            <div className='txt-center p-tb-30 fs-14 lh-12' style={{ color: '#565656' }}>
                                <p>{t('user.disable2FACaption')}</p>
                            </div>
                            <div style={{ width: 200, margin: '0 auto' }} className='max-w-full'>
                                <img src='/images/2fa/disable2fa.png' alt="disable" className='w-full' />
                            </div>
                            <div className='w-full p-tb-20'>
                                <TextField
                                    error={errorFormState.authentication_code ? true : false}
                                    helperText={errorFormState?.authentication_code || ''}
                                    placeholder={t('user.authenticationCode')}
                                    variant="standard"
                                    type="number"
                                    value={state.authenticationCode}
                                    name="authenticationCode"
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </div>
                            <div>
                                <Button variant="contained" onClick={disableTwoFactorAuth} className='big-button' fullWidth>{t('button.submit')}</Button>
                            </div>
                        </>
                    }
                    {
                        !two_factor_confirmed_at && enableAuth &&
                        <>
                            {/* <Grid item xs={12}>
                                <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>{t('user.enable2FA')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('user.scan2FAQrcode')}</Typography>
                            </Grid> */}
                            <div className='p-tb-20'>
                                <div style={{ backgroundColor: 'transparent', padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <QRCodeSVG value={qrcode} bgColor='#eee' size={150} />
                                </div>
                            </div>
                            <div className='txt-center p-b-30'>
                                {/* <Typography variant="subtitle2">{t('user.insertSecret')}</Typography> */}
                                <Typography variant="body2" style={{ color: theme.palette.darkBase.main }}><b>{t('user.secretKey', { secret: state.secret })}</b></Typography>
                            </div>
                            <Grid item xs={12}>
                                <TextField
                                    error={errorFormState.authentication_code ? true : false}
                                    helperText={errorFormState?.authentication_code || ''}
                                    placeholder={t('user.authenticationCode')}
                                    variant="standard"
                                    type="number"
                                    fullWidth
                                    value={state.authenticationCode}
                                    name="authenticationCode"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                            <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                                <Button variant="contained" onClick={verifyCode} className='big-button' style={{ marginTop: 20 }} fullWidth>{t('button.submit')}</Button>
                            </Grid>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default TwoFactorAuth

const useStyles = makeStyles(theme => ({
    titleBox: {
        backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
}));
