import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useTheme, makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import theme from '../../Theme';
import { useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { storePaths } from '@utils/Tools';

export default function MarketPlaceCard(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { marketPrice, mobileDialog = false } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const handleViewAllClick = () => {
        navigate('/market-price', { state: { from: storePaths(location) } });
    };

    return (
        <div 
            style={{ 
                backgroundColor: mobileDialog? theme.palette.white.main : theme.palette.secondary.main, 
                borderRadius: 15, 
                padding: 20,
                paddingTop: 40,
                position: 'relative',
                color: '#F0F0F0',
                width: '100%',
                boxShadow: mobileDialog? '2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)' : ''

            }}
         >
            <div className={classes.marketPriceTitleContainer}>
                <div className="flex-m p-lr-20">
                    <div style={{ height: 45, padding: 0, backgroundColor: theme.palette.primary.main, borderRadius: '50%', aspectRatio: '1/1', alignContent: 'center', marginRight: 20, }}>
                        <img src='/images/logos/logo-white.png' alt='logo' className='w-full bor-15' style={{ objectFit: 'contain' }} loading='lazy' />
                    </div>
                    <Typography style={{ color: '#EEEEEE', fontWeight: 700, fontSize: 18}}>
                        {t('title.marketPlace')}
                    </Typography>
                    {/* <p className='pointer fs-14 txt-white' onClick={handleViewAllClick} style={{ display: mobileDialog ? 'none' : 'block' }}>
                        {t('button.viewAll')}
                    </p> */}
                </div>
            </div>
            <div className={classes.itemRow}>
                <Typography className={classes.headerText} style={{color: mobileDialog ? 'rgba(86, 86, 86, 1)': '#F0F0F0'}}><b>{t('trade.remainingUnit')}</b></Typography>
                <Typography className={classes.headerText} style={{color: mobileDialog ? 'rgba(86, 86, 86, 1)': '#F0F0F0'}}><b>{t('trade.rate')}</b></Typography>
            </div>
            {mobileDialog 
                ? <div className={classes.newDividerClass} /> 
                : <div className="divider-list-black" />
            }
            {
                Array.isArray(marketPrice) && _.size(marketPrice) > 0 ?
                    marketPrice.map((item, index) => (
                        <div key={index}>
                            <div className={classes.itemRow}>
                                <Typography  style={{color: mobileDialog ? 'rgba(86, 86, 86, 1)': '#F0F0F0'}}>{item.market_price}</Typography>
                                <Typography  style={{color: mobileDialog ? 'rgba(86, 86, 86, 1)': '#F0F0F0'}}>
                                    {!isNaN(parseFloat(item.rate)) ? parseFloat(item.rate).toFixed(3) : item.rate}
                                </Typography>
                            </div>
                            {
                                !(index === marketPrice.length - 1) && 
                                (
                                    mobileDialog 
                                        ? <div className={classes.newDividerClass} /> 
                                        : <div className="divider-list-black" />
                                )
                            }
                        </div>
                    ))
                :
                (
                    <div className='p-b-10 p-tb-10' >
                        <div className='flex-sb-m w-full' style={{ fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                            <img src="images/general/filter-search-empty-data.png" width="250" alt="No data" />
                            <p style={{ color: mobileDialog ? 'rgba(86, 86, 86, 1)': '#F0F0F0' }}>{t('general.noDisplay')}</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    marketPriceTitleContainer: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '80%', 
        padding: '10px 0',
        borderRadius: '15px',
        background: theme.palette.primary.main,
        boxShadow: '0px 4px 5px 0px rgba(209, 60, 60, 0.17)'
    },
    marketPriceTitle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
    },
    headerText: {
        // flex: 1,
        // textAlign: 'center',
        fontWeight: 700,
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        alignItems: 'center',
        width:'80%',
        margin:'0 auto'
    },
    itemText: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 500,
    },
    newDividerClass: {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px rgba(255, 255, 255, 1)',
        height: 4,
        display: 'inline-block',
        border: 0,
        width: '100%'
    }
}));
