import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUrl } from '@utils/ApiAction';
import { useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import NoticeItem from '@components/NoticeItem';

import { Grid, Typography } from '@mui/material';

const Detail = () => {
    const styles = useStyles();
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({});
    const { addAlert, setLoading, loading } = useNotificationLoading();

    useEffect(() => {
        setLoading(true);
        getUrl(`/notice-info/${id}`).then(response => {
            setLoading(false);
            setState(response.data);
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }, [])

    return (
        <div style={{ marginTop: 80, marginBottom: 50 }}>
            <div id="notice-img" style={{ padding: '0 30px', wordBreak:'break-all' }}>
                {
                    loading ?
                        null :
                        (
                            _.size(state) > 0 ?
                                <div>
                                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                                        <Typography variant="h6" style={{ wordBreak: 'break-word', fontWeight: "bold" }}>{state?.title[i18n.language] ? state?.title[i18n.language] : state?.title?.cn}</Typography>
                                        <Typography variant="body2" style={{color: "#545454"}}>{state?.created_at}</Typography>
                                    </div>
                                    <NoticeItem  info={state} />
                                </div>
                                :
                                <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={'/images/genealogy/downline_none.png'} alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                                        {t('table.noRecord')}
                                    </Typography>
                                </Grid>
                        )
                }
            </div>
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    endFontStyle: {
        textAlign: 'center',
        marginTop: 400,
        marginBottom: 30,
    },
}));

export default Detail;