import React, { memo } from 'react';
import { makeStyles , useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Hidden, Typography } from '@mui/material';

import AuthTitleBar from '@layouts/AuthTitleBar';
import RegisterForm from '@layouts/RegisterForm';

const Register = memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className='flex-c-m' style={{ backgroundColor: '#2E3133', minHeight: '100vh' }}>
            <AuthTitleBar />
            <Hidden mdDown>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100%' }}>
                    <div className={classes.leftBg}>
                        <div className='flex-c-m'>
                            <img src="images/auth/register-graphic.png" alt="register-graphic" loading='lazy' className={classes.imgStyle} />
                        </div>
                    </div>
                    <div className={classes.rightBg}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <img src="images/auth/login-black-bg-top.png" alt="bg-black-top" loading='lazy' />
                            <div style={{ display: 'block', bottom: 0, position: 'absolute' }}>
                                <img src="images/auth/login-black-bg-bottom.png" alt="bg-black-bottom" loading='lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            <div className={classes.fieldBox} >
                <div className="box-auth">
                <Typography className='p-b-20 txt-center' style={{fontSize:'1.2rem'}}>{t('login.registerAcc')}</Typography>
                    <RegisterForm stepperStyle={{ 
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                        {
                            color: 'white', // Just text label (ACTIVE)
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                        {
                            color: 'white', // Just text label (COMPLETED)
                        },
                        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                            color: 'white',
                        },
                        '& .MuiSvgIcon-root.MuiStepIcon-root':{
                            color:'white',
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                            color: theme.palette.primary.main, // circle color (ACTIVE)
                          },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                            fill: 'white', // circle's number (ACTIVE)
                        },
                        '& .MuiStepLabel-root .MuiStepIcon-text': {
                            fill: theme.palette.primary.main, 
                        },
                     }} />
                </div>
            </div>
        </div>
    )
});

const useStyles = makeStyles(theme => ({
    leftBg: {
        flex: 0.3,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url('../images/auth/login-red-bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    rightBg: {
        flex: 0.7
    },
    imgStyle: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '50%',
        height: '85%',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: -10,
        right: 0,
        objectFit: 'contain'
    },
    fieldBox:{
        position:'absolute',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        right: 0, 
        left: '27%',
        [theme.breakpoints.down('md')]:{
            left:0,
        },
        [theme.breakpoints.down('sm')]:{
            left:0,
        },
        [theme.breakpoints.down('xs')]:{
            left:0,
        },
    },
    // registerBox:{
    //     width: '70%',
    //     [theme.breakpoints.down('md')]:{
    //         width: '100%',
    //     },
    //     [theme.breakpoints.down('sm')]:{
    //         width: '100%',
    //     },
    //     [theme.breakpoints.down('xs')]:{
    //         width: '100%',
    //     },
    // },
}));

export default Register;